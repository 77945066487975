import React, { Component } from 'react';
import Button from '../companents/button';

import close from '../asseets/close-white.png'
import robot from '../asseets/Error404.png'
import history from '../companents/history';

class Error extends Component {

    refrehPage = () => {
        history.push('/')
        window.location.reload();
    }

    render() {
        return (
            <div className='error'>
                <div className='error-wrapper'>

                    <div className='error-modal'>
                        <img src={close} alt='close' className='big-close-er' />
                        <div className='modal-info-er'>
                            <p>Ошибка</p>
                            <p>Подключение не разрешено в настоящий момент.</p>
                        </div>
                        <img src={close} alt='close' className='small-close-er' />
                    </div>

                    <img src={robot} alt='robo' className='logo-robo' />

                    <h3 className='error-text-h3'>Подключение  не разрещено в настоящий момент.</h3>

                    <p className='error-text-p'>Проверьте настройки сети  или попробуйте ообновить экран.</p>

                    <Button value='Обновить' class='reload_error_page' click={this.refrehPage} />

                </div>
            </div>
        );
    }
}

export default Error;