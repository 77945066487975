import React, { useEffect, useState } from 'react';

import PageMasterHeader from './PageMasterHeader';
import PageMasterButtons from './ProfileBeautyMaster/PageMasterButtons';
import PageMasterContent from './ProfileBeautyMaster/PageMasterContent';
import PageMasterRecord from './ProfileBeautyMaster/PageMasterRecord';

import beautyApi from '../../api/beautyApi';
import LoadingBlock from '../../companents/bshop/itemCatalogBshop/oneItem/LoadingBlock';

import { ToastContainer, toast } from 'react-toastify';

const PageMaster = () => {
  const [dataMaster, setDataMaster] = useState();
  const [ContentSwitch, setContentSwitch] = useState(false);

  const urlPath = window.location.search
    .replace('?', '')
    .split('&')
    .reduce(function (p, e) {
      const a = e.split('=');
      p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
      return p;
    }, {});

  useEffect(() => {
    getDataMaster(urlPath.id);
    Object.keys(urlPath).length == 2
      ? urlPath.record == 'true'
        ? setContentSwitch(true)
        : setContentSwitch(false)
      : setContentSwitch(false);
  }, []);

  const getDataMaster = async (id) => {
    const fd = new FormData();
    fd.append('id', id);
    const response = await beautyApi.MyAcceptedRequestMaster(id);
    await setDataMaster(response);
  };

  const ContentSwitcherOff = () => {
    setContentSwitch(false);
  };

  const ContentSwitcherOn = () => {
    setContentSwitch(true);
  };

  const wave_error = () => {
    toast('Возникла ошибка, попробуйте еще раз!', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_success = () => {
    toast('Заявка на запись успешно отправлена!', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  if (dataMaster) {
    return (
      <>
        <PageMasterHeader dataMaster={dataMaster} />
        {!ContentSwitch ? (
          <>
            <PageMasterButtons ContentSwitcherOn={ContentSwitcherOn} />
            <PageMasterContent />
          </>
        ) : (
          <PageMasterRecord
            ContentSwitcherOff={ContentSwitcherOff}
            wave_error={wave_error}
            wave_success={wave_success}
          />
        )}
        <ToastContainer theme='light' />
      </>
    );
  } else {
    return (
      <>
        <LoadingBlock />
      </>
    );
  }
};

export default PageMaster;
