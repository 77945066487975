import React, { Component } from 'react';

import './style.css'

class Alert extends Component {
    render() {
        return (
            <div className='modal-menu' data-type='main' >
                <div className='modalAlert'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Alert;