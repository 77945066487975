import React, { useEffect, useRef, useState } from 'react';
import s from './header.module.css';
import { useForm } from 'react-hook-form';
import beautyApi from '../../api/beautyApi';
import { useSelector, useDispatch } from 'react-redux';
import {
  DisplayType,
  DisplayType1,
  DisplayType2,
  DisplayType3,
  DisplayType4,
  DopDisplayType,
  onAddAllServForClientReq,
  onAddAllSDServsForClientReq,
  onAddServForClientReq_1,
  onAddServForClientReq_2,
  onAddServForClientReq_3,
  onAddServForClientReq_4,
  onAddServForClientReq_5,
  onAddServForClientReq_11,
  onAddServForClientReq_21,
  onAddServForClientReq_31,
  onAddServForClientReq_41,
  onAddServForClientReq_51,
} from '../../redux/action';
import Modal from '@mui/material/Modal';

const ChildSelectModal = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const [open, setOpen] = React.useState(false);
  const itemsAllServForClientReq = useSelector((state) => state.itemsAllServForClientReq);
  const itemsAllSDServsForClientReq = useSelector((state) => state.itemsAllSDServsForClientReq);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [DopCatName1, setCatName1] = useState('Выбрать услугу');
  const [DopCatName2, setCatName2] = useState('Не выбрано');
  const [DopCatName3, setCatName3] = useState('Не выбрано');
  const [DopCatName4, setCatName4] = useState('Не выбрано');
  const [DopCatName5, setCatName5] = useState('Не выбрано');

  const [DopCatName11, setCatName11] = useState('Не выбрано');
  const [DopCatName21, setCatName21] = useState('Не выбрано');
  const [DopCatName31, setCatName31] = useState('Не выбрано');
  const [DopCatName41, setCatName41] = useState('Не выбрано');
  const [DopCatName51, setCatName51] = useState('Не выбрано');

  const [display_child1, setdisplay_child1] = useState('none');
  const [display_child2, setdisplay_child2] = useState('none');
  const [display_child3, setdisplay_child3] = useState('none');
  const [display_child4, setdisplay_child4] = useState('none');
  const [display_child5, setdisplay_child5] = useState('none');

  const [DopCatID1, setCatID1] = useState('-');
  const [DopCatID2, setCatID2] = useState('-');
  const [DopCatID3, setCatID3] = useState('-');
  const [DopCatID4, setCatID4] = useState('-');
  const [DopCatID5, setCatID5] = useState('-');

  const [dropDownDop, setDropDownDop] = useState(false);
  const [defaultValue, setDefaultValue] = useState('Выбрать услугу');

  const itemsServForClientReq_1 = useSelector((state) => state.itemsServForClientReq_1);
  const itemsServForClientReq_2 = useSelector((state) => state.itemsServForClientReq_2);
  const itemsServForClientReq_3 = useSelector((state) => state.itemsServForClientReq_3);
  const itemsServForClientReq_4 = useSelector((state) => state.itemsServForClientReq_4);
  const itemsServForClientReq_5 = useSelector((state) => state.itemsServForClientReq_5);

  const display_flex1 = useSelector((state) => state.DisplayType1);
  const display_flex2 = useSelector((state) => state.DisplayType2);
  const display_flex3 = useSelector((state) => state.DisplayType3);
  const display_flex4 = useSelector((state) => state.DisplayType4);
  const dop_display_flex = useSelector((state) => state.DopDisplayType);

  const SecDopServs = (ids, prod_id_add, prof_add_dop) => {
    return {
      ids: ids,
      prod_id_add: prod_id_add,
      prof_add_dop: prof_add_dop,
      // ...другие свойства
    };
  };

  const DopServs = (ids, profs) => {
    return {
      ids: ids,
      profs: profs,
      // ...другие свойства
    };
  };

  useEffect(() => {
    {
      getDopServs();
      getSecondDopServs();
      setOpen(props.openPrev);
    }
  }, [props.catID]);

  useEffect(() => {
    setDefaultValue(DopCatName1);
  }, [DopCatName1]);

  var warrObjects2 = [];
  const getDopServs = async () => {
    const responseAllDopServsForClient = await beautyApi.fetchServicesInReq2();

    for (let i = 0; i < responseAllDopServsForClient.data.length; i++) {
      if (responseAllDopServsForClient.data[i]['prof_id'] == props.catID) {
        let user = DopServs(
          responseAllDopServsForClient.data[i]['id'],
          responseAllDopServsForClient.data[i]['prof_add'],
        );
        warrObjects2[i] = user;
      }
    }
    dispatch(onAddAllServForClientReq(warrObjects2));
  };

  var warrObjects3 = [];
  const getSecondDopServs = async () => {
    const responseAllDopServsForClient = await beautyApi.fetchServicesInReq3();

    for (let i = 0; i < responseAllDopServsForClient.data.length; i++) {
      let user = SecDopServs(
        responseAllDopServsForClient.data[i]['id'],
        responseAllDopServsForClient.data[i]['prod_id_add'],
        responseAllDopServsForClient.data[i]['prof_add_dop'],
      );
      warrObjects3[i] = user;
    }
    dispatch(onAddAllSDServsForClientReq(warrObjects3));
  };

  const handleServ_1 = (event) => {
    dispatch(onAddServForClientReq_1(event.target.value));
    itemsAllServForClientReq.map((x) =>
      event.target.value == x.profs ? setCatName1(x.profs) : null,
    );
    itemsAllServForClientReq.map((x) =>
      event.target.value == x.profs ? handleChildServ_1(x.ids) : null,
    );
  };

  const handleChildServ_1 = (event) => {
    setCatID1(event);
    itemsAllSDServsForClientReq.map((x) =>
      event == x.prod_id_add ? setdisplay_child1('block') : null,
    );
  };

  const handleServ_11 = (event) => {
    dispatch(onAddServForClientReq_11(event.target.value));
    itemsAllSDServsForClientReq.map((x) =>
      event.target.value == x.prof_add_dop ? setCatName11(x.prof_add_dop) : null,
    );
  };

  const handleServ_2 = (event) => {
    dispatch(onAddServForClientReq_2(event.target.value));
    itemsAllServForClientReq.map((x) =>
      event.target.value == x.profs ? setCatName2(x.profs) : null,
    );
    itemsAllServForClientReq.map((x) =>
      event.target.value == x.profs ? handleChildServ_2(x.ids) : null,
    );
  };

  const handleChildServ_2 = (event) => {
    setCatID2(event);
    itemsAllSDServsForClientReq.map((x) =>
      event == x.prod_id_add ? setdisplay_child2('block') : null,
    );
  };

  const handleServ_21 = (event) => {
    dispatch(onAddServForClientReq_21(event.target.value));
    itemsAllSDServsForClientReq.map((x) =>
      event.target.value == x.prof_add_dop ? setCatName21(x.prof_add_dop) : null,
    );
  };

  const handleServ_3 = (event) => {
    dispatch(onAddServForClientReq_3(event.target.value));
    itemsAllServForClientReq.map((x) =>
      event.target.value == x.profs ? setCatName3(x.profs) : null,
    );
    itemsAllServForClientReq.map((x) =>
      event.target.value == x.profs ? handleChildServ_3(x.ids) : null,
    );
  };

  const handleChildServ_3 = (event) => {
    setCatID3(event);
    itemsAllSDServsForClientReq.map((x) =>
      event == x.prod_id_add ? setdisplay_child3('block') : null,
    );
  };

  const handleServ_31 = (event) => {
    dispatch(onAddServForClientReq_31(event.target.value));
    itemsAllSDServsForClientReq.map((x) =>
      event.target.value == x.prof_add_dop ? setCatName31(x.prof_add_dop) : null,
    );
  };

  const handleServ_4 = (event) => {
    dispatch(onAddServForClientReq_4(event.target.value));
    itemsAllServForClientReq.map((x) =>
      event.target.value == x.profs ? setCatName4(x.profs) : null,
    );
    itemsAllServForClientReq.map((x) =>
      event.target.value == x.profs ? handleChildServ_4(x.ids) : null,
    );
  };

  const handleChildServ_4 = (event) => {
    setCatID4(event);
    itemsAllSDServsForClientReq.map((x) =>
      event == x.prod_id_add ? setdisplay_child4('block') : null,
    );
  };

  const handleServ_41 = (event) => {
    dispatch(onAddServForClientReq_41(event.target.value));
    itemsAllSDServsForClientReq.map((x) =>
      event.target.value == x.prof_add_dop ? setCatName41(x.prof_add_dop) : null,
    );
  };

  const handleServ_5 = (event) => {
    dispatch(onAddServForClientReq_5(event.target.value));
    itemsAllServForClientReq.map((x) =>
      event.target.value == x.profs ? setCatName5(x.profs) : null,
    );
    itemsAllServForClientReq.map((x) =>
      event.target.value == x.profs ? handleChildServ_5(x.ids) : null,
    );
  };

  const handleChildServ_5 = (event) => {
    setCatID5(event);
    itemsAllSDServsForClientReq.map((x) =>
      event == x.prod_id_add ? setdisplay_child5('block') : null,
    );
  };

  const handleServ_51 = (event) => {
    dispatch(onAddServForClientReq_51(event.target.value));
    itemsAllSDServsForClientReq.map((x) =>
      event.target.value == x.prof_add_dop ? setCatName51(x.prof_add_dop) : null,
    );
  };

  const plusButton = () => {
    // setDropDownDop(!dropDownDop);
    itemsServForClientReq_1 !== '' ? dispatch(DisplayType1('inline')) : console.info();
    itemsServForClientReq_2 !== '' ? dispatch(DisplayType2('inline')) : console.info();
    itemsServForClientReq_3 !== '' ? dispatch(DisplayType3('inline')) : console.info();
    itemsServForClientReq_4 !== '' ? dispatch(DisplayType4('inline')) : console.info();
    itemsServForClientReq_4 !== '' ? dispatch(DopDisplayType('none')) : console.info();

    // dispatch(DopDisplayType('none'));
  };

  return (
    <>
      <span className={s.dropdown_dop_serv} onClick={handleOpen}>
        {defaultValue}
      </span>
      <Modal open={open} onClose={handleClose}>
        <div className={s.modal_child}>
          <button className={s.close} onClick={handleClose}>
            &times;
          </button>
          <div className={s.content}>
            <select className={s.dropdown_serv} onChange={(e) => handleServ_1(e)}>
              <option defaultValue='defaultValue'>{DopCatName1}</option>
              {itemsAllServForClientReq.map((x) => (
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>
              ))}
            </select>

            <select
              className={s.dropdown_sec_serv}
              onChange={(e) => handleServ_11(e)}
              style={{ display: `${display_child1}` }}
            >
              <option defaultValue='defaultValue'>{DopCatName11}</option>
              {itemsAllSDServsForClientReq.map((x) => {
                if (DopCatID1 == x.prod_id_add) {
                  return (
                    <option key={x.ids} value={x.prof_add_dop}>
                      {x.prof_add_dop}
                    </option>
                  );
                }
              })}
            </select>

            <select
              style={{ display: `${display_flex1}` }}
              className={s.dropdown_serv}
              onChange={(e) => handleServ_2(e)}
            >
              <option defaultValue='defaultValue'>{DopCatName2}</option>
              {itemsAllServForClientReq.map((x) => (
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>
              ))}
            </select>

            <select
              style={{ display: `${display_child2}` }}
              className={s.dropdown_sec_serv}
              onChange={(e) => handleServ_21(e)}
            >
              <option defaultValue='defaultValue'>{DopCatName21}</option>
              {itemsAllSDServsForClientReq.map((x) => {
                if (DopCatID2 == x.prod_id_add) {
                  return (
                    <option key={x.ids} value={x.prof_add_dop}>
                      {x.prof_add_dop}
                    </option>
                  );
                }
              })}
            </select>

            <select
              style={{ display: `${display_flex2}` }}
              className={s.dropdown_serv}
              onChange={(e) => handleServ_3(e)}
            >
              <option defaultValue='defaultValue'>{DopCatName3}</option>
              {itemsAllServForClientReq.map((x) => (
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>
              ))}
            </select>

            <select
              style={{ display: `${display_child3}` }}
              className={s.dropdown_sec_serv}
              onChange={(e) => handleServ_31(e)}
            >
              <option defaultValue='defaultValue'>{DopCatName31}</option>
              {itemsAllSDServsForClientReq.map((x) => {
                if (DopCatID3 == x.prod_id_add) {
                  return (
                    <option key={x.ids} value={x.prof_add_dop}>
                      {x.prof_add_dop}
                    </option>
                  );
                }
              })}
            </select>

            <select
              style={{ display: `${display_flex3}` }}
              className={s.dropdown_serv}
              onChange={(e) => handleServ_4(e)}
            >
              <option defaultValue='defaultValue'>{DopCatName4}</option>
              {itemsAllServForClientReq.map((x) => (
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>
              ))}
            </select>

            <select
              style={{ display: `${display_child4}` }}
              className={s.dropdown_sec_serv}
              onChange={(e) => handleServ_41(e)}
            >
              <option defaultValue='defaultValue'>{DopCatName41}</option>
              {itemsAllSDServsForClientReq.map((x) => {
                if (DopCatID4 == x.prod_id_add) {
                  return (
                    <option key={x.ids} value={x.prof_add_dop}>
                      {x.prof_add_dop}
                    </option>
                  );
                }
              })}
            </select>

            <select
              style={{ display: `${display_flex4}` }}
              className={s.dropdown_serv}
              onChange={(e) => handleServ_5(e)}
            >
              <option defaultValue='defaultValue'>{DopCatName5}</option>
              {itemsAllServForClientReq.map((x) => (
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>
              ))}
            </select>

            <select
              style={{ display: `${display_child5}` }}
              className={s.dropdown_sec_serv}
              onChange={(e) => handleServ_51(e)}
            >
              <option defaultValue='defaultValue'>{DopCatName51}</option>
              {itemsAllSDServsForClientReq.map((x) => {
                if (DopCatID5 == x.prod_id_add) {
                  return (
                    <option key={x.ids} value={x.prof_add_dop}>
                      {x.prof_add_dop}
                    </option>
                  );
                }
              })}
            </select>

            <div
              className={s.dop_section}
              style={{ display: `${dop_display_flex}` }}
              onClick={() => plusButton()}
            >
              {!dropDownDop ? 'Еще одна услуга' : 'Еще одна услуга'}
            </div>
          </div>
          <button className={s.beauty_button_confirm} onClick={handleClose}>
            Ок
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ChildSelectModal;
