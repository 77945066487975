import React, { useEffect, useRef, useState } from 'react';
import s from './req.module.css';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import objToFd from '../../../common/helpers/objToFd';
import beautyApi from '../../../api/beautyApi';
import { useSelector, useDispatch } from 'react-redux';
import { DisplayType, DopDisplayType, onAddMasterForClientInfo } from '../../../redux/action';
import { useHistory } from 'react-router-dom';

const ReviewModulforClient = (props) => {

    const {
        register,
        handleSubmit,
        reset
      } = useForm();
  
      const [dropDownDop, setDropDownDop] = useState(false);
      const [acceptDate, setAcceptDate] = useState(props.date);
      const dispatch = useDispatch();
      const masterId = useSelector((state) => state.accountBeauty.id);
      const WebSocketConnection = useSelector((state) => state.WebSocketConnection);
      const history = useHistory();

      const WSCSend = props.WSCSend
 
      const ws = useRef(null);
      const urlPath = window.location.pathname;
      // ws.current = new WebSocket('wss:/beauty.bikli.kz:2346'); // создаем ws соединение
      const options = { weekday: 'long',  month: 'long', day: 'numeric' };
      const day = new Date(props.date);
      let phone = '+ ' +  props.Phone.substr(0, 1) + '-(' + props.Phone.substr(1,3) + ')-' +  props.Phone.substr(4,3) + '-' +  props.Phone.substr(7,2) + '-' +  props.Phone.substr(9,2);

    //   console.log(phone)
      const  handleAccept = async () => {
        const response = await beautyApi.AcceptMaster(props.id, props.MasterID, props.price, props.orderID, 1);
        let message = 'ClientAccept,' + props.MasterID
        WSCSend(message)


      };

      const  handleNotAccept = async () => {
        const response = await beautyApi.AcceptMaster(props.id, props.MasterID, props.price, props.orderID, 0);
        let message = 'ClientRefused,' + props.MasterID
        WSCSend(message)
      };


      // useEffect(() => {
        
      //     ws.current = new WebSocket('wss:/beauty.bikli.kz:2346'); // создаем ws соединение
      //     ws.current.onopen = () => {
      //       console.log('Соединение открыто');
      //       // dispatch(onWsCon(true));
      //     }; // callback на ивент открытия соединения
      //     ws.current.onclose = () => {
      //       console.log('Соединение закрыто');
      //       // dispatch(onWsCon(false));
      //     }; // callback на ивент закрытия соединения
    
      //     return () => ws.current.close(); // кода меняется isPaused - соединение закрывается

      // }, []);



    const onSubmit = async (data) => {
        const obj = {
            ...data
        };

        obj.order == '' ?  obj.order = props.orderName : obj.order = obj.order;
        obj.time == 'Время' ?  obj.time = props.time : obj.time = obj.time;
        obj.price == '' ?  obj.price = props.price : obj.price = obj.price;
        obj.comm == '' ?  obj.comm = 'Мастер не оставил комментария' : obj.comm = obj.comm;

        const fd = new FormData();
        await objToFd(fd, obj);
        await fd.append('date', acceptDate);
        await fd.append('orderId', props.id);
        await fd.append('clientId', masterId);

        beautyApi.MasterReqAccept(fd);
        reset();
        dispatch(DopDisplayType('block'));
        setDropDownDop(!dropDownDop);
        };

        const toItem = () => {
          history.push(`/beauty/master?id=${props.MasterID}`);
        };

  return (
    <Popup
    trigger={<button className={s.dropdown_accept}>О мастере</button>}
    modal
    nested
  >
    {close => (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <div className={s.modal}>
        <button className={s.close} onClick={close}>
          &times;
        </button>
        <div className={s.header}>Заявка {props.orderID}</div>
        <div className={s.content}>
                <p>Услуги:</p>
                <p>{props.orderName}</p>
                <p>{props.orderName2}</p>
                <p>{props.orderName3}</p>
                <p>{props.orderName4}</p>
                <p>{props.orderName5}</p>
                <p><br /></p>
                <p>Дата: {day.toLocaleDateString("ru-ru", options)}</p>
                <p><br /></p>
                <p>Цена: {props.price} тнг.</p>
                <p><br /></p>
                <p>О мастере:</p>
                <p><br /></p>
                <div onClick={toItem}>
                <div className={s.item_masterA}>
      <div className={s.item_beauty_master_image}>
      <img
          src={
            props.img && props.img !== ''
              ? 'https://bshop.kz/beauty/AvatarPhotos/' + props.img
              : 'https://bshop.kz/beauty/AvatarPhotos/0.png'
          }
          alt="logo"
        />
      </div>

    <div className={s.item_beauty_master_name}>{props.FIO}</div>
    <div className={s.master_short_inf_salon}><p>Салон: {props.SalonName}</p></div>
    <div className={s.master_short_inf_salon}><p>Адрес: {props.Adress}</p></div>
    <div className={s.master_short_inf_salon_phone}><p>Телефон: {phone}</p></div>


    </div>
                </div>
                
        </div>
            
           
  

      </div>
      
      </form>
    )}
  </Popup>
)
       
  

};

export default ReviewModulforClient;
