import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { setItem, errorStatus, setItemCategory } from '../redux/action';

import CafeItem from '../companents/item-cafe';
import SearchInput from '../companents/search-input';
import { LoaderMain } from '../companents/loaders';
import SliderSwiper from '../companents/slider';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './style.css';

import card from '../asseets/card1.png';

class Main extends Component {
  async componentDidMount() {
    try {
      this.props.getData('data', 'cafe.cafeList');
    } catch (error) {
      this.props.errorStatus();
    }
  }

  //удалить, ссылаться на cafe.js componentDidMount
  selectedItem = async (id) => {
    const formData = await new FormData();
    await formData.append('id', id);
    const res = await fetch(`https://bikli.kz/apiBikli/index.php?method=cafe.edaById`, {
      method: 'POST',
      body: formData,
    });
    const json = await res.json();
    await this.props.setItem(json, 'itemCafe');

    const res1 = await fetch(`https://bikli.kz/apiBikli/index.php?method=cafe.cafeCategories`, {
      method: 'POST',
      body: formData,
    });
    const json1 = await res1.json();
    this.props.setItemCategory(json1);
  };

  searchValue = (e) => this.props.searchFilterInput(e);

  render() {
    return (
      <div className="main">
        <div className="main-wrapper">
          <h4 className="title">Специально для Вас</h4>

          <div className="slider">
            <SliderSwiper main={true}>
              {Array(6)
                .fill(card)
                .map((el, i) => (
                  <img key={i} src={el} alt="promo" />
                ))}
            </SliderSwiper>
          </div>

          <div className="line"></div>

          <div className="main-title-seacrh-input">
            <h4 className="name-group">Рестораны</h4>
            <SearchInput searchValue={this.searchValue} />
          </div>

          {this.props.data.length
            ? this.props.data &&
              this.props.newData.map((el) => (
                <CafeItem key={el.id} el={el} selectedItem={this.selectedItem} />
              ))
            : Array(4)
                .fill(<LoaderMain />)
                .map((el, i) => (
                  <div className="loader-main" key={i}>
                    {el}
                  </div>
                ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => data;

const mapDispatchToProps = { setItem, errorStatus, setItemCategory };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
