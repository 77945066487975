import React, { useEffect, useRef, useState } from 'react';
import s from './header.module.css';
import { useForm } from 'react-hook-form';
import beautyApi from '../../api/beautyApi';
import { useSelector, useDispatch } from 'react-redux';
import {
  PageDisplayType1,
  PageDisplayType2,
  PageDisplayType3,
  PageDisplayType4,
  PageDopDisplayType,
  onAddChildAllServForClientReq,
  onAddChildAllSDServsForClientReq,
  onAddServForClientReq_1,
  onAddServForClientReq_2,
  onAddServForClientReq_3,
  onAddServForClientReq_4,
  onAddServForClientReq_5,
  onAddServForClientReq_11,
  onAddServForClientReq_21,
  onAddServForClientReq_31,
  onAddServForClientReq_41,
  onAddServForClientReq_51,
} from '../../redux/action';
import Modal from '@mui/material/Modal';

const ChildSelectModalPage = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const [open, setOpen] = React.useState(false);
  const itemsChildAllServForClientReq = useSelector((state) => state.itemsChildAllServForClientReq);
  const itemsChildAllSDServsForClientReq = useSelector(
    (state) => state.itemsChildAllSDServsForClientReq,
  );
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [ChildDopCatName1, setCatName1] = useState('Не выбрано');
  const [ChildDopCatName2, setCatName2] = useState('Не выбрано');
  const [ChildDopCatName3, setCatName3] = useState('Не выбрано');
  const [ChildDopCatName4, setCatName4] = useState('Не выбрано');
  const [ChildDopCatName5, setCatName5] = useState('Не выбрано');

  const [DopCatID1, setCatID1] = useState('-');
  const [DopCatID2, setCatID2] = useState('-');
  const [DopCatID3, setCatID3] = useState('-');
  const [DopCatID4, setCatID4] = useState('-');
  const [DopCatID5, setCatID5] = useState('-');

  const [DopCatName11, setCatName11] = useState('Не выбрано');
  const [DopCatName21, setCatName21] = useState('Не выбрано');
  const [DopCatName31, setCatName31] = useState('Не выбрано');
  const [DopCatName41, setCatName41] = useState('Не выбрано');
  const [DopCatName51, setCatName51] = useState('Не выбрано');

  const [display_child1, setdisplay_child1] = useState('none');
  const [display_child2, setdisplay_child2] = useState('none');
  const [display_child3, setdisplay_child3] = useState('none');
  const [display_child4, setdisplay_child4] = useState('none');
  const [display_child5, setdisplay_child5] = useState('none');

  const [defaultValue, setDefaultValue] = useState('Выбрать услугу');

  const [dropDownDop, setDropDownDop] = useState(false);

  const itemsServForClientReq_1 = useSelector((state) => state.itemsServForClientReq_1);
  const itemsServForClientReq_2 = useSelector((state) => state.itemsServForClientReq_2);
  const itemsServForClientReq_3 = useSelector((state) => state.itemsServForClientReq_3);
  const itemsServForClientReq_4 = useSelector((state) => state.itemsServForClientReq_4);

  const display_flex1 = useSelector((state) => state.PageDisplayType1);
  const display_flex2 = useSelector((state) => state.PageDisplayType2);
  const display_flex3 = useSelector((state) => state.PageDisplayType3);
  const display_flex4 = useSelector((state) => state.PageDisplayType4);
  const dop_display_flex = useSelector((state) => state.PageDopDisplayType);

  const SecDopServs = (ids, prod_id_add, prof_add_dop) => {
    return {
      ids: ids,
      prod_id_add: prod_id_add,
      prof_add_dop: prof_add_dop,
      // ...другие свойства
    };
  };

  const DopServs = (ids, profs) => {
    return {
      ids: ids,
      profs: profs,
      // ...другие свойства
    };
  };

  if (itemsServForClientReq_1 == '') {
    ChildDopCatName1 !== 'Не выбрано' ? setCatName1('Не выбрано') : console.info();
    ChildDopCatName2 !== 'Не выбрано' ? setCatName2('Не выбрано') : console.info();
    ChildDopCatName3 !== 'Не выбрано' ? setCatName3('Не выбрано') : console.info();
    ChildDopCatName4 !== 'Не выбрано' ? setCatName4('Не выбрано') : console.info();
    ChildDopCatName5 !== 'Не выбрано' ? setCatName5('Не выбрано') : console.info();

    DopCatName11 !== 'Не выбрано' ? setCatName11('Не выбрано') : console.info();
    DopCatName21 !== 'Не выбрано' ? setCatName21('Не выбрано') : console.info();
    DopCatName31 !== 'Не выбрано' ? setCatName31('Не выбрано') : console.info();
    DopCatName41 !== 'Не выбрано' ? setCatName41('Не выбрано') : console.info();
    DopCatName51 !== 'Не выбрано' ? setCatName51('Не выбрано') : console.info();

    display_child1 !== 'none' ? setdisplay_child1('none') : console.info();
    display_child2 !== 'none' ? setdisplay_child2('none') : console.info();
    display_child3 !== 'none' ? setdisplay_child3('none') : console.info();
    display_child4 !== 'none' ? setdisplay_child4('none') : console.info();
    display_child5 !== 'none' ? setdisplay_child5('none') : console.info();

    display_flex1 !== 'none' ? dispatch(PageDisplayType1('none')) : console.info();
    display_flex2 !== 'none' ? dispatch(PageDisplayType2('none')) : console.info();
    display_flex3 !== 'none' ? dispatch(PageDisplayType3('none')) : console.info();
    display_flex4 !== 'none' ? dispatch(PageDisplayType4('none')) : console.info();

    defaultValue == 'Выбрать услугу' ? console.info() : setDefaultValue('Выбрать услугу');
    // setCatName11('Не выбрано');
    // setCatName21('Не выбрано');
    // setCatName31('Не выбрано');
    // setCatName41('Не выбрано');
    // setCatName51('Не выбрано');

    // setCatID1('');
    // setCatID2('');
    // setCatID3('');
    // setCatID4('');
    // setCatID5('');

    // dispatch(onAddServForClientReq_1(''));
    // dispatch(onAddServForClientReq_2(''));
    // dispatch(onAddServForClientReq_3(''));
    // dispatch(onAddServForClientReq_4(''));
    // dispatch(onAddServForClientReq_5(''));

    // setdisplay_child1('none');
    // setdisplay_child2('none');
    // setdisplay_child3('none');
    // setdisplay_child4('none');
    // setdisplay_child5('none');
  }

  useEffect(() => {
    if (itemsServForClientReq_1 == '') {
      setCatName1('Не выбрано');
      setCatName2('Не выбрано');
      setCatName3('Не выбрано');
      setCatName4('Не выбрано');
      setCatName5('Не выбрано');

      setCatName11('Не выбрано');
      setCatName21('Не выбрано');
      setCatName31('Не выбрано');
      setCatName41('Не выбрано');
      setCatName51('Не выбрано');

      setCatID1('');
      setCatID2('');
      setCatID3('');
      setCatID4('');
      setCatID5('');

      dispatch(onAddServForClientReq_1(''));
      dispatch(onAddServForClientReq_2(''));
      dispatch(onAddServForClientReq_3(''));
      dispatch(onAddServForClientReq_4(''));
      dispatch(onAddServForClientReq_5(''));

      setdisplay_child1('none');
      setdisplay_child2('none');
      setdisplay_child3('none');
      setdisplay_child4('none');
      setdisplay_child5('none');
    }
  }, []);

  useEffect(() => {
    {
      getDopServs();
      getSecondDopServs();
      setOpen(props.openPrev);
    }

    if (itemsServForClientReq_1 == '') {
      setCatName1('Не выбрано');
      setCatName2('Не выбрано');
      setCatName3('Не выбрано');
      setCatName4('Не выбрано');
      setCatName5('Не выбрано');

      setCatName11('Не выбрано');
      setCatName21('Не выбрано');
      setCatName31('Не выбрано');
      setCatName41('Не выбрано');
      setCatName51('Не выбрано');

      setCatID1('');
      setCatID2('');
      setCatID3('');
      setCatID4('');
      setCatID5('');
      dispatch(onAddServForClientReq_1(''));
      dispatch(onAddServForClientReq_2(''));
      dispatch(onAddServForClientReq_3(''));
      dispatch(onAddServForClientReq_4(''));
      dispatch(onAddServForClientReq_5(''));
      setdisplay_child1('none');
      setdisplay_child2('none');
      setdisplay_child3('none');
      setdisplay_child4('none');
      setdisplay_child5('none');
    }
  }, [props.catID]);

  useEffect(() => {
    ChildDopCatName1 == 'Не выбрано' ? console.info() : setDefaultValue(ChildDopCatName1);
  }, [ChildDopCatName1]);

  var warrObjects2 = [];
  const getDopServs = async () => {
    const responseAllDopServsForClient = await beautyApi.fetchServicesInReq2();
    let NewCatID = props.mainCatID;

    if (Number.isNaN(props.catID)) {
      NewCatID = props.mainCatID;
    } else if (props.catID == 0) {
      NewCatID = props.mainCatID;
    } else {
      NewCatID = props.catID;
    }

    for (let i = 0; i < responseAllDopServsForClient.data.length; i++) {
      if (responseAllDopServsForClient.data[i]['prof_id'] == NewCatID) {
        let user = DopServs(
          responseAllDopServsForClient.data[i]['id'],
          responseAllDopServsForClient.data[i]['prof_add'],
        );
        warrObjects2[i] = user;
      }
    }
    dispatch(onAddChildAllServForClientReq(warrObjects2));
  };

  var warrObjects3 = [];
  const getSecondDopServs = async () => {
    const responseAllDopServsForClient = await beautyApi.fetchServicesInReq3();

    for (let i = 0; i < responseAllDopServsForClient.data.length; i++) {
      let user = SecDopServs(
        responseAllDopServsForClient.data[i]['id'],
        responseAllDopServsForClient.data[i]['prod_id_add'],
        responseAllDopServsForClient.data[i]['prof_add_dop'],
      );
      warrObjects3[i] = user;
    }
    dispatch(onAddChildAllSDServsForClientReq(warrObjects3));
  };

  const handleServ_1 = (event) => {
    dispatch(onAddServForClientReq_1(event.target.value));
    itemsChildAllServForClientReq.map((x) =>
      event.target.value == x.profs ? setCatName1(x.profs) : console.info(),
    );
    itemsChildAllServForClientReq.map((x) =>
      event.target.value == x.profs ? handleChildServ_1(x.ids) : null,
    );
  };

  const handleChildServ_1 = (event) => {
    setCatID1(event);
    itemsChildAllSDServsForClientReq.map((x) =>
      event == x.prod_id_add ? setdisplay_child1('block') : null,
    );
  };

  const handleServ_11 = (event) => {
    dispatch(onAddServForClientReq_11(event.target.value));
    itemsChildAllSDServsForClientReq.map((x) =>
      event.target.value == x.prof_add_dop ? setCatName11(x.prof_add_dop) : null,
    );
  };

  const handleServ_2 = (event) => {
    dispatch(onAddServForClientReq_2(event.target.value));
    itemsChildAllServForClientReq.map((x) =>
      event.target.value == x.profs ? setCatName2(x.profs) : console.info(),
    );
    itemsChildAllServForClientReq.map((x) =>
      event.target.value == x.profs ? handleChildServ_2(x.ids) : null,
    );
  };

  const handleChildServ_2 = (event) => {
    setCatID2(event);
    itemsChildAllSDServsForClientReq.map((x) =>
      event == x.prod_id_add ? setdisplay_child2('block') : null,
    );
  };

  const handleServ_21 = (event) => {
    dispatch(onAddServForClientReq_21(event.target.value));
    itemsChildAllSDServsForClientReq.map((x) =>
      event.target.value == x.prof_add_dop ? setCatName21(x.prof_add_dop) : null,
    );
  };

  const handleServ_3 = (event) => {
    dispatch(onAddServForClientReq_3(event.target.value));
    itemsChildAllServForClientReq.map((x) =>
      event.target.value == x.profs ? setCatName3(x.profs) : console.info(),
    );
    itemsChildAllServForClientReq.map((x) =>
      event.target.value == x.profs ? handleChildServ_3(x.ids) : null,
    );
  };

  const handleChildServ_3 = (event) => {
    setCatID3(event);
    itemsChildAllSDServsForClientReq.map((x) =>
      event == x.prod_id_add ? setdisplay_child3('block') : null,
    );
  };

  const handleServ_31 = (event) => {
    dispatch(onAddServForClientReq_31(event.target.value));
    itemsChildAllSDServsForClientReq.map((x) =>
      event.target.value == x.prof_add_dop ? setCatName31(x.prof_add_dop) : null,
    );
  };

  const handleServ_4 = (event) => {
    dispatch(onAddServForClientReq_4(event.target.value));
    itemsChildAllServForClientReq.map((x) =>
      event.target.value == x.profs ? setCatName4(x.profs) : console.info(),
    );
    itemsChildAllServForClientReq.map((x) =>
      event.target.value == x.profs ? handleChildServ_4(x.ids) : null,
    );
  };

  const handleChildServ_4 = (event) => {
    setCatID4(event);
    itemsChildAllSDServsForClientReq.map((x) =>
      event == x.prod_id_add ? setdisplay_child4('block') : null,
    );
  };

  const handleServ_41 = (event) => {
    dispatch(onAddServForClientReq_41(event.target.value));
    itemsChildAllSDServsForClientReq.map((x) =>
      event.target.value == x.prof_add_dop ? setCatName41(x.prof_add_dop) : null,
    );
  };

  const handleServ_5 = (event) => {
    dispatch(onAddServForClientReq_5(event.target.value));
    itemsChildAllServForClientReq.map((x) =>
      event.target.value == x.profs ? setCatName5(x.profs) : console.info(),
    );
    itemsChildAllServForClientReq.map((x) =>
      event.target.value == x.profs ? handleChildServ_5(x.ids) : null,
    );
  };

  const handleChildServ_5 = (event) => {
    setCatID5(event);
    itemsChildAllSDServsForClientReq.map((x) =>
      event == x.prod_id_add ? setdisplay_child5('block') : null,
    );
  };

  const handleServ_51 = (event) => {
    dispatch(onAddServForClientReq_51(event.target.value));
    itemsChildAllSDServsForClientReq.map((x) =>
      event.target.value == x.prof_add_dop ? setCatName51(x.prof_add_dop) : null,
    );
  };

  const plusButton = () => {
    // setDropDownDop(!dropDownDop);
    // console.log(itemsServForClientReq_1)
    itemsServForClientReq_1 !== '' ? dispatch(PageDisplayType1('inline')) : console.info();
    itemsServForClientReq_2 !== '' ? dispatch(PageDisplayType2('inline')) : console.info();
    itemsServForClientReq_3 !== '' ? dispatch(PageDisplayType3('inline')) : console.info();
    itemsServForClientReq_4 !== '' ? dispatch(PageDisplayType4('inline')) : console.info();
    itemsServForClientReq_4 !== '' ? dispatch(PageDopDisplayType('none')) : console.info();

    // dispatch(DopDisplayType('none'));
  };

  return (
    <>
      <span className={s.dropdown_dop_serv} onClick={handleOpen}>
        {defaultValue}
      </span>
      <Modal open={open} onClose={handleClose}>
        <div className={s.modal_child}>
          <button className={s.close} onClick={handleClose}>
            &times;
          </button>
          <div className={s.content}>
            <select className={s.dropdown_serv} onChange={(e) => handleServ_1(e)}>
              <option defaultValue='defaultValue'>{ChildDopCatName1}</option>
              {itemsChildAllServForClientReq.map((x) => (
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>
              ))}
            </select>

            <select
              style={{ display: `${display_child1}` }}
              onChange={(e) => handleServ_11(e)}
              className={s.dropdown_sec_serv}
            >
              <option defaultValue='defaultValue'>{DopCatName11}</option>
              {itemsChildAllSDServsForClientReq.map((x) => {
                if (DopCatID1 == x.prod_id_add) {
                  return (
                    <option key={x.ids} value={x.prof_add_dop}>
                      {x.prof_add_dop}
                    </option>
                  );
                }
              })}
            </select>

            <select
              style={{ display: `${display_flex1}` }}
              className={s.dropdown_serv}
              onChange={(e) => handleServ_2(e)}
            >
              <option defaultValue='defaultValue'>{ChildDopCatName2}</option>
              {itemsChildAllServForClientReq.map((x) => (
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>
              ))}
            </select>

            <select
              style={{ display: `${display_child2}` }}
              onChange={(e) => handleServ_21(e)}
              className={s.dropdown_sec_serv}
            >
              <option defaultValue='defaultValue'>{DopCatName21}</option>
              {itemsChildAllSDServsForClientReq.map((x) => {
                if (DopCatID2 == x.prod_id_add) {
                  return (
                    <option key={x.ids} value={x.prof_add_dop}>
                      {x.prof_add_dop}
                    </option>
                  );
                }
              })}
            </select>

            <select
              style={{ display: `${display_flex2}` }}
              className={s.dropdown_serv}
              onChange={(e) => handleServ_3(e)}
            >
              <option defaultValue='defaultValue'>{ChildDopCatName3}</option>
              {itemsChildAllServForClientReq.map((x) => (
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>
              ))}
            </select>

            <select
              style={{ display: `${display_child3}` }}
              onChange={(e) => handleServ_31(e)}
              className={s.dropdown_sec_serv}
            >
              <option defaultValue='defaultValue'>{DopCatName31}</option>
              {itemsChildAllSDServsForClientReq.map((x) => {
                if (DopCatID3 == x.prod_id_add) {
                  return (
                    <option key={x.ids} value={x.prof_add_dop}>
                      {x.prof_add_dop}
                    </option>
                  );
                }
              })}
            </select>

            <select
              style={{ display: `${display_flex3}` }}
              className={s.dropdown_serv}
              onChange={(e) => handleServ_4(e)}
            >
              <option defaultValue='defaultValue'>{ChildDopCatName4}</option>
              {itemsChildAllServForClientReq.map((x) => (
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>
              ))}
            </select>

            <select
              style={{ display: `${display_child4}` }}
              onChange={(e) => handleServ_41(e)}
              className={s.dropdown_sec_serv}
            >
              <option defaultValue='defaultValue'>{DopCatName41}</option>
              {itemsChildAllSDServsForClientReq.map((x) => {
                if (DopCatID4 == x.prod_id_add) {
                  return (
                    <option key={x.ids} value={x.prof_add_dop}>
                      {x.prof_add_dop}
                    </option>
                  );
                }
              })}
            </select>

            <select
              style={{ display: `${display_flex4}` }}
              className={s.dropdown_serv}
              onChange={(e) => handleServ_5(e)}
            >
              <option defaultValue='defaultValue'>{ChildDopCatName5}</option>
              {itemsChildAllServForClientReq.map((x) => (
                <option key={x.ids} value={x.profs}>
                  {x.profs}
                </option>
              ))}
            </select>

            <select
              style={{ display: `${display_child5}` }}
              onChange={(e) => handleServ_51(e)}
              className={s.dropdown_sec_serv}
            >
              <option defaultValue='defaultValue'>{DopCatName51}</option>
              {itemsChildAllSDServsForClientReq.map((x) => {
                if (DopCatID5 == x.prod_id_add) {
                  return (
                    <option key={x.ids} value={x.prof_add_dop}>
                      {x.prof_add_dop}
                    </option>
                  );
                }
              })}
            </select>

            <div
              className={s.dop_section}
              style={{ display: `${dop_display_flex}` }}
              onClick={() => plusButton()}
            >
              {!dropDownDop ? 'Еще одна услуга' : 'Еще одна услуга'}
            </div>
          </div>
          <button className={s.beauty_button_confirm} onClick={handleClose}>
            Ок
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ChildSelectModalPage;
