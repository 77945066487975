import React, { useEffect, useRef, useState } from 'react';
import s from './req.module.css';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';
import { useDispatch } from 'react-redux';
import { onItemGo, onScrollCatalog } from '../../../redux/action';
import Popup from 'reactjs-popup';
import AcceptModulforClient from './AcceptModulforClient';
import Comm from '../../../asseets/Frame 348.png';
import Comm2 from '../../../asseets/Frame 349.png';
const MyRequestForCalendar = ({
  FIO,
  date,
  id,
  orderName,
  orderName2,
  orderName3,
  orderName4,
  orderName5,
  phome,
  price,
  status,
  time,
  comm,
  img,
  clientId,
  Adress,
  masterFIO,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dropDownComm, setDropDownComm] = useState(false);
  const [dropDownReqAccept, setDropDownReqAccept] = useState(false);
  const options = { weekday: 'long', month: 'long', day: 'numeric' };

  const day = new Date(date);

  const toItem = () => {
    dispatch(onItemGo(true));
    dispatch(onScrollCatalog(window.pageYOffset));
  };

  const ReqAccept = () => {
    setDropDownReqAccept(!dropDownReqAccept);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <div
        className={s.item_bshop}
        style={{ boxShadow: 'none', backgroundImage: 'none' }}
        key={uniqid()}
      >
        <div className={s.item_client_req_info}>
          <div style={{ marginBottom: '8px' }} className={s.item_client_req_info_name_id}>
            <div style={{ width: '180px' }} className={s.item_client_req_info_date}>
              {time == '' ? 'Время не задано' : time} <br />
            </div>
            <div style={{ width: '180px' }} className={s.item_client_req_info_date}>
              {orderName} <br />
            </div>
            {orderName2 == '' ? null : (
              <div style={{ width: '180px' }} className={s.item_client_req_info_date}>
                {orderName2} <br />
              </div>
            )}
            {orderName3 == '' ? null : (
              <div style={{ width: '180px' }} className={s.item_client_req_info_date}>
                {orderName3} <br />
              </div>
            )}
            {orderName4 == '' ? null : (
              <div style={{ width: '180px' }} className={s.item_client_req_info_date}>
                {orderName4} <br />
              </div>
            )}
            {orderName5 == '' ? null : (
              <div style={{ width: '180px' }} className={s.item_client_req_info_date}>
                {orderName5} <br />
              </div>
            )}

            {/* <div className={s.item_client_req_info_time}>{time}</div> */}
          </div>
          {/* <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Категория: {status}</div>
              <div></div>
              <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Дата: {day.toLocaleDateString("ru-ru", options)}</div>
              <div></div>
              <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Время: {time}</div>
              <div className={s.item_client_req_info_comm_price}>
                  <div 
                  style = {{marginLeft: '15px'}} 
                  className={s.item_client_req_info_price}>Цена: {price} тнг</div>
              </div> */}

          {comm == '' ? (
            <div style={{ display: 'inline-block', position: 'absolute', right: '40px' }}>
              {' '}
              <img src={Comm2} />
            </div>
          ) : (
            <Popup
              trigger={
                <div style={{ display: 'inline-block', position: 'absolute', right: '40px' }}>
                  {' '}
                  <img src={Comm} />
                </div>
              }
              modal
              nested
            >
              {(close) => (
                <div style={{ height: `216px` }} className={s.modal_comm}>
                  <p>
                    <b>Комментарий клиента</b>
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>{comm}</p>
                  <button
                    onClick={close}
                    style={{
                      textAlign: 'center',
                      height: '32px',
                      width: '152px',
                      textDecoration: `none`,
                      position: 'absolute',
                      top: '170px',
                      left: '70px',
                    }}
                    className={s.beauty_button_confirm}
                  >
                    Понятно
                  </button>
                </div>
              )}
            </Popup>
          )}

          <div
            style={{
              display: 'block',
              fontSize: '12px',
              float: 'left',
              marginLeft: '10px',
              lineHeight: '18px',
              fontFamily: 'Gotham Pro Regular',
              textAlign: 'left',
            }}
          >
            Дата:
            <br /> Стоимость:
            <br /> Мастер:
          </div>
          <div
            style={{
              display: 'inline-block',
              marginBottom: '10px',
              textAlign: 'left',
              width: 'auto',
            }}
            className={s.item_client_req_info_nameClient}
          >
            <b>{day.toLocaleDateString('ru-ru', options)}</b>
            <br />
            {price} тг
            <br />
            {masterFIO}
            <br />
            {Adress}
          </div>
        </div>
      </div>

      {/* <AcceptModulforClient orderName={orderName} date={date} id={id} phome={phome} price={price} time={time} clientId={clientId} /> */}
    </div>
  );
};

export default MyRequestForCalendar;
