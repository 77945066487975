import React, { useEffect, useRef, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import beautyApi from '../../../api/beautyApi';
import { accountBeauty } from '../../../redux/action';
import s from '../pageMaster.module.css';
import no_avatar from '../style/no-avatar.png';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import objToFd from '../../../common/helpers/objToFd';
import ImageResize from 'image-resize';
import useReactIpLocation from 'react-ip-details';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  osName,
  osVersion,
  mobileVendor,
  mobileModel,
  deviceDetect,
} from 'react-device-detect';
const ProfileClientEdit = () => {
  const dispatch = useDispatch();
  const accountBeautyInfo = useSelector((state) => state.accountBeauty);
  const avatar = useRef();
  const history = useHistory();
  const targetScroll = useRef(null);
  let phone = '+ ';
  accountBeautyInfo.Phone
    ? (phone =
        '+ ' +
        accountBeautyInfo.Phone.substr(0, 1) +
        '-(' +
        accountBeautyInfo.Phone.substr(1, 3) +
        ')-' +
        accountBeautyInfo.Phone.substr(4, 3) +
        '-' +
        accountBeautyInfo.Phone.substr(7, 2) +
        '-' +
        accountBeautyInfo.Phone.substr(9, 2))
    : console.info();
  const time = localStorage.getItem('time');
  const { ipResponse } = useReactIpLocation({ numberToConvert: 100 });

  const [cities, setCities] = useState([{}]);
  const [currentCity, setCurrentCity] = useState('');

  const { register, handleSubmit, reset } = useForm();
  const { register: registerCity, handleSubmit: handleSubmitCity, reset: resetCity } = useForm();

  const editData = async () => {
    if (avatar.current.files[0]) {
      const form = new FormData();
      var imageResize = new ImageResize({
        format: 'png',
        width: 150,
      });
      let res = await imageResize.play(avatar.current.files[0]);

      // form.append('userfile', avatar.current.files[0], avatar.current.files[0].name);
      form.append('userfile', res);
      form.append('id', accountBeautyInfo.id);
      const uploadAvatar = beautyApi.uploadAvatarUser(form);
      uploadAvatar.then((res) => res).then((res) => window.location.reload());
    }
  };

  useEffect(() => {
    getCityList();
  }, []);
  const getCityList = async () => {
    let cities = await fetch('https://beauty.bikli.kz/beautyApi/getListOfCities.php');
    cities = await cities.json();
    setCities(cities);
  };
  useEffect(() => {
    if (accountBeautyInfo.city != 0) {
      let cCity = cities.filter((data) => {
        return data.city_id == accountBeautyInfo.city;
      });
      if (cCity.length != 0) {
        setCurrentCity(cCity[0].city);
      } else {
        setCurrentCity('Не указано');
      }
    }
  }, [cities]);

  const onSubmit = async (data) => {
    console.log(data);
    const obj = {
      ...data,
    };

    const fd = new FormData();
    await objToFd(fd, obj);
    await fd.append('clientId', accountBeautyInfo.id);
    await beautyApi.NameEdit(fd);
    await reset();
    window.location.reload();
  };

  const onSubmitCity = async (data) => {
    console.log(data);
    const obj = {
      ...data,
    };

    const fd = new FormData();
    await objToFd(fd, obj);
    await fd.append('clientId', accountBeautyInfo.id);
    await beautyApi.CityEdit(fd);
    await resetCity();
    window.location.reload();
  };

  const exitAccount = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append('id', accountBeautyInfo.id);
    fd.append('time', time);
    fd.append('ip', ipResponse?.IPv4);
    fd.append('osName', osName);
    fd.append('osVersion', osVersion);
    fd.append('mobileVendor', mobileVendor);
    fd.append('mobileModel', mobileModel);
    beautyApi.logout(fd);
    dispatch(accountBeauty([]));
    localStorage.removeItem('account');
    localStorage.removeItem('applications');
    history.push('/beauty/profile');
    localStorage.removeItem('time');
    window.location.reload();
  };

  return (
    <div>
      <>
        <div className={s.headerEdit} ref={targetScroll}>
          <label htmlFor='file-input'>
            <div className={s.avatarEdit}>
              {accountBeautyInfo.img && accountBeautyInfo.img != '' ? (
                <img
                  src={`https://bshop.kz/beauty/AvatarPhotos/${accountBeautyInfo.img}`}
                  alt='avatar'
                />
              ) : (
                <img src={no_avatar} alt='avatar' />
              )}
            </div>
            <span>Изменить фото профиля</span>
          </label>
        </div>
        <div className={s.infoEdit}>
          <div className={s.row}>
            <div>Имя</div>
            <div className={s.field}>{accountBeautyInfo.FIO}</div>
            <Popup
              trigger={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  style={{
                    display: 'inline',
                    marginLeft: '-20px',
                    marginRight: '5px',
                  }}
                  viewBox='0 0 16 16'
                >
                  <path d='M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z' />
                </svg>
              }
              modal
              nested
            >
              {(close) => (
                <form method='post' onSubmit={handleSubmit(onSubmit)}>
                  <div style={{ marginTop: '120px' }} className={s.modal}>
                    <button style={{ top: '130px' }} className={s.close} onClick={close}>
                      &times;
                    </button>
                    <div className={s.EditHeader}>Изменение имени</div>
                    <div className={s.contents}>
                      <div className={s.input_label}>
                        <input
                          {...register('name', {
                            required: 'Поле обязательно к заполнению',
                          })}
                          type='text'
                          placeholder='Введите новое Имя'
                        />
                      </div>
                    </div>
                    <button className={s.beauty_button_confirm}>Отправить</button>
                  </div>
                </form>
              )}
            </Popup>
          </div>
          <div className={s.row}>
            <div>Номер телефона</div>
            <div className={s.field}>{phone}</div>
          </div>
          <div className={s.row}>
            <div>E-mail</div>
            <div className={s.field}>{accountBeautyInfo.Email}</div>
          </div>
          <div className={s.row}>
            <div>Город</div>
            <div className={s.field}>{currentCity}</div>
            <Popup
              trigger={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  style={{
                    display: 'inline',
                    marginLeft: '-20px',
                    marginRight: '5px',
                  }}
                  viewBox='0 0 16 16'
                >
                  <path d='M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z' />
                </svg>
              }
              modal
              nested
            >
              {(close) => (
                <form method='post' onSubmit={handleSubmitCity(onSubmitCity)}>
                  <div style={{ marginTop: '120px' }} className={s.modal}>
                    <button style={{ top: '130px' }} className={s.close} onClick={close}>
                      &times;
                    </button>
                    <div className={s.EditHeader}>Изменение города</div>
                    <div className={s.contents}>
                      <div className={s.input_label}>
                        <select
                          {...registerCity('city_id', {
                            required: 'Поле обязательно к заполнению',
                          })}
                        >
                          <option value='0'>Не указано</option>
                          {cities.map((item) => (
                            <option key={item.city_id} value={item.city_id}>
                              {item.city}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <button className={s.beauty_button_confirm}>Отправить</button>
                  </div>
                </form>
              )}
            </Popup>
          </div>
        </div>
        <form name='registration'>
          <input
            id='file-input'
            type='file'
            name='file'
            multiple
            style={{ display: 'none' }}
            ref={avatar}
            onChange={() => editData()}
          />
        </form>
        <div className={s.exitButton}>
          {/* <button onClick={(e) => history.push('/beauty/master/addbalance')}>
            Пополнить баланс
          </button> */}
          <button onClick={(e) => exitAccount(e)}>Выйти</button>
        </div>
      </>
    </div>
  );
};

export default ProfileClientEdit;
