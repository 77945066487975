import React, { useCallback, useEffect, useRef, useState } from 'react';
import s from '../../headerNew/header.module.css';
import Popup from 'reactjs-popup';
import { height } from '@mui/system';
import chooseBeauty from '../../../asseets/Beauty.png';

const FirstTimePopup = (props) => {
  const [open, setOpen] = useState(true);
  const FirstTimeUseApp = localStorage.getItem('FirstTimeUseApp');

  const closeModal = () => {
    console.log(1);
    setOpen(false);
    FirstTimeUseApp
      ? localStorage.setItem('FirstTimeUseApp', Number(FirstTimeUseApp) + 1)
      : localStorage.setItem('FirstTimeUseApp', 1);
    localStorage.setItem('FirstTimeUseAppCheck', 0);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Popup open={open} modal nested>
        <div
          className={s.modal}
          style={{ height: '100vh', background: 'transparent', border: 'none' }}
        >
          <div
            className={s.content}
            style={{
              background: '#ffffff',

              borderRadius: '20px',
              width: '95%',
              margin: 'auto',
              marginTop: '40%',
            }}
          >
            <div className={s.header}> Beauty </div>
            {/* <button
              className={s.close_main}
              onClick={() => {
                closeModal();
              }}
              style={{ top: '18%' }}
            >
              &times;
            </button> */}

            <img src={chooseBeauty} alt='' />
            <div className={s.contentText}>Срочно нужна beauty-услуга?</div>
            <div className={s.contentText}>Создайте свою первую заявку!</div>
            <div className={s.contentText}>Выбирайте категорию</div>
            <div className={s.contentText}>Предложите цену</div>
            <div className={s.contentText}>Ожидайте предложения от самых лучших мастеров</div>

            <button
              style={{ marginTop: '40px' }}
              className={s.beauty_button_confirm}
              onClick={() => {
                closeModal();
              }}
            >
              Ок
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default FirstTimePopup;
