
import React, { useEffect, useState } from 'react';
import beautyApi from '../../../../api/beautyApi';
import ServiceInCatalog from './ServiceInCatalog';
import s from './ServiceWrapper.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import ServicesLoadingBlock from './ServicesLoadingBlock';
import { useSelector, useDispatch } from 'react-redux';
import { onSearchItemAllServ, onSearchItemPubl, onScrollCatalog, AddCountItemAllServ, onAddAllServ} from '../../../../redux/action';
import uniqid from 'uniqid';

const ServicesWrapper = (props) => {
  const dispatch = useDispatch();
  const {ServicesValue, ServiceEnd, onShopId, onCategoryId } = useSelector((state) => state.onSearchItemAllServ);
  const AllServ = useSelector((state) => state.itemsAllServ);
  const ServItems = useSelector((state) => state.ServItems);
  const beenScroll = useSelector((state) => state.scrollCatalog);
  let cat = localStorage.getItem('cat');
  cat == 'Космето-логия' ? cat = 'Косметология' : 
  cat == 'Барбер-шоп' ? cat = 'Барбер' : console.info();
  const urlPath = window.location.pathname;
  const itemsAllServForClientMainReq = useSelector((state) => state.itemsAllServForClientMainReq);

  const getMasters = async (ServiceEnd, ServicesValue) => {
    
    itemsAllServForClientMainReq.map((x) => cat == x.prof ? localStorage.setItem('catID', x.id) : null)
    const catID = localStorage.getItem('catID');

    const responseAllServ = await beautyApi.fetchServicesInCatalog(ServiceEnd, catID);
    dispatch(onAddAllServ(responseAllServ.data));

    if (beenScroll && urlPath.slice(0, 15) === '/shopper/shopid') {
      await window.scrollTo({
        top: beenScroll,
      });
    }
  };
  
  useEffect(() => {
    if (AllServ.length === 0) {
      getMasters(ServItems, '', '3', '');
    }
  }, []);

  useEffect(() => {
    if (AllServ.length !== 0) {
      if (ServicesValue === '' && onShopId === '' && onCategoryId === '') {
        getMasters(ServItems, '', '3', '');
      } else {

        getMasters(ServiceEnd, ServicesValue, onShopId, onCategoryId);
      }
    }
  }, [ServItems, ServicesValue, ServiceEnd, onShopId, onCategoryId]);

  const fetchNewItems = () => {
    dispatch(onScrollCatalog(''));
    if (ServicesValue === '' && onShopId === '' && onCategoryId === '') {
      dispatch(AddCountItemAllServ(ServItems + 2));
    } else {
      dispatch(
        onSearchItemAllServ({
          ServicesValue: ServicesValue,
          ServiceEnd: ServiceEnd + 2
        }),
      );
    }
  };

  return (
    
<div
      className={s.all_items}
      id="scrollableDiv"
      style={{ overflowY: 'scroll', overflowX: 'hidden' }}>

      {AllServ.length > 0 ? (
        <InfiniteScroll
          style={{ }}
          dataLength={AllServ.length}
          next={fetchNewItems}
          hasMore={true}
          loader={<div style={{ width: '50%' }}>
            {/* {Array.from(Array(4), (_, i) => (
                      <LoadingBlock key={uniqid()} />
                    ))} */}
          </div>}
          sscrollableTarget="scrollableDiv">
          {AllServ.map((item) => (
                <ServiceInCatalog key={uniqid()} {...item} />
              ))}
        </InfiniteScroll>
      ) : (
        <p>Категории услуг пока что не добавлены &#9203;</p>
        // Array.from(Array(4), (_) => <ServicesLoadingBlock key={uniqid()} />)
      )}
    </div>
  );
};

export default React.memo(ServicesWrapper);
