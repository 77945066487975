import React, { Component } from 'react';

import { connect } from 'react-redux'

import './style.css'

import Button from '../button';

import close from '../../asseets/close-gray.png'

class Card extends Component {

    render() {
        const { opis, name, telefon, mail } = Object.assign({}, ...this.props.restoran);
        return (
            <div className='modal-menu' data-type='main' onClick={(e) => this.props.closeModalEvent(e.target.dataset.type)}>
                <div className='card-product'>
                    <img src={close} alt='close' className='close-card-product' onClick={this.props.closeModal} />

                    <div className='cardRestoran-product-wrapper'>
                        <div className='cardRestoran-product-info'>
                            <h3>{name}</h3>
                            <span>{opis}</span>
                            <div>
                                <p className='contact-info-rostoran'>{telefon}</p>
                                <p className='contact-info-rostoran'>{mail}</p>
                            </div>
                        </div>

                    </div>

                    <div className='line-card'></div>

                    <div className='item-num card-num'>
                        <Button value='Закрыть' class='btn_basket_card' click={this.props.closeModal} />
                    </div>

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(Card);