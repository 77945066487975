import React from 'react';
import './loadingBlock.css';
const LoadingBlock = () => {
  return (
    <div className="holder">
      <div className="preloader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingBlock;
