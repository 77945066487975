import BlogCard from './BlogCard'
import s from './BlogOfMaster.module.css'

const BlogOfMaster = () => {
    return (
        <div className={s.mainContainer}>
            <BlogCard />
            <BlogCard />
            <BlogCard />
            <BlogCard />
            <BlogCard />
            <BlogCard />
        </div>
    )
}

export default BlogOfMaster