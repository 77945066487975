import React from 'react';
import s from './policy.module.css';
const Policy = () => {
  return (
    <div className={s.root}>
      <center>
        <div>
          <b>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ </b>
        </div>
      </center>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>г. Павлодар </div>
        <div>«____»___________2022г.</div>
      </div>
      <br />
      Политика обработки персональных данных (далее – Политика) разработана в соответствии с Законом
      Республики Казахстан «О Персональных данных и их защите» от 21 мая 2013 года №94 (с
      дополнениями и изменениями по состоянию на 30.12.21.г.) – далее – Закон № 94. <br />
      Настоящая Политика определяет порядок обработки персональных данных и меры по обеспечению
      безопасности персональных данных в ТОО «BFCroup Inc» (далее – Оператор) с целью защиты прав и
      свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на
      неприкосновенность частной жизни, личную и семейную тайну. <br /> Настоящая Политика также
      действует в отношении всей информации, которая занесена в Приложение BIKLI (далее по тексту –
      Приложение). <br />В Политике используются следующие основные понятия: <br /> 1) персональные
      данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому
      физическому лицу (субъекту персональных данных); <br />
      2) оператор - государственный орган, муниципальный орган, юридическое или физическое лицо,
      самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку
      персональных данных, а также определяющие цели обработки персональных данных, состав
      персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными
      данными; <br />
      3) обработка персональных данных - любое действие (операция) или совокупность действий
      (операций), совершаемых с использованием средств автоматизации или без использования таких
      средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение,
      уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
      предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных
      данных; <br />
      4) автоматизированная обработка персональных данных - обработка персональных данных с помощью
      средств вычислительной техники;
      <br />
      5) распространение персональных данных - действия, направленные на раскрытие персональных
      данных неопределенному кругу лиц; <br />
      6) предоставление персональных данных - действия, направленные на раскрытие персональных
      данных определенному лицу или определенному кругу лиц;
      <br />
      7) блокирование персональных данных - временное прекращение обработки персональных данных (за
      исключением случаев, если обработка необходима для уточнения персональных данных); <br />
      8) уничтожение персональных данных - действия, в результате которых становится невозможным
      восстановить содержание персональных данных в информационной системе персональных данных и
      (или) в результате которых уничтожаются материальные носители персональных данных; <br />
      9) обезличивание персональных данных - действия, в результате которых становится невозможным
      без использования дополнительной информации определить принадлежность персональных данных
      конкретному субъекту персональных данных; <br />
      10) информационная система персональных данных - совокупность содержащихся в базах данных
      персональных данных и обеспечивающих их обработку информационных технологий и технических
      средств; <br />
      11) Пользователь – лицо, имеющее доступ к Приложению, посредством сети Интернет. Компания
      обязана опубликовать или иным образом обеспечить неограниченный доступ к настоящей Политике
      обработки персональных данных в соответствии с нормами Закона № 94.
      <br />
      <br />
      <center>
        <div>
          <b>Статья 1. ОБЩИЕ ПОЛОЖЕНИЯ</b>
        </div>
      </center>
      <br />
      1. Использование Пользователем Приложения означает согласие с настоящей Политикой
      конфиденциальности и условиями обработки персональных данных Пользователя.
      <br /> 2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен
      прекратить использование Приложения. <br />
      3. Настоящая Политика конфиденциальности применяется только к Приложению указанному в Политике
      конфиденциальности. Оператор не контролирует и не несет ответственность за приложения третьих
      лиц, на которые Пользователь может перейти по ссылкам, доступным при использовании Приложения.
      <br />
      4. Персональные данные, разрешённые к обработке в рамках настоящей Политики
      конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы и
      включают в себя следующую информацию: <br />
      <br />
      фамилию, имя, отчество Пользователя; <br />
      <br />
      контактный телефон Пользователя; <br />
      <br />
      адрес электронной почты (e-mail); <br />
      <br />
      дальнейшее перечисление информации, которая собирается.
      <br />
      <br />
      <center>
        <div>
          <b> Статья 2. ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ </b>
        </div>
      </center>
      <br />
      1. Приложение собирает и хранит только те персональные данные, которые необходимы для
      предоставления и оказания услуг (исполнения соглашений и договоров с пользователем). <br />
      2. Персональную информацию пользователя Приложение может использовать в следующих целях:{' '}
      <br />
      2.1. Идентификация стороны в рамках соглашений и договоров. <br />
      2.2. Предоставление пользователю персонализированных услуг;
      <br /> 2.3. Связь с пользователем, в том числе направление уведомлений, запросов и информации,
      касающихся использования Сервисов, оказания услуг, а также обработка запросов и заявок от
      пользователя; <br />
      2.4. Улучшение качества, удобства их использования, разработка услуг; <br />
      2.5. Таргетирование рекламных материалов; <br />
      2.6. Проведение статистических и иных исследований на основе обезличенных данных
      <br />
      <br />
      <center>
        <div>
          <b> Статья 3. ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</b>
        </div>
      </center>
      <br /> 1. Обработка персональных данных у Оператора осуществляется на основе следующих
      принципов: <br />- законности и справедливой основы; <br />- ограничения обработки
      персональных данных достижением конкретных, заранее определенных и законных целей;
      <br /> - недопущения обработки персональных данных, несовместимой с целями сбора персональных
      данных;
      <br /> - недопущения объединения баз данных, содержащих персональные данные, обработка которых
      осуществляется в целях, несовместимых между собой; <br />- обработки только тех персональных
      данных, которые отвечают целям их обработки; соответствия содержания и объема обрабатываемых
      персональных данных заявленным целям обработки; <br />- недопущения обработки персональных
      данных, избыточных по отношению к заявленным целям их обработки; <br />- обеспечения точности,
      достаточности и актуальности персональных данных по отношению к целям обработки персональных
      данных; <br />- уничтожения либо обезличивания персональных данных по достижении целей их
      обработки или в случае утраты необходимости в достижении этих целей, при невозможности
      устранения Оператором допущенных нарушений персональных данных, если иное не предусмотрено
      нормами Закона №94..
      <br />
      <br />
      <center>
        <div>
          <b> Статья 4. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</b>
        </div>
      </center>
      <br /> 1. Оператор производит обработку персональных данных при наличии хотя бы одного из
      следующих условий: <br />
      1.1. обработка персональных данных осуществляется с согласия субъекта персональных данных на
      обработку его персональных данных; <br />
      1.2. обработка персональных данных необходима для осуществления правосудия, исполнения
      судебного акта, акта другого органа или должностного лица, подлежащих исполнению в
      соответствии с законодательством Республики Казахстан об исполнительном производстве; <br />
      1.3. обработка персональных данных необходима для исполнения договора, стороной которого либо
      выгодоприобретателем или поручителем по которому является субъект персональных данных, а также
      для заключения договора по инициативе субъекта персональных данных или договора, по которому
      субъект персональных данных будет являться выгодоприобретателем или поручителем; <br />
      1.4. обработка персональных данных необходима для осуществления прав и законных интересов
      оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при
      этом не нарушаются права и свободы субъекта персональных данных; <br />
      1.5. осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым
      предоставлен субъектом персональных данных либо по его просьбе (далее - общедоступные
      персональные данные); <br />
      1.6. осуществляется обработка персональных данных, подлежащих опубликованию или обязательному
      раскрытию в соответствии с нормами Закона №94.
      <br />
      <br />
      <center>
        <div>
          <b> Статья 5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</b>
        </div>
      </center>
      <br /> 1. Обработка и хранение персональных данных Пользователя осуществляется без ограничения
      срока, любым законным способом, в том числе в информационных системах персональных данных с
      использованием средств автоматизации или без использования таких средств. <br />
      2. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной
      власти Республики Казахстан только по основаниям и в порядке, установленным законодательством
      Республики Казахстан.
      <br />
      <br />
      <center>
        <div>
          <b> Статья 6. КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ</b>
        </div>
      </center>
      <br /> 1. Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать
      третьим лицам и не распространять персональные данные без согласия субъекта персональных
      данных, если иное не предусмотрено нормами Закона №94. <br />
      2. Оператор принимает необходимые организационные и технические меры для защиты персональной
      информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения,
      блокирования, копирования, распространения, а также от иных неправомерных действий третьих
      лиц.
      <br />
      <br />
      <center>
        <div>
          <b> Статья 7. ОБЩЕДОСТУПНЫЕ ИСТОЧНИКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</b>
        </div>
      </center>
      <br />
      1. В целях информационного обеспечения у Оператора могут создаваться общедоступные источники
      персональных данных субъектов, в том числе справочники и адресные книги. В общедоступные
      источники персональных данных с письменного согласия субъекта могут включаться его фамилия,
      имя, отчество, дата и место рождения, должность, номера контактных телефонов, адрес
      электронной почты и иные персональные данные, сообщаемые субъектом персональных данных. <br />
      2. Сведения о субъекте должны быть в любое время исключены из общедоступных источников
      персональных данных по требованию субъекта либо по решению суда или иных уполномоченных
      государственных органов.
      <br />
      <br />
      <center>
        <div>
          <b> Статья 8. ПЕРЕДАЧА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ТРЕТЬИМ ЛИЦАМ </b>
        </div>
      </center>
      <br />
      1. Оператор вправе передать персональную информацию пользователя третьим лицам в следующих
      случаях: <br />
      1.1. Пользователь выразил свое согласие на такие действия; <br />
      1.2. Передача необходима в рамках использования пользователем определенного Сервиса либо для
      оказания услуги пользователю; <br />
      1.3. Передача предусмотрена казахстанским или иным применимым законодательством в рамках
      установленной законодательством процедуры; <br />
      1.4. Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в
      части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей
      Политики применительно к полученной им персональной информации; <br />
      1.5. В целях обеспечения возможности защиты прав и законных интересов Приложения или третьих
      лиц в случаях, когда пользователь нарушает Пользовательское соглашение.
      <br /> 2. При обработке персональных данных пользователей Приложения, Оператор руководствуется
      Законом Республики Казахстан «О персональных данных и их защите».
      <br />
      <br />
      <center>
        <div>
          <b>
            Статья 9. СОГЛАСИЕ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ НА ОБРАБОТКУ ЕГО ПЕРСОНАЛЬНЫХ ДАННЫХ{' '}
          </b>
        </div>
      </center>
      <br />
      1. Субъект персональных данных принимает решение о предоставлении его персональных данных и
      дает согласие на их обработку свободно, своей волей и в своем интересе. Согласие на обработку
      персональных данных может быть дано субъектом персональных данных или его представителем в
      любой позволяющей подтвердить факт его получения форме, если иное не установлено федеральным
      законом.
      <br />
      <br />
      <center>
        <div>
          <b>Статья 10. ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</b>
        </div>
      </center>
      <br />
      1. Субъект персональных данных имеет право на получение у Оператора информации, касающейся
      обработки его персональных данных, если такое право не ограничено в соответствии с нормами
      Закона №94. Субъект персональных данных вправе требовать от Оператора уточнения его
      персональных данных, их блокирования или уничтожения в случае, если персональные данные
      являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми
      для заявленной цели обработки, путем письменного обращения к Оператору, в том числе, и через
      электронные средства связи по адресам указанным в Приложении Оператора, а также принимать
      предусмотренные законом меры по защите своих прав. <br />
      2. Оператор обязан немедленно прекратить по требованию субъекта персональных данных обработку
      его персональных данных. <br />
      3. Запрещается принятие на основании исключительно автоматизированной обработки персональных
      данных решений, порождающих юридические последствия в отношении субъекта персональных данных
      или иным образом затрагивающих его права и законные интересы, за исключением случаев,
      предусмотренных нормами Закона №94, или при наличии согласия в письменной форме субъекта
      персональных данных. <br />
      4. Если субъект персональных данных считает, что Оператор осуществляет обработку его
      персональных данных с нарушением требований ФЗ-152 или иным образом нарушает его права и
      свободы, субъект персональных данных вправе обжаловать действия или бездействие Оператора в
      Уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.
      <br />
      <br />
      <center>
        <div>
          <b> Статья 11. ОБЯЗАТЕЛЬСТВА СТОРОН</b>
        </div>
      </center>
      <br />
      1. Пользователь обязан: <br />
      1.1. Предоставить информацию о персональных данных, необходимую для пользования Приложением.{' '}
      <br />
      1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения
      данной информации. <br />
      2. Оператор обязан: <br />
      2.1. Использовать полученную информацию исключительно для целей, указанных в настоящей
      Политики конфиденциальности. <br />
      2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без
      предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен,
      опубликование, либо разглашение иными возможными способами переданных персональных данных
      Пользователя. <br />
      2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных
      Пользователя согласно порядку, обычно используемого для защиты такого рода информации в
      существующем деловом обороте. <br />
      2.4. Осуществить блокирование персональных данных, относящихся к соответствующему
      Пользователю, с момента обращения или запроса Пользователя или его законного представителя
      либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в
      случае выявления недостоверных персональных данных или неправомерных действий.
      <br />
      <br />
      <center>
        <div>
          <b> Статья 12. ОТВЕТСТВЕННОСТЬ СТОРОН</b>
        </div>
      </center>
      <br />
      1. В случае утраты или разглашения Конфиденциальной информации Оператор не несёт
      ответственность, если данная конфиденциальная информация: <br />
      1.1. Стала публичным достоянием до её утраты или разглашения. <br />
      1.2. Была получена от третьей стороны до момента её получения Оператором. <br />
      1.3. Была разглашена с согласия Пользователя.
      <br />
      <br />
      <center>
        <div>
          <b> Статья 13. РАЗРЕШЕНИЕ СПОРОВ</b>
        </div>
      </center>
      <br />
      1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и
      Оператором, обязательным является предъявление претензии (письменного предложения о
      добровольном урегулировании спора). <br />
      2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно
      уведомляет заявителя претензии о результатах рассмотрения претензии. <br />
      3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в
      соответствии с действующим законодательством Республики Казахстан. <br />
      4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Оператором
      применяется действующее законодательство Республики Казахстан.
      <br />
      <br />
      <center>
        <div>
          <b> Статья 14. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</b>
        </div>
      </center>
      <br />
      1. Оператор вправе вносить изменения в настоящую Политику конфиденциальности без согласия
      Пользователя. <br />
      2. Новая Политика конфиденциальности вступает в силу с момента ее размещения в Приложении,
      если иное не предусмотрено новой редакцией Политики конфиденциальности. <br />
      3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать
      Оператору с указанием на раздел Приложения. <br />
      4. Действующая Политика конфиденциальности размещена на странице по адресу www. указать адрес.{' '}
      <br />
      5. Место нахождения оператора: Республика Казахстан, Павлодарская область, г. Павлодар, ул.
      Луначарского, 10, офис 8, тел. + 7 778 210 1339, электронная почта – 665090@gmail.com.
    </div>
  );
};

export default Policy;
