import React from 'react';
import s from './Publication.module.css';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';
import { useDispatch } from 'react-redux';
import { onItemGo, onScrollCatalog } from '../../../redux/action';

const Publication = ({ name, photopub, photoavatar, shopName, publdatetime, id, shopid }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const toItem = () => {
    dispatch(onItemGo(true));
    history.push(`/shopper/item?shopid=${shopid}&id=${id}`);
    dispatch(onScrollCatalog(window.pageYOffset));
  };

  return (
    <div className={s.item_bshop} key={uniqid()}>
      <div className={s.item__bshop_image}>
        <img
          src={
            photopub && photopub !== ''
              ? 'https://bshop.kz/beauty/PublicationPhotos/' + photopub
              : 'https://bshop.kz/beauty/AvatarPhotos/0.png'
          }
          alt="logo"
        />
      </div>

      <div className={s.item_avatar_image}>
        <img
          src={
            photoavatar && photoavatar !== ''
              ? 'https://bshop.kz/beauty/AvatarPhotos/' + photoavatar
              : 'https://bshop.kz/beauty/AvatarPhotos/0.png'
          }
          alt="logo"
        />
      </div>
      <div className={s.item__bshop_name}>{name}</div>
      <div className={s.item_publtext}>
        <p>Служба обратной связи<br/><br/><br/></p>
        <p>Телеграмм: @bfgmod<br/></p>
        <p>Whatsapp: +7 706 429 20 42<br/></p>
        <p>Почта: info.bshop.kz@gmail.com<br/></p>
      </div>

      </div>
  );
};

export default Publication;
