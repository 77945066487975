
import React, { useState } from 'react';
import s from './req.module.css';
import uniqid from 'uniqid';
import Popup from 'reactjs-popup';
import AcceptModulforClient from './AcceptModulforClient';
import ReviewModulforClient from './ReviewModulforClient';
const ServiceOfMaster = ({cat_id, id,  cat_name, masterId, serives_add_id_names, services_add_id, services_id, services_name, img}) => {

  const [dropDownReqAccept, setDropDownReqAccept] = useState(false);
  const options = { weekday: 'long',  month: 'long', day: 'numeric' };
//   const day = new Date(date);
//   const WSCSend2 = WSCSend

  let backgroundImage = 'linear-gradient(white, white), linear-gradient(to right, #edcd73, #ff015f)' ;
  let backgroundOrigin = 'border-box';
  let backgroundClip = 'padding-box, border-box';

//   const toItem = () => {
//     dispatch(onItemGo(true));
//     dispatch(onScrollCatalog(window.pageYOffset));
//   };
//   const status0 = () => {
//     let backgroundImage = 'linear-gradient(white, white), linear-gradient(to right, #4a291c, #ff015f)' ;
//     let backgroundOrigin = 'border-box';
//     let backgroundClip = 'padding-box, border-box';
//   }

//   const status1 = () => {
//     let backgroundImage = 'linear-gradient(white, #48e394), linear-gradient(to right, green, gold)' ;
//     let backgroundOrigin = 'border-box';
//     let backgroundClip = 'padding-box, border-box';
//   }

//   const status2 = () => {
//     let backgroundImage = 'linear-gradient(white, #fdb4b4), linear-gradient(to right, green, gold)' ;
//     let backgroundOrigin = 'border-box';
//     let backgroundClip = 'padding-box, border-box';
//   }

  
  

  const ReqAccept = () => {
    setDropDownReqAccept(!dropDownReqAccept);
  }
  return (
 <div style={{marginBottom: '20px'}}>
    {/* className={s.item_bshop} ссылается на тот же стиль, что стоит в профиле у клиента, поэтому менять осторожно, желательно отделить друг от друга */}
    <div className={s.item_bshop} key={uniqid()} 
    style={{borderRadius: '40px 5px 5px 40px'  , backgroundImage: `${backgroundImage}`, backgroundOrigin: `${backgroundOrigin}`, backgroundClip: `${backgroundClip}`}}>
          <div className={s.item_master_serv_image} onClick={() => ReqAccept()}>
              <img
                  src={img && img !== ''
                      ? 'https://bshop.kz/beauty/AvatarPhotos/' + img
                      : 'https://bshop.kz/beauty/AvatarPhotos/0.png'}
                  alt="logo" />
          </div>

          <div className={s.item_client_req_info}>
              <div className={s.item_client_req_info_name_id}>
                  <div className={s.item_master_req_info_Order}>{services_name}</div>
                  {/* <div className={s.item_client_req_info_time}>{time}</div> */}
                  {/* <div className={s.item_client_req_info_date}>{day.toLocaleDateString("ru-ru", options)}</div> */}
                  

                  
              </div>
              {/* <div className={s.item_client_req_info_orderName}></div> */}

                  <div className={s.item_master_req_info_serives_add_id_names}>{serives_add_id_names  || cat_name}</div>
                  <div className={s.item_master_req_info_cat_name}>{cat_name}</div>

              {/* <div className={s.item_client_req_info_comm      }>{comm}</div> */}
          </div>
      </div>

        </div>
  );
};

export default ServiceOfMaster;
