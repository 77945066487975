import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import LoadingBlock from '../../companents/bshop/itemCatalogBshop/oneItem/LoadingBlock';
import SignIn from '../../companents/SignIn/SignIn';
import { accountBeauty, onPBCmenu } from '../../redux/action';
import ProfileBeautyClient from './ProfileBeautyClient/ProfileBeautyClient';
import ProfileBeautyMaster from './ProfileBeautyMaster/ProfileBeautyMaster';

const Profile = () => {
  const dispatch = useDispatch();
  localStorage.setItem('FirstTimeUseAppCheck', 0);
  const [signIn, setSignIn] = useState(false);
  const login = JSON.parse(localStorage.getItem('account'));
  const accountBeautyInfo = useSelector((state) => state.accountBeauty);
  const [loading, setLoading] = useState(false);
  // console.log(loading)

  useEffect(() => {
    if (login) {
      const regdata = new FormData();
      dispatch(onPBCmenu('Мои записи'));
      regdata.append('phone', login.login);
      regdata.append('pass', login.pass);
      regdata.append('status', 0);
      fetch(`https://bshop.kz/beauty/beautyApi/profil/logIn.php`, {
        method: 'POST',
        body: regdata,
      })
        .then(async (res) => await res.json())
        .then((res) => {
          setSignIn(!signIn);
          dispatch(accountBeauty(res[0]));
          setLoading(true);
          // console.log(loading)
        });
    } else {
      setLoading(true);
    }
  }, []);

  if (accountBeautyInfo?.Email || login) {
    // статус 0 - клиент, 1 - мастер
    if (accountBeautyInfo?.status === '0') {
      return <ProfileBeautyClient />;
    } else if (accountBeautyInfo?.status === '1') {
      return <SignIn />;
    } else {
      return (
        <>
          <LoadingBlock />
          {accountBeautyInfo
            ? accountBeautyInfo.length == 0
              ? console.info()
              : console.info()
            : localStorage.removeItem('account')}
          {/* {localStorage.removeItem('account')} */}
        </>
      );
    }
  } else {
    return (
      <div>
        <SignIn />
      </div>
    );
  }
};

export default Profile;
