import React, { Component } from 'react';

import { Link } from 'react-router-dom'

import './style.css'

import Button from '../button'

import closeMenu from '../../asseets/close.png'
import gift from '../../asseets/gift.png'
import person from '../../asseets/person.png'
import list from '../../asseets/list.png'
import shop from '../../asseets/shop.png'
// import dost from '../../asseets/dost.png'

class ModalMenu extends Component {
    render() {

        const menuItems = [
            { value: 'Акции', url: 'gifts', image: gift },
            { value: 'Профиль', url: 'profile', image: person },
            { value: 'Регистрация/Авторизация', url: 'reg', image: person },
            // { value: 'Достижения', url: 'records', image: dost },
            { value: 'История', url: 'history', image: list },
            { value: 'Корзина', url: 'backet', image: shop },
        ]

        return (
            <div className='modal-menu' data-type='main' onClick={(e) => this.props.closeModalEvent(e.target.dataset.type)}>
                <div className='modalM'>
                    <div className='modal-wrapper'>

                        <div className='modal-contentM'>
                            <h3>BIKLI-FOOD</h3>

                            <div className='menu'>
                                {menuItems
                                    .filter(el => localStorage.getItem('access_token') ? el.value !== 'Регистрация/Авторизация' : el.value !== 'Профиль')
                                    .map((el, i) => {
                                        return (
                                            <Link to={el.url} className='menu-item' key={i} onClick={this.props.closeMenu}>
                                                <img src={`${el.image}`} alt='icon' />
                                                <p>{el.value}</p>
                                            </Link>
                                        )
                                    })}
                            </div>

                            {localStorage.getItem('access_token')
                                ?
                                <Link to='/main' className='menu-item-link' onClick={this.props.closeMenuWithDelLS}>
                                    <Button value='Выйти' class={'btn_close_modal'} />
                                </Link>
                                :
                                <Link to='/auth' className='menu-item-link' onClick={this.props.closeMenu}>
                                    <Button value='Войти' class={'btn_close_modal'} />
                                </Link>}

                        </div>

                        <img src={closeMenu} alt='close' className='close-menu' onClick={this.props.closeMenu} />

                    </div>
                </div>
            </div>
        );
    }
}

export default ModalMenu;