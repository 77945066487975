import React, { useState } from 'react';
import s from './item_bshop.module.css';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';
import { useDispatch } from 'react-redux';
import { onItemGo, onScrollCatalog } from '../../../redux/action';
import no_photo from './1643275201.jpeg';
const Item_bshop = ({ name, price, photo, id, shopid }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [photoItem, setPhotoItem] = useState(no_photo);

  const toItem = () => {
    dispatch(onItemGo(true));
    history.push(`/shopper/item?shopid=${shopid}&id=${id}`);
    dispatch(onScrollCatalog(window.pageYOffset));
  };

  const handlePhotoItem = () => {
    setPhotoItem('https://bshop.kz/photos/' + photo);
  };

  return (
    <div className={s.item_bshop} key={uniqid()}>
      <div className={s.item__bshop_image}>
        <div className={s.item__bshop_favorite}>
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.7663 2.2377C15.3753 1.84531 14.9111 1.53404 14.4002 1.32168C13.8893 1.10931 13.3417 1 12.7887 1C12.2357 1 11.6881 1.10931 11.1772 1.32168C10.6663 1.53404 10.2021 1.84531 9.81116 2.2377L8.9998 3.05166L8.18843 2.2377C7.39874 1.44548 6.32768 1.00041 5.21089 1.00041C4.09409 1.00041 3.02303 1.44548 2.23334 2.2377C1.44365 3.02993 1 4.10441 1 5.22479C1 6.34516 1.44365 7.41965 2.23334 8.21187L8.9998 15L15.7663 8.21187C16.1574 7.81967 16.4677 7.354 16.6794 6.84146C16.891 6.32893 17 5.77958 17 5.22479C17 4.67 16.891 4.12064 16.6794 3.60811C16.4677 3.09558 16.1574 2.6299 15.7663 2.2377Z"
              stroke="#404246"
              strokeOpacity="0.7"
              strokeWidth="0.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {photo && <img src={photoItem} onLoad={handlePhotoItem} alt="logo" />}

        <div className={s.item__bshop_geo}>
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.3333 7.54545C14.3333 12.6364 7.66667 17 7.66667 17C7.66667 17 1 12.6364 1 7.54545C1 5.80949 1.70238 4.14463 2.95262 2.91712C4.20286 1.68961 5.89856 1 7.66667 1C9.43478 1 11.1305 1.68961 12.3807 2.91712C13.631 4.14463 14.3333 5.80949 14.3333 7.54545Z"
              stroke="#404246"
              strokeOpacity="0.7"
              strokeWidth="0.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.66667 9.72727C8.89397 9.72727 9.88889 8.75044 9.88889 7.54545C9.88889 6.34047 8.89397 5.36364 7.66667 5.36364C6.43937 5.36364 5.44444 6.34047 5.44444 7.54545C5.44444 8.75044 6.43937 9.72727 7.66667 9.72727Z"
              stroke="#404246"
              strokeOpacity="0.7"
              strokeWidth="0.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div onClick={toItem}>
        <div className={s.item__bshop_name}>{name}</div>
        <div className={s.item__bshop_size_price}>
          <div className={s.sizes}></div>
          <div className={s.price}>{Number(price).toLocaleString('ru') + ' тг.'}</div>
        </div>
      </div>
    </div>
  );
};

export default Item_bshop;
