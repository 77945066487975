import React, { Component } from 'react';

import search from '../../asseets/searchWhite.png'

import './style.css'

class Button extends Component {
    render() {
        const val = this.props.value === 'search' ? <img src={search} alt='search' className='sort' /> : this.props.value;

        return <button className={this.props.class} onClick={this.props.click}>{val}</button>;
    }
}

export default Button;