import React from "react";
import ContentLoader from "react-content-loader"

const LoaderMain = () => (
    <ContentLoader
        speed={2}
        width={340}
        height={300}
        viewBox="0 0 340 300"
        backgroundColor="#f0f0f0"
        foregroundColor="#d6d6d6"
    >
        <circle cx="170" cy="100" r="100" />
        <rect x="0" y="235" rx="8" ry="8" width="190" height="25" />
        <rect x="0" y="280" rx="8" ry="8" width="80" height="20" />
        <rect x="90" y="280" rx="8" ry="8" width="55" height="20" />
        <rect x="270" y="280" rx="8" ry="8" width="70" height="20" />
    </ContentLoader>
)

export { LoaderMain }