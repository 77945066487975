import React, { useEffect, useState } from 'react';

import s from '../../companents/bshop/MainBshop.module.css';
import ItemsWrapper from '../../companents/bshop/itemsWrapper/ItemsWrapper';
import bshopApi from '../../api/bshopApi';
import { useDispatch } from 'react-redux';
import { onSearchItemBshop, onScrollCatalog } from '../../redux/action';
import uniqid from 'uniqid';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import ArrowUp from '../../companents/arrowUp/ArrowUp';
import LoadingBlock from '../../companents/bshop/itemCatalogBshop/oneItem/LoadingBlock';

const PageShop = () => {
  const dispatch = useDispatch();

  const [category, setCategory] = useState(null);

  const [shopInfo, setShopInfo] = useState('');
  const url = useLocation();
  const params = qs.parse(url.pathname.substring(9));
  const [activeCat, setActiveCat] = useState(null);
  const [arrowUp, setArrowUp] = useState(false);

  useEffect(() => {
    getShopInfo(params.shopid);
    getCategory(params.shopid);

    window.addEventListener('scroll', () => {
      window.pageYOffset > 400 ? setArrowUp(true) : setArrowUp(false);
    });
    window.scrollTo(0, 0);
  }, []);

  const getCategory = async (id) => {
    const responseItem = await bshopApi.fetchCategories(id);
    setCategory(responseItem);
  };

  const getShopInfo = async (id) => {
    const responseItem = await bshopApi.fetchShopInfo(id);
    setShopInfo(responseItem);
  };

  const onChangeCategoryShop = (value) => {
    setActiveCat(value);
    dispatch(
      onSearchItemBshop({
        value: '',
        end: 100,
        onShopId: shopInfo.id,
        onCategoryId: value,
      }),
    );
  };
  console.log();
  return (
    <div style={{ marginBottom: '70px' }}>
      {category ? (
        <>
          <div className={s.wrapHeader}>
            <div className={s.slide}>
              <div className={s.slide_previous}>
                {shopInfo.image !== '' ? (
                  <img src={`https://bshop.kz/image_logo/${shopInfo.image}`} alt="logo" />
                ) : (
                  <LoadingBlock />
                )}
              </div>
            </div>
            <div className={s.dataShop}>
              <div className={s.shopAddres}>{shopInfo && shopInfo.adres}</div>
              <div className={s.categoryesToShop}>
                {category?.map((item) => (
                  <div
                    data-id={item.id}
                    key={uniqid()}
                    className={activeCat === item.id ? s.categoryToShopActive : s.categoryToShop}
                    onClick={(e) => onChangeCategoryShop(e.target.dataset.id)}>
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={s.catalog_items}>
            {arrowUp && <ArrowUp />}
            <ItemsWrapper />
          </div>
        </>
      ) : (
        <LoadingBlock />
      )}
    </div>
  );
};

export default PageShop;
