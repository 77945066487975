import React, { useRef, useState } from 'react';
import '../../pages/beauty/style/Registration_form_style.css';
import Registration_text_field from '../../pages/beauty/Registration_text_field';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { accountBeauty } from '../../redux/action';
import { useHistory } from 'react-router-dom';
import Inputmask from 'inputmask';
import s from '../headerNew/header.module.css';
import { osName } from 'react-device-detect';
const SignIn = () => {
  const dispatch = useDispatch();
  const defCssSet = {
    display: 'block',
  };

  const defCssSetShadow = {
    boxShadow: 'none',
    display: 'inline-block',
    borderRadius: '5px',
    marginBottom: '10px',
  };

  const [PhoneCss, setPhoneCss] = useState(defCssSet);
  const [PasswordCss, setPasswordCss] = useState(defCssSet);
  const [PhoneCssShadow, setPhoneCssShadow] = useState(defCssSetShadow);
  const [PasswordCssShadow, setPasswordCssShadow] = useState(defCssSetShadow);

  const [Phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [PhoneError, setPhoneError] = useState('');
  const history = useHistory();
  const inputP = useRef();
  const [phoneInput, setphoneInput] = useState(false);
  const urlPath = localStorage.getItem('urlPath');
  urlPath === '/beauty/profile' ? console.info() : window.location.reload();
  inputP.current && Inputmask({ mask: '+7 (999)-999-99-99' }).mask(inputP.current);

  const iOSCssSetShadow = {
    boxShadow: '0px 0px 20px 6px rgb(224 114 243)',
    display: 'inline-block',
    borderRadius: '5px',
    marginBottom: '10px',
  };

  const iOSCssSet = {
    position: 'fixed',
    bottom: '250px',
    left: '0px',
    right: '0px',
  };

  const onPhoneInput = () => {
    inputP.current.value = '';
    setphoneInput(true);
    osName == 'iOS' ? setPhoneCss(iOSCssSet) : setPhoneCss(defCssSet);
    setPhoneCssShadow(iOSCssSetShadow);
  };

  const onPasswordInput = () => {
    osName == 'iOS' ? setPasswordCss(iOSCssSet) : setPasswordCss(defCssSet);
    setPasswordCssShadow(iOSCssSetShadow);
  };

  const onPhoneBlur = () => {
    setPhoneCss(defCssSet);
    setPhoneCssShadow(defCssSetShadow);
  };

  const onPasswordBlur = () => {
    setPasswordCss(defCssSet);
    setPasswordCssShadow(defCssSetShadow);
  };

  const wave_error = () => {
    if (Phone === '') {
      toast('Ошибка! Некорректно введенное поле Телефон', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    } else if (Boolean(PhoneError) === true) {
      toast('Ошибка! Некорректно введенное поле Телефон', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    } else if (password === '') {
      toast('Ошибка! Пустое поле пароль', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    }
  };

  const wave_success = () =>
    toast('Отлично! Вы успешно авторизовались!', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
    });
  const wave_error_sign_in = () =>
    toast('Ошибка входа! Вы ввели  неверные данные!', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });

  const passwordd = (event) => {
    setPassword(event.target.value);
  };

  const SendRegForm = async (e) => {
    e.preventDefault();
    console.log(inputP.current.inputmask.undoValue.length);

    if (inputP.current.inputmask.undoValue.length === 18) {
      const regdata = new FormData();

      // regdata.append('phone', Phone);
      regdata.append('pass', password);
      regdata.append('phone', inputP.current.inputmask.undoValue);
      regdata.append('status', 0);

      let phone = inputP.current.inputmask.undoValue;
      let response = await fetch(`https://bshop.kz/beauty/beautyApi/profil/logIn.php`, {
        method: 'POST',
        body: regdata,
      });

      let commits = await response.json(); // читаем ответ в формате JSON
      if (commits) {
        if (commits[0].status == '1') {
          wave_error_sign_in();
        } else {
          // wave_success();
          dispatch(accountBeauty(commits[0]));
          localStorage.setItem('account', JSON.stringify({ login: phone, pass: password }));
          localStorage.setItem('avatar', commits[0].img);
          window.location.reload();
        }
      } else {
        wave_error_sign_in();
      }
    } else {
      wave_error();
    }
  };
  const goToReg = () => {
    history.push('/beauty/registration');
  };
  return (
    <div className='reg_body'>
      <div className='reg_wrapper'>
        <h1 className='reg_title'>Авторизация</h1>

        <div className='registration_form'>
          <div style={PhoneCss} className='inputGroup'>
            <div style={PhoneCssShadow}>
              <input
                ref={inputP}
                className='reg_placeholder_without_margin'
                type='text'
                placeholder='Телефон'
                onClick={() => {
                  onPhoneInput();
                }}
                onBlur={onPhoneBlur}
              />
            </div>
          </div>

          <div
            style={PasswordCss}
            onClick={() => {
              onPasswordInput();
            }}
            className='inputGroup'
          >
            <div style={PasswordCssShadow}>
              <Registration_text_field
                name='password'
                onChange={(e) => passwordd(e)}
                error='none'
                placeholder='Пароль'
                className='reg_placeholder_without_margin'
                onBlur={onPasswordBlur}
              />
            </div>
          </div>

          <div className='inputGroup'>
            <button
              onClick={(e) => SendRegForm(e)}
              className='reg_btn'
              style={{ marginBottom: 20 }}
            >
              Войти
            </button>

            <button
              onClick={() => history.push('/beauty/forgetpassword')}
              className='forgetpassword'
            >
              Забыли пароль?
            </button>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ToastContainer position='top-center' theme='dark' limit={4} />
            </div>
            <button
              className='reg_abs'
              style={{ textAlign: 'center', marginBottom: 150 }}
              onClick={() => goToReg()}
            >
              Регистрация
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
