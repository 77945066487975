import { React, memo } from 'react';
import s from './slider_catalog.module.css';
import OneSlide from './OneSlide';
import picSlide from './Rectangle 48.png';
import { Carousel } from 'react-responsive-carousel';
import uniqid from 'uniqid';

const Slider_catalog = () => {
  const slides = [
    <OneSlide picSlide={picSlide} content={'Новинки весна-лето'} />,
    <OneSlide picSlide={picSlide} content={'Новинки весна-лето'} />,
    <OneSlide picSlide={picSlide} content={'Новинки весна-лето'} />,
    <OneSlide picSlide={picSlide} content={'Новинки весна-лето'} />,
    <OneSlide picSlide={picSlide} content={'Новинки весна-лето'} />,
  ];
  return (
    <div className={s.root}>
      <div className={s.wrapper_slider}>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay={true}
          interval={4000}
          infiniteLoop={true}>
          {slides?.map((item, i) => (
            <div key={uniqid()}>{item}</div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default memo(Slider_catalog);
