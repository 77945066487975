import React from 'react';
import s from './slider_catalog.module.css';

const oneSlide = ({ picSlide, content }) => {
  return (
    <div className={s.slide}>
      <div className={s.slide_previous}>
        <img src={picSlide} alt="picSlide" />
      </div>
      <div className={s.slide_content}>{content}</div>
      <div className={s.side_link_shop}>
        <span>В магазин</span>
        <svg
          width="73"
          height="8"
          viewBox="0 0 73 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M72.3536 4.35355C72.5488 4.15828 72.5488 3.8417 72.3536 3.64644L69.1716 0.46446C68.9763 0.269198 68.6597 0.269198 68.4645 0.46446C68.2692 0.659722 68.2692 0.976305 68.4645 1.17157L71.2929 3.99999L68.4645 6.82842C68.2692 7.02368 68.2692 7.34027 68.4645 7.53553C68.6597 7.73079 68.9763 7.73079 69.1716 7.53553L72.3536 4.35355ZM4.37114e-08 4.5L72 4.49999L72 3.49999L-4.37114e-08 3.5L4.37114e-08 4.5Z"
            fill="#FF7A00"
          />
        </svg>
      </div>
    </div>
  );
};

export default oneSlide;
