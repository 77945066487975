import React, { Component } from 'react';
import Button from '../../../companents/button';

import './style.css'

class FormAddPartner extends Component {
    render() {

        const form = [
            { title: 'Название', type: 'text', placeholder: 'Введите название', name: 'name' },
            { title: 'Ваша отрасль', type: 'option', placeholder: 'Выберите вашу отрасль', name: 'otrasl' },
            { title: 'Ваш email', type: 'text', placeholder: 'email@mail.com', name: 'email' },
            { title: 'Контактный телефон', type: 'text', placeholder: 'Введите ваш телефон', name: 'phone' },
            { title: 'ФИО', type: 'text', placeholder: 'ФИО', name: 'fio' },
            { title: 'Должность', type: 'text', placeholder: 'Должность', name: 'dolzhnost' },
            { title: 'Среднее время доставки', type: 'text', placeholder: '60 мин', name: 'time_delivery' },
            { title: 'Цена доставки', type: 'text', placeholder: '300 тг', name: 'price_delivery' },
            { title: 'Минимальная сумма заказа (если есть)', type: 'text', placeholder: '2000 тг', name: 'min_price_delivery' },
            { title: 'Сумма после которой доствка становится бесплатной', type: 'text', placeholder: '4000 тг', name: 'free_delivery' },
            { title: 'Время начала приема заказов', type: 'text', placeholder: 'Время начала приема заказов', name: 'time_start_delivery' },
            { title: 'Время конца приема заказов', type: 'text', placeholder: 'Время конца приема заказов', name: 'time_end_delivery' },
            { title: 'Город', type: 'text', placeholder: 'Город', name: 'city' },
            { title: 'Телефон для приема заказов', type: 'text', placeholder: 'Телефон для приема заказов', name: 'phone_delivery' },
            { title: 'Описание', type: 'text', placeholder: 'Необходимо добавить описание вашего заведения', name: 'opis' },
            { title: 'Загрузка логотипа', type: 'file', placeholder: '', name: 'logo' },
            { title: 'Лицензионное соглашение (нажмите для просмотра)', type: 'checkbox', placeholder: 'Я согласен(на)', name: 'license' },
        ]

        const otrasl = [
            { title: 'Рестораны/кафе', value: 'otrasl_cafe' },
            { title: 'Цветочная лавка', value: 'otrasl_flower' }
        ]

        return (
            <div className='formAddPartner'>
                <div className='formAddPartner-wrapper'>

                    <Button value='Назад' class='btn-prev-formPartner' click={this.props.toggleFormPartner} />

                    <form onSubmit={e => e.preventDefault()} className='form-addPartner'>

                        {form.map((el, i) => {
                            return (
                                <div className='form-text-addPartner' key={i}>
                                    <p>{el.title}</p>
                                    {el.type === 'text' ? <input type={el.type} name={el.name} placeholder={el.placeholder} autoComplete='off' /> : null}
                                    {el.type === 'option'
                                        ? <select id="select">
                                            <option className='option-dont' disabled="disabled" selected="selected">Выберите отрасль</option>
                                            {otrasl.map((el, i) => <option key={i} name={el.value}>{el.title}</option>)}
                                        </select> : null}
                                    {el.type === 'checkbox'
                                        ? <div>
                                            <input type={el.type} name={el.name} />
                                            <span>{el.placeholder}</span>
                                        </div> : null}
                                    {el.type === 'file' ?
                                        <div className='load-file'>
                                            <input type={el.type} name={el.name} id="input_load_file" />
                                            <label for="input_load_file" >
                                                <p className="load-file-button">Выбрать</p>
                                                <p className="load-file-fake">Файл не вбран</p>
                                            </label>
                                        </div>
                                        : null}
                                </div>
                            )
                        })}

                    </form>

                    <Button value='Отправить форму' class='btn-formPartner' />

                </div>
            </div>
        );
    }
}

export default FormAddPartner;