import React from 'react';
import { useHistory } from 'react-router-dom';
import './style/Registration_form_style.css';

const TypeRegistration = () => {
  const history = useHistory();
  const regMaster = () => {
    history.push('registration/beautymaster');
  };
  const regClient = () => {
    history.push('/beauty/registration/client');
  };

  return (
    <div className="reg_body">
      <div className="reg_wrapper">
        <h1 className="reg_title">Контакты</h1>

        <div className="registration_form">
          <div className="inputGroup">
            <div>
              {/* Внимание! <br /> */}
              Для получения дополнительной информации и ответов на возникшие вопросы Вы всегда можете позвонить или написать на WhatsApp по номеру
              <br />
              <a href="tel:+77064292042">+7-(706)-429-20-42</a>
            </div>
            <button className="reg_btn" onClick={() => regMaster()}>
              Я Мастер
            </button>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}></div>
            <button
              className="reg_btn"
              style={{ textAlign: 'center', marginBottom: 50, marginTop: 20 }}
              onClick={() => regClient()}>
              Я Клиент
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeRegistration;
