import React from 'react';
import s from './slider_catalog.module.css';

const oneSlide = ({ picSlide, content }) => {
  return (
    <div className={s.slide}>
      <div className={s.slide_previous}>
        <img src={picSlide} alt="picSlide" />
      </div>
    </div>
  );
};

export default oneSlide;
