import React, { useRef, useState, useEffect } from 'react';
import '../../pages/beauty/style/Registration_form_style.css';
import Registration_text_field from '../../pages/beauty/Registration_text_field';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Inputmask from 'inputmask';
import star from '../../../src/asseets/star.png';
const Registration_form = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputP = useRef();
  // const Dalee = useRef();

  const [UserName, setName] = useState('');
  const [salon, setSalon] = useState('');
  const [adress, setAdress] = useState('');
  const [Phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [phoneInput, setphoneInput] = useState(false);
  const [passwordInp, setPasswordInp] = useState('');
  const [agreePasswordInp, setAgreePasswordInp] = useState('');
  const [regCheckbox, setRegCheckbox] = useState(false);

  // NEW
  const [verificationCode, setVerificationCode] = useState('');
  const [serverVerificationCode, setServerVerificationCode] = useState('-');
  const [tab, setTab] = useState(0);
  const [cities, setCities] = useState([]);
  const [city_id, setCityId] = useState(0);

  useEffect(() => {
    getCityList();
  }, []);

  // ************************

  const urlPath = localStorage.getItem('urlPath');
  const emregex = /\S+@\S+\.\S+/;

  inputP.current && Inputmask({ mask: '+7 (999)-999-99-99' }).mask(inputP.current);

  const historyPush = () => {
    history.push('/beauty/profile');
  };

  const onPhoneInput = () => {
    // inputP.current.value = '';
    setphoneInput(true);
  };

  const wave_error_phone = () => {
    toast('Ошибка! Некорректно введенное поле Телефон', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };
  const wave_error_name = () => {
    toast('Ошибка! Некорректно введенное поле Имя', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_success = () =>
    toast(
      'Отлично! Вы успешно зарегистрировались! Для входа используйте введенный номер телефона и введенный пароль',
      {
        backgroundColor: '#10df3d',
        color: '#ffffff',
      },
    );
  const wave_yandex = () =>
    toast(
      'Похоже, что вы используете почту Яндекс, поэтому вам может прийти "пустое" сообщение. Для получения скрытой информации перейдите в свойства письма.',
      {
        backgroundColor: '#10df3d',
        color: '#ffffff',
      },
    );

  const wave_repeat_not_success = () =>
    toast('Ошибка! Клиент уже зарегистрирован!', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  const undef_error = () =>
    toast('Возникла ошибка на стороне сервера. Попробуйте снова', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  // NEW **************
  const verifyCode_error = () =>
    toast('Неверно введен код', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  // **************************

  const no_agree_pass = () =>
    toast('Пароли не совпадают', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });

  const terms_error = () =>
    toast('Вы не согласились с пользовательским соглашением', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });

  // **************************

  const wave_call = () =>
    toast(
      'В течении нескольких минут Вам позвонит робот, отвечать на звонок не нужно! Запомните последние 4 цифры и введите их в поле для кода.',
      {
        backgroundColor: '#10df3d',
        color: '#ffffff',
        autoClose: false,
      },
    );

  const getCityList = async () => {
    let cities = await fetch('https://beauty.bikli.kz/beautyApi/getListOfCities.php');
    cities = await cities.json();
    setCities(cities);
  };

  const formatPhone = (number) => {
    number = number.replaceAll('-', '');
    number = number.replaceAll(' ', '');
    number = number.replaceAll('(', '');
    number = number.replaceAll(')', '');
    number = number.replace('+7', '7');
    return number;
  };

  const FlashhCallError = () => {
    undef_error();
    setTimeout(PageReload, 1500);
  };

  const PageReload = () => {
    window.location.reload();
  };

  const FlashhCallSucces = (code) => {
    setServerVerificationCode(code);
    wave_call();
  };

  const PhoneCode = async () => {
    if (phoneInput == true) {
      if (inputP.current.inputmask.undoValue !== undefined) {
        if (inputP.current.inputmask.undoValue[17] !== '_') {
          if (inputP.current.inputmask.undoValue !== '') {
            if (UserName !== '') {
              setTab(1);
              const phonedata = new FormData();

              phonedata.append('phone', Phone);

              fetch(`https://beauty.bikli.kz/beautyApi/flashcall.php`, {
                method: 'POST',
                body: phonedata,
              })
                .then((response) => response.json())
                .then((res) => (res == 400 ? FlashhCallError() : FlashhCallSucces(res.code)));
              // .then((res) => setServerVerificationCode(res.code));
              inputP.current.setAttribute('disabled', 'disabled');
              // Dalee.current.setAttribute('disabled', 'true');
            } else {
              wave_error_name();
            }
          } else {
            wave_error_phone();
          }
        } else {
          wave_error_phone();
        }
      } else {
        wave_error_phone();
      }
    } else {
      wave_error_phone();
    }
  };

  const SendRegForm = async (e) => {
    if (passwordInp === agreePasswordInp) {
      if (phoneInput == true) {
        if (inputP.current.inputmask.undoValue !== undefined) {
          if (inputP.current.inputmask.undoValue[17] !== '_') {
            if (inputP.current.inputmask.undoValue !== '') {
              if (UserName !== '') {
                const regdata = new FormData();

                regdata.append('FIO', UserName);
                regdata.append('SalonName', salon);
                regdata.append('Adress', adress);
                regdata.append('Phone', Phone);
                regdata.append('status', 1);
                regdata.append('password', passwordInp);
                regdata.append('city_id', city_id);

                let response = await fetch(
                  `https://bshop.kz/beauty/beautyApi/BRegistration_form3.php`,
                  {
                    method: 'POST',
                    body: regdata,
                  },
                );
                let commits = await response.json(); // читаем ответ в формате JSON
                const PHPresponse = commits[0].response;
                setTimeout(historyPush, 5000);
                PHPresponse == 'Успешно!'
                  ? wave_success()
                  : PHPresponse == 'Клиент уже зарегистрирован!'
                  ? wave_repeat_not_success()
                  : PHPresponse == 'Ошибка отправки письма'
                  ? wave_success()
                  : undef_error();

                if (email.indexOf('yandex') + 1) {
                  wave_yandex();
                }
              } else {
                wave_error_name();
              }
            } else {
              wave_error_phone();
            }
          } else {
            wave_error_phone();
          }
        } else {
          wave_error_phone();
        }
      } else {
        wave_error_phone();
      }
    } else {
      no_agree_pass();
    }
  };

  return (
    <>
      <div className='reg_body'>
        <div style={{ marginTop: '20px' }} className='reg_wrapper'>
          <h1 className='reg_title' style={{ marginTop: '90px' }}>
            Регистрация
          </h1>
          <div style={{ textAlign: 'center' }} className='registration_form'>
            <>
              <div
                style={{
                  width: 'fit-content',
                  margin: 'auto',
                  textAlign: 'left',
                }}
              >
                <p style={{ display: 'inline' }}>Имя</p>{' '}
                <img style={{ marginBottom: '8px' }} src={star}></img>
                <Registration_text_field
                  name='password'
                  onChange={(e) => setName(e.target.value)}
                  error='none'
                  // placeholder="Пароль"
                  className='reg_placeholder'
                />
                <p style={{ display: 'inline' }}>Название салона</p>
                <Registration_text_field
                  name='password'
                  onChange={(e) => setSalon(e.target.value)}
                  error='none'
                  // placeholder="Пароль"
                  className='reg_placeholder'
                />
                <p style={{ display: 'inline' }}>Адрес оказания услуги</p>{' '}
                <img style={{ marginBottom: '8px' }} src={star}></img>
                <Registration_text_field
                  name='password'
                  onChange={(e) => setAdress(e.target.value)}
                  error='none'
                  // placeholder="Пароль"
                  className='reg_placeholder'
                />
                <p style={{ display: 'inline' }}>Телефон</p>{' '}
                <img style={{ marginBottom: '8px' }} src={star}></img>
                <div className='inputGroup'>
                  <input
                    ref={inputP}
                    className='reg_placeholder'
                    type='text'
                    onChange={(e) => setPhone(e.target.value)}
                    onClick={() => {
                      onPhoneInput();
                    }}
                  />
                </div>
                <p style={{ display: 'inline' }}>Город</p>{' '}
                <img style={{ marginBottom: '8px' }} src={star}></img>
                <div className='inputGroup'>
                  <select
                    className='reg_placeholder'
                    type='text'
                    onChange={(e) => setCityId(e.target.value)}
                  >
                    <option value='0'>Не указано</option>
                    {cities.map((item) => (
                      <option key={item.city_id} value={item.city_id}>
                        {item.city}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={{ display: tab == 0 ? 'block' : 'none' }} className='inputGroup'>
                <button onClick={PhoneCode} className='reg_btn' style={{ marginBottom: 20 }}>
                  Получить код
                </button>
                <p>
                  Мы позвоним на указанный номер, вашим кодом будут последние 4 цифры звонившего
                  номера
                </p>
              </div>
              <div style={{ display: tab == 1 ? 'block' : 'none' }}>
                <p style={{ display: 'inline' }}>Введите последние 4 цифры номера</p>
                <div
                  style={{
                    width: 'fit-content',
                    margin: 'auto',
                    textAlign: 'left',
                  }}
                >
                  <Registration_text_field
                    name='passwordCode'
                    onChange={(e) => setVerificationCode(e.target.value)}
                    error='none'
                    placeholder='Код'
                    className='reg_placeholder'
                    // label={"Код"}
                  />

                  <p style={{ display: 'inline' }}>Введите новый пароль</p>
                  <Registration_text_field
                    name='password'
                    onChange={(e) => setPasswordInp(e.target.value)}
                    error='none'
                    placeholder='Пароль'
                    className='reg_placeholder2'
                    // label={"Код"}
                  />
                  <p style={{ display: 'inline' }}>Повторите пароль</p>
                  <Registration_text_field
                    name='password'
                    onChange={(e) => setAgreePasswordInp(e.target.value)}
                    error='none'
                    placeholder='Повторите пароль'
                    className='reg_placeholder2'
                    // label={"Код"}
                  />
                </div>
                <div
                  className='terms'
                  style={{
                    width: 'fit-content',
                  }}
                >
                  <h1>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>
                  <p>
                    Настоящее Пользовательское соглашение заключается между Компанией –
                    Товариществом с ограниченной ответственностью «ТЕХНОSTYLE», расположенной в сети
                    Интернет по адресу http://bshop.kz (далее - Компания), и пользователем услуг
                    (далее - Пользователь), и регламентирует условия их взаимодействия.
                  </p>
                  <h1>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h1>
                  <p>
                    В настоящем Пользовательском соглашении нижеприведенные термины и определения
                    используются в следующих значениях:
                    <br />
                    1.1. Пользовательское соглашение (именуемое в дальнейшем Соглашение)
                    представляет собой условия по использованию Программного обеспечения BSHOP
                    (далее – Программное обеспечение).
                    <br />
                    1.2. Программное обеспечение BSHOP - Система управления Вашего магазина. Система
                    управления Вашего магазина.
                    <br />
                    1.3. Компания – Товарищество с ограниченной ответственностью «ТЕХНОSTYLE»,
                    (местонахождение: Республика Казахстан, Павлодарская область, г. Павлодар, ул.
                    Луначарского, 8), уполномоченные сотрудники, действующие от имени Товарищества с
                    ограниченной ответственностью «ТЕХНОSTYLE».
                    <br />
                    1.4. Пользователь Программного обеспечения (далее - Пользователь) – физическое
                    лицо, индивидуальный предприниматель или юридическое лицо, обратившееся к
                    Компании с целью приобретения прав на использование Программного обеспечения.
                    Пользователем Программного обеспечения считается любое юридическое или
                    физическое лицо, когда-либо осуществившее доступ к Программному обеспечению,
                    имеющее права принятия настоящего Соглашения.
                    <br />
                    1.5. Стороны – с одной стороны - Компания, с другой стороны – Пользователь
                    (Представитель Пользователя).
                    <br />
                    1.6. Договор – сделка, заключаемая между Пользователем и Компанией, в
                    соответствии с условиями которой Компания предоставляет Пользователю (иному лицу
                    – Представителю Пользователя, действующего в интересах Пользователя) на условиях
                    простой (неисключительной) лицензии права пользования Программным обеспечением.
                    <br />
                    1.7. Лицензия – совокупность прав на использование Программного обеспечения,
                    предоставленных Пользователю, определяемая сроком и допустимыми объемами
                    использования Программного продукта.
                    <br />
                    1.8. Сайт – сайт в сети Интернет, размещенный по адресу http://bshop.kz,
                    администратором которого является Компания
                  </p>
                  <h1>2. ПРЕДМЕТ СОГЛАШЕНИЯ</h1>
                  <p>
                    2.1. Предметом настоящего Соглашения является предоставление Компанией
                    Пользователю на условиях простой (неисключительной) лицензии прав пользования
                    Программным обеспечением.
                  </p>
                  <h1>3. ОБЩИЕ ПОЛОЖЕНИЯ</h1>
                  <p>
                    3.1. Правообладателем Программного обеспечения в полном объеме является
                    Компания.
                    <br />
                    3.2. Программное обеспечение является объектом права интеллектуальной
                    собственности Компании. Все исключительные имущественные авторские права на
                    Программное обеспечение принадлежат Компании. Использование Программного
                    обеспечения Пользователем возможно строго в рамках Соглашения и действующего
                    законодательства Республики Казахстан об авторском праве и интеллектуальной
                    собственности.
                    <br />
                    3.3. Компания вправе на условиях, установленных в настоящем Соглашении,
                    предоставлять Пользователю права пользования Программным обеспечением.
                    Предоставление Компанией прав пользования Программным обеспечением
                    осуществляется при условии полного и безоговорочного акцепта условий настоящего
                    Соглашения. Частичный акцепт, а равно акцепт на иных условиях, не допускается.
                    <br />
                    3.4. Использование Пользователем Программного обеспечения свидетельствует о
                    заключении между Пользователем и Компанией Договора.
                    <br />
                    3.5. Акцепт условий настоящего Соглашения совершается Пользователем либо путем
                    регистрации на Сайте.
                    <br />
                    3.6. В случае несогласия с условиями Соглашения, использование Сайта
                    Пользователем должно быть немедленно прекращено.
                    <br />
                    3.7. Пользователь подтверждает, что до совершения им действий по акцепту
                    настоящего Соглашения, он ознакомился с условиями настоящего Соглашения, и они
                    ему полностью понятны.
                    <br />
                    3.8. В случае осуществления действий, третьим лицом в интересах Пользователя,
                    Стороны исходят из безусловной правомочности такого лица, а также из того, что
                    третье лицо действует с ведома и согласия Пользователя. Компания не несет
                    ответственности за действия (бездействия) третьих лиц, действующих в интересах
                    Пользователя и за последствия этих действий (бездействий) для Пользователя.
                    <br />
                    3.9. Иные порядок и условия использования Пользователем Программного
                    обеспечения, отличные от условий настоящего Соглашения, могут устанавливаться
                    положениями дополнительных сделок (договоров, соглашений), заключаемых
                    Пользователем и Компанией.
                    <br />
                    3.10. Настоящее Соглашение может быть изменено и/или дополнено Компанией в
                    одностороннем порядке без какого-либо специального уведомления.
                  </p>
                  <h1>4. ПОРЯДОК И УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ</h1>
                  <p>
                    4.1. Компания предоставляет, а Пользователь обязуется принять предоставляемые
                    права, осуществлять их в установленных пределах и выплатить Компании
                    лицензионное вознаграждение.
                    <br />
                    4.2. Компания позволяет Пользователю просматривать и загружать информацию
                    исключительно для личного некоммерческого использования. Запрещается изменять
                    материалы сайта, распространять для общественных или коммерческих целей. Любое
                    использование информации на других сайтах или в компьютерных сетях запрещается.
                    <br />
                    4.3. Пользователь соглашается ни воспроизводить, ни повторять, не копировать,
                    какие-либо части Программного обеспечения.
                    <br />
                    4.4. Для осуществления регистрации Пользователь соглашается предоставить
                    достоверную и точную информацию о себе и своих контактных данных, при этом
                    Пользователь заполняет регистрационную форму, расположенную на Сайте.
                    <br />
                    4.5. В процессе регистрации на сайте, Пользователь получает логин и пароль, за
                    безопасность которых самостоятельно несет ответственность.
                    <br />
                    4.6. Пользователь может обращаться к Компании с вопросами, претензиями,
                    пожеланиями по улучшению работы, либо с какой-либо иной информацией. При этом
                    Пользователь несет ответственность, что данное обращение не является незаконным,
                    угрожающим, нарушающим авторские права, дискриминацию людей по какому-либо
                    признаку, а также содержащим оскорбления либо иным образом нарушающим
                    действующее законодательство.
                    <br />
                    4.7. Пользователь, правомерно владеющий экземпляром Программного обеспечения,
                    вправе использовать его в соответствии с его назначением в пределах срока и
                    объемов, указанных в соответствующем счете и акте о предоставленном праве.
                    <br />
                    4.8. Пользователь не вправе использовать Программное обеспечение (осуществлять
                    права в отношении Программного обеспечения) способами, не установленными в
                    настоящем Соглашении.
                    <br />
                    4.9. Права пользования являются предоставленными Пользователю с момента
                    предоставления возможности использования Программного обеспечения посредством
                    авторизации данных – логина и пароля.
                    <br />
                    4.10. Основанием для предоставления Пользователю прав пользования Программным
                    обеспечением является поступление на расчетный счет Компании лицензионного
                    вознаграждения.
                    <br />
                    4.11. Прекращение лицензии (приостановка лицензии) являются основанием для
                    закрытия Пользователю доступа к Программному обеспечению.
                    <br />
                    4.12. В течение срока действия лицензии Пользователь вправе увеличивать объем
                    предоставляемых ему прав на использование Программного обеспечения и срок
                    действия Лицензии. Такие изменения являются основанием для увеличения
                    лицензионного вознаграждения, которые отражаются в акте.
                    <br />
                    4.13. Компания вправе менять состав Программного обеспечения (элементов
                    Программного обеспечения – программных продуктов), изменять интерфейс, порядок
                    доступа к Программному обеспечению, основные функциональные возможности
                    Программного обеспечения. <br />
                    4.14. Информация о внесенных изменениях и особенные условия использования
                    Программного обеспечения доводятся до сведения Пользователя посредством
                    размещения на Сайте.
                    <br />
                    4.15. Компания вправе предоставлять Пользователю тестовый период, в течение
                    которого Пользователь вправе использовать Программное обеспечение без выплаты
                    Компании лицензионного вознаграждения, но в пределах правомочий, определённых
                    настоящим Соглашением, информация о сроке действия тестового периода,
                    размещается Компанией на Сайте, либо предоставляется Пользователю в электронной
                    форме. Право тестового периода использования Программного обеспечения
                    предоставляется однократно конкретному Пользователю.
                    <br />
                    4.16. В случае злоупотребления Пользователем правом на использование
                    Программного обеспечения в течение тестового периода, Компания вправе прекратить
                    доступ Пользователя к Программному обеспечению.
                    <br />
                    4.17. Компания самостоятельно и в одностороннем порядке определяет порядок и
                    условия предоставления Пользователю прав на использование тестового периода, а
                    также вправе в любое время их менять
                  </p>
                  <h1>5. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h1>
                  <p>
                    Компания обязуется:
                    <br />
                    5.1. При условии надлежащего выполнения Пользователем обязательств по уплате
                    лицензионного вознаграждения, предоставить Пользователю права и возможность
                    использования Программного обеспечения в согласованном Сторонами объеме.
                    <br />
                    5.2. В случае, если предоставляемая Пользователем информация является
                    конфиденциальной - обеспечивать должный режим защиты такой информации, не
                    допуская ее разглашения третьим лицам за исключением случаев публично-правовой
                    обязанности раскрыть такую информацию по запросу уполномоченного органа.
                    Компания имеет право:
                    <br />
                    5.3. Изменять правила пользования Программным обеспечением, а также изменять
                    содержание данного Программного обеспечения. Изменения вступают в силу с момента
                    публикации новой редакции Соглашения на Сайте.
                    <br />
                    5.4. В любой момент, без предварительного уведомления, проверять порядок
                    использования Программного обеспечения и идентичность используемой Пользователем
                    версии Программного обеспечения.
                    <br />
                    5.5. Приостанавливать доступ Пользователя к Программному обеспечению в случае
                    проведения технических работ в отношении Программного обеспечения.
                    <br />
                    5.6. Ограничивать и (или) прекращать доступ Пользователя к Программному
                    обеспечению в случае нарушения Пользователем условий настоящего Соглашения. При
                    этом, приостановка (прекращение) доступа к Программному обеспечению в указанных
                    случаях не является нарушением обязательств Компании и основанием для возмещения
                    Пользователю каких бы то ни было убытков, если таковые возникли.
                    <br />
                    5.7. Отказаться от исполнения Договора в случае допущения Пользователем
                    какого-либо нарушения настоящего Соглашения, а также есть основания полагать,
                    что Пользователь использует Программное обеспечение для противоправных,
                    незаконных целей.
                    <br />
                    5.8. Предоставлять Пользователю тестовый период пользования Программным
                    обеспечением. 5.9. Предоставлять консультации по использованию Программного
                    обеспечения с целью повышению уровня и качества обслуживания посетителей.
                    <br />
                    Пользователь обязуется:
                    <br />
                    5.10. Осуществить регистрацию на Сайте (при необходимости сотрудниками Компании
                    может быть оказана удаленная консультационная помощь по заполнению
                    регистрационной формы).
                    <br />
                    5.11. Определить и согласовать с Компанией предпочтительный объем Лицензии и
                    срок ее действия.
                    <br />
                    5.12. Предоставить Компания действительную, точную и достоверную информацию о
                    себе, необходимую для целей исполнения настоящего Договора, а также,
                    предоставлять по запросу Компании дополнительную информацию, которая имеет
                    непосредственное отношение к предоставляемым услугам данного Программного
                    обеспечения.
                    <br />
                    5.13. Своевременно выплачивать Компании лицензионное вознаграждение.
                    <br />
                    5.14. Использовать Программное обеспечение только способами, установленными
                    настоящим Соглашением.
                    <br />
                    5.15. Устанавливать Программное обеспечение только на аппаратное обеспечение,
                    соответствующее требованиям, установленным Компанией.
                    <br />
                    5.16. Не создавать на основе исходного кода Программного обеспечения новые
                    программные продукты, модифицировать Программное обеспечение, осуществлять его
                    переработку; не вносить изменения в код, являющийся компонентом Программного
                    обеспечения
                    <br />
                    5.17. Без предварительного согласия Компании не регистрировать более одной
                    учетной записи в учетной системе Компании (неоднократно проходить регистрацию на
                    Сайте).
                    <br />
                    5.18. Не использовать Программное обеспечение для противозаконных действий и
                    направлений деятельности.
                    <br />
                    5.19. Не передавать третьим лицам идентификационные данные, необходимые для
                    доступа к Панели управления и (или) к Программному обеспечению.
                    <br />
                    5.20. Соблюдать имущественные и неимущественные права Компании при использовании
                    Программного обеспечения.
                    <br />
                    5.21. Не использовать Сайт для распространения информации рекламного характера,
                    иначе как с согласия Компании. Пользователь имеет право:
                    <br />
                    5.22. Получить доступ к использованию Программного обеспечения после соблюдения
                    требований о регистрации и оплате.
                    <br />
                    5.23. Пользоваться всеми имеющимися на Сайте услугами.
                    <br />
                    5.24. Обращаться к Компании с предложениями и вопросами, относящимися к работе с
                    Программным обеспечением по контактам, размещенным на Сайте.
                    <br />
                    5.25. Пользоваться Сайтом исключительно в целях и порядке, предусмотренным
                    Соглашением.
                  </p>
                  <h1>6. ВОЗНАГРАЖДЕНИЕ КОМПАНИИ. ПОРЯДОК РАСЧЕТОВ</h1>
                  <p>
                    6.1. Размер лицензионного вознаграждения за предоставляемые права пользования
                    Программным обеспечением устанавливается Компанией самостоятельно и в
                    одностороннем порядке.
                    <br />
                    6.2. Лицензионное вознаграждение рассчитывается исходя из объема предоставляемых
                    Пользователю прав на использование Программного обеспечения (срока, количества
                    информационных ресурсов, с которыми интегрируется Программное обеспечения).
                    <br />
                    6.3. Информация о действующих ставках лицензионного вознаграждения доводится до
                    Пользователя в электронной и (или) устной форме по его запросу.
                    <br />
                    6.4. Стоимость лицензии определяется на дату выставления счета. Если иное не
                    согласовано, сумма лицензионного вознаграждения, указанная в счете,
                    действительна в течение 3 (трех) рабочих дней с даты его выставления.
                    <br />
                    6.5. Уплата лицензионного вознаграждения осуществляется единовременно в порядке
                    предоплаты за последующий период использования Программным обеспечением.
                    <br />
                    6.6. Платежное поручение должно содержать номер учетной записи или ссылку на
                    настоящее Соглашение, а также ссылку на выставленный счет или соответствующие
                    размеры оплаты.
                    <br />
                    6.7. Права пользования Программным обеспечением и возможность его использования
                    (доступ к Программному обеспечению) предоставляются после поступления на
                    расчетный счет Компании лицензионного вознаграждения, в размере выбранной
                    Пользователем Лицензии.
                    <br />
                    6.8. Акты о предоставленном праве пользования Программным обеспечением
                    оформляются Компанией и направляются Пользователю на электронную почту,
                    указанную при регистрации на Сайте.
                    <br />
                    6.9. Пользователь обязуется в течение 5 (пяти) дней с даты получения акта
                    подписать его и направить отсканированную копию Компании на контактный адрес
                    электронной почты. Документы, направленные в соответствии с настоящим пунктом,
                    признаются совершенными в простой письменной форме и обладают юридической силой
                    до момента предоставления документов на бумажном носителе.
                    <br />
                    6.10. Оригиналы актов о предоставленном праве направляются Компанией
                    Пользователю в срок до 10 (десятого) числа календарного месяца, следующего за
                    месяцем, в котором Пользователь оплатил лицензию. В течение 5 (пяти) рабочих
                    дней с даты получения актов, Пользователь обязан их подписать и один экземпляр
                    подписанного акта направить Компании. При этом, обязательства Компании по
                    предоставлению прав пользования Программным обеспечением полагаются исполненными
                    в полном объеме и надлежащим образом.
                    <br />
                    6.11. В случае отсутствия подписанных Сторонами актов, положения настоящего
                    Соглашения и данные Программного обеспечения о факте его использования
                    полагаются достаточными для целей подтверждения предоставления Компанией
                    Пользователю прав пользования Программным обеспечением.
                    <br />
                    6.12. Компания вправе незамедлительно приостановить действие лицензии в случае
                    наличия задолженности Пользователя по уплате лицензионного вознаграждения.
                    <br />
                    6.13. Обязанность по уплате применимых банковских комиссий при перечислении
                    денежных средств лежит на Пользователе.
                    <br />
                    6.14. Обязательства Пользователя по оплате считаются исполненными с момента
                    зачисления средств на расчётный счет, указанный Компанией.
                    <br />
                    6.15. В случае отказа Пользователя от предоставленных ему Компанией прав
                    (лицензии), лицензионное вознаграждение Пользователю не возвращается.
                  </p>
                  <h1>7. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ И ОСВОБОЖДЕНИЕ ОТ ОТВЕТСТВЕННОСТИ</h1>
                  <p>
                    7.1. За неисполнение и (или) ненадлежащее исполнение принятых на себя
                    обязательств в рамках настоящего Соглашения Стороны несут ответственность в
                    соответствии с законодательством и условиями настоящего Соглашения.
                    <br />
                    7.2. Сторона освобождается от ответственности за неисполнение или ненадлежащее
                    исполнение своих обязательств по настоящему Соглашению, если такое неисполнение
                    вызвано обстоятельствами непреодолимой силы, возникшими после заключения между
                    Сторонами Договора.
                    <br />
                    7.3. К обстоятельствам непреодолимой силы относятся чрезвычайные и
                    непредотвратимые при данных условиях обстоятельства, такие как забастовки,
                    наводнения, землетрясения и иные стихийные бедствия, войны и военные действия, а
                    также иные обстоятельства, носящие объективный характер и находящиеся вне воли и
                    контроля Сторон.
                    <br />
                    7.4. Компания не несет ответственности за возможные противоправные действия
                    Пользователя относительно третьих лиц, либо третьих лиц относительно
                    Пользователя.
                    <br />
                    7.5. Получение третьими лицами доступа к информации, хранящейся в Программном
                    обеспечении, противоправным путем, в том числе, посредством взлома защиты
                    источников, либо мест хранения информации (несмотря на принимаемые Компанией
                    меры по защите от несанкционированного доступа), не является основанием для
                    привлечения Компании к ответственности, равно основанием для возмещения
                    Компанией каких бы то ни было убытков.
                    <br />
                    7.6. Компания не несет ответственности за ущерб, убытки или расходы (реальные
                    либо возможные), возникшие в связи с использованием Сайта или невозможностью его
                    использования.
                    <br />
                    7.7. Компания не несет ответственности за утерю Пользователем возможности
                    доступа к своему аккаунту — учетной записи на Сайте.
                    <br />
                    7.8. Компания не несет ответственности за неполное, неточное, некорректное
                    указание Пользователем своих данных при создании учетной записи Пользователя.
                    <br />
                    7.9. Ограничение доступа к информационному ресурсу, на котором используется
                    Программное обеспечение, актами органов государственной власти, не является
                    нарушением Компанией своих обязательств по Соглашению и основанием для возврата
                    Пользователю денежных средств, уплаченных им в пользу Компании по Соглашению.
                    <br />
                    7.10. Пользователь настоящим признает и соглашается с тем, что Программное
                    обеспечение, не свободно от программных ошибок и сбоев. Компания не несет
                    ответственности перед Пользователем за возможные убытки последнего, вызванные
                    указанными сбоями (ошибками).
                    <br />
                    7.11. Ответственность за действия (бездействия), осуществляемые Пользователем
                    посредством Программного обеспечения, в полном объеме лежит на Пользователе.
                    Пользователь самостоятельно и за собственный счет рассматривает и при
                    необходимости удовлетворяет претензии (заявления, исковые заявления,
                    требования), предъявленные Пользователю (Компании) в связи с нарушением
                    Пользователем при использовании Программного обеспечения прав третьих лиц
                  </p>
                  <h1>8. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h1>
                  <p>
                    8.1. Все споры или разногласия, возникающие в связи с исполнением и (или)
                    неисполнением, и (или) ненадлежащим исполнением положений настоящего Соглашения,
                    разрешаются с обязательным соблюдением претензионного порядка.
                    <br />
                    8.2. До обращения в суд с иском по спорам, возникающим из отношений между
                    Пользователем и Компанией, обязательным является предъявление претензии
                    (письменного предложения о добровольном урегулировании спора).
                    <br />
                    8.3. Сторона, полагающая свои права нарушенными, направляет другой стороне в
                    простой письменной форме претензию с приложением надлежащим образом заверенных
                    документов, обосновывающих изложенные требования, в течение 5 (пяти) дней с
                    момента возникновения спора. В случае нарушения 5-дневного срока направления
                    претензии, претензия не подлежит рассмотрению.
                    <br />
                    8.4. Получатель претензии в течение 30 календарных дней со дня получения
                    претензии, письменно уведомляет заявителя претензии о результатах рассмотрения
                    претензии.
                    <br />
                    8.5. При не достижении согласия, спор может быть передан на рассмотрение в
                    судебный орган в соответствии с действующим законодательством Республики
                    Казахстан.
                  </p>
                  <h1>9. ИНЫЕ УСЛОВИ</h1>
                  <p>
                    9.1. При возникновении проблем в использовании Программного обеспечения,
                    несогласия с конкретными разделами Пользовательского соглашения, либо получении
                    Пользователем недостоверной информации от третьих лиц, либо информации
                    оскорбительного характера, любой иной неприемлемой информации, Пользователь
                    обращается к Компании для анализа и устранения соответствующих дефектов,
                    ограничений и предотвращения поступления на Сайт нежелательной информации, а
                    также, при необходимости, ограничении либо прекращения обязательства по
                    предоставлению услуг любому Пользователю и клиенту, умышленно нарушающему
                    предписания Соглашения и функционирование работы Программного обеспечения.
                    <br />
                    9.2. В целях вышеизложенного Компания оставляет за собой право удалять
                    размещенную на Сайте информацию и предпринимать технические и юридические меры
                    для прекращения доступа к Сайту Пользователей, создающих проблемы в
                    использовании Сайта другими Пользователями, или Пользователей, нарушающих
                    требования Соглашения.
                    <br />
                    9.3. Пользователь дает свое согласие на получение информационно-справочной и
                    рекламной рассылки на контактные адреса (номера), указанные при регистрации и
                    (или) предоставленные Компании иным способом, равно на обработку указанных
                    данных в целях осуществления рекламно - информационной рассылки.
                    <br />
                    9.4. Пользователь дает свое согласие на размещение информации о нем (его
                    наименовании или ФИО) на сайте Компании в разделе клиентов и партнеров, в
                    информационных, маркетинговых, рекламных материалах, распространяемых Компанией,
                    публикацию и публичное размещение информации о сотрудничестве с Компанией, о
                    достигнутых в ходе сотрудничества результатах.
                    <br />
                    9.5. Компания имеет право ссылаться на Клиента в средствах массовой информации
                    (онлайн, офлайн, социальные медиа), на продукции рекламного, маркетингового,
                    образовательного характера, распространяемой среди неограниченного круга лиц, в
                    том числе на мероприятиях (конференциях, семинарах, вебинарах, выставках,
                    корпоративных и деловых встречах).
                  </p>
                  <h1>10. ПОЛОЖЕНИЕ О ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
                  <p>
                    10.1. Пользователь (физическое лицо или индивидуальный предприниматель), <br />
                    осуществляя регистрацию на Сайте Компании, дает свое безусловное согласие
                    Компании <br />
                    на сбор, обработку и хранение персональных данных, как на бумажных носителях,
                    так и в <br />
                    электронном формате, в массивах и (или) базах данных Компании согласно Закону{' '}
                    <br />
                    Республики Казахстан «О персональных данных и их защите»..
                    <br />
                    10.2. Персональные данные - это любые относящиеся к Пользователю сведения,{' '}
                    <br />
                    зафиксированные на электронном, бумажном и (или) ином материальном носителе,{' '}
                    <br />
                    которые могут содержать (включая, но не ограничиваясь):
                    <br />- сведения, необходимые для регистрации на сайте и надлежащей
                    идентификации <br />
                    (фамилия, имя, отчество); данные документа, удостоверяющего личность
                    (удостоверения <br />
                    личности или паспорта); индивидуальный идентификационный номер; дата и данные о{' '}
                    <br />
                    рождении; пол; подпись; биометрические данные и пр.);
                    <br />
                    - сведения, необходимые для поддержания связи (место регистрации, место
                    фактического <br />
                    проживания или пребывания), место работы и должность; номер телефона (рабочий,{' '}
                    <br />
                    сотовый), адрес электронной почты);
                    <br />
                    - сведения, связанные с оказанием Компанией услуг в рамках предоставления прав
                    на <br />
                    Программное обеспечение - тексты договоров (соглашений), дополнительных
                    соглашений <br />
                    к ним, заявления и согласия, переписка, правоустанавливающие документы и пр.;
                    <br />
                    - сведения о цели и характере деловых отношений;
                    <br />
                    - информация об изменении и(или) дополнении вышеуказанных данных.
                    <br />
                    10.3. Пользователь подтверждает, что:
                    <br />
                    1) уведомлен о защите персональных данных;
                    <br />
                    2) уведомлен о праве отозвать/дать согласие на сбор, обработку персональных
                    данных <br />
                    письменно или в форме электронного документа либо иным способом с применением{' '}
                    <br />
                    элементов защитных действий, не противоречащих законодательству Республики{' '}
                    <br />
                    Казахстан;
                    <br />
                    3) согласие на сбор и обработку персональных данных не подлежит отзыву, только в{' '}
                    <br />
                    случаях, если это противоречит законам Республики Казахстан, либо при наличии{' '}
                    <br />
                    неисполненного обязательства;
                    <br />
                    4) Компания вправе производить сбор и обработку персональных данных без его
                    согласия <br />в случаях, установленных законами Республики Казахстан, в том
                    числе, в иных <br />
                    организациях для целей проведения любых, не запрещенных законодательством <br />
                    Республики Казахстан, мероприятий;
                    <br />
                    5) Компания вправе собирать (получать) его персональные данные от него и любых{' '}
                    <br />
                    третьих лиц, а также передавать его персональные данные третьим
                    лицам/распространять <br />в общедоступных источниках персональных данных, с
                    учетом требований действующего <br />
                    законодательства Республики Казахстан и требований международного права
                    (требований <br />
                    международных договоров);`
                    <br />
                    6) его согласие предоставляет Компании право, в установленном законодательством{' '}
                    <br />
                    Республики Казахстан порядке, осуществлять с его персональными данными любое{' '}
                    <br />
                    действие (операцию) или совокупности таких действий (операций), в том числе,{' '}
                    <br />
                    совершаемых с использованием средств автоматизации или без использования
                    последних, <br />
                    включая, но не ограничиваясь, сбор, обработку, запись, копирование, резервное{' '}
                    <br />
                    копирование, защиту, систематизацию, накопление, хранение внутри Компании и за
                    его <br />
                    пределами (с соблюдением требований безопасности и конфиденциальности),
                    уточнение <br />
                    (изменение, обновление), извлечение, использование, передачу (распространение,{' '}
                    <br />
                    предоставление доступа, в том числе, передачу третьим лицам с правом обработки{' '}
                    <br />
                    персональных данных, включая трансграничную передачу), обезличивание,
                    блокирование, <br />
                    удаление, уничтожение;
                    <br />
                    7) согласие может быть представлено третьим лицам в качестве доказательства о
                    том, что <br />
                    согласие на сбор и обработку персональных данных было предоставлено
                    пользователем <br />
                    Товариществу с ограниченной ответственностью «ТЕХНОSTYLE».
                    <br />
                  </p>
                  <h1>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h1>
                  <p>
                    11.1. Настоящее Соглашение вступает в силу с момента его размещения на Сайте и{' '}
                    <br />
                    действует бессрочно.
                    <br />
                    11.2. Компания вправе в любое время вносить дополнения и изменения в настоящее{' '}
                    <br />
                    Соглашение, которые публикуются и доводятся до всеобщего сведения посредством{' '}
                    <br />
                    Сайта.
                    <br />
                    11.3. Использование Пользователем Программного обеспечения после внесения <br />
                    изменений в текст настоящего Соглашения означает акцепт настоящего Соглашения с{' '}
                    <br />
                    учетом внесенных изменений.
                    <br />
                    11.4. Настоящее Соглашение являются открытым и общедоступным документом
                  </p>
                </div>
                <label
                  className='regCheckbox'
                  style={{
                    width: 'min-content',
                    margin: 'auto',
                  }}
                >
                  <input type='checkbox' onClick={() => setRegCheckbox(!regCheckbox)} />Я согласен с
                  пользовательским соглашением
                </label>

                <div className='inputGroup' style={{}}>
                  <button
                    disabled={regCheckbox ? false : true}
                    onClick={(e) => {
                      if (verificationCode == serverVerificationCode.substr(2, 4)) {
                        SendRegForm();
                        console.log('success');
                      } else {
                        verifyCode_error();
                        console.log('error');
                      }
                    }}
                    className='reg_btn'
                    style={{ marginBottom: 20, opacity: regCheckbox ? 1 : 0.3 }}
                  >
                    Продолжить
                  </button>

                  <button
                    onClick={(e) => {
                      window.location.reload();
                    }}
                    className='reg_btn'
                    style={{ marginBottom: 20, fontSize: '14px' }}
                  >
                    Введен неверный номер
                  </button>
                </div>
              </div>

              <button
                className='reg_abs'
                style={{ textAlign: 'center', marginBottom: 10 }}
                onClick={() => history.goBack()}
              >
                назад
              </button>
            </>

            <div style={{ marginBottom: '130px' }}>
              <img style={{ display: 'inline' }} src={star}></img>
              <p style={{ marginLeft: '10px', display: 'inline' }}>
                {' '}
                поля обязательные к заполнению
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ToastContainer position='top-center' theme='dark' limit={3} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration_form;
