import React, { useEffect, useState } from 'react';
import bshopApi from '../../../api/bshopApi';
import ItemBshop from '../itemCatalogBshop/Item_bshop';
import s from '../MainBshop.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingBlock from './LoadingBlock';
import { useSelector, useDispatch } from 'react-redux';
import {
  onSearchItemBshop,
  onAddItemBshop,
  onScrollCatalog,
  AddCountItemBshop,
} from '../../../redux/action';
import uniqid from 'uniqid';

const ItemsWrapper = () => {
  const dispatch = useDispatch();
  const { value, end, onShopId, onCategoryId } = useSelector((state) => state.onSearchItemBshop);
  const items = useSelector((state) => state.itemsBshop);
  const countItems = useSelector((state) => state.counItems);
  const beenScroll = useSelector((state) => state.scrollCatalog);
  const urlPath = window.location.pathname;
  const getItems = async (end, value, onShopId, onCategoryId) => {
    const responseItems = await bshopApi.fetchItems(end, value, onShopId, onCategoryId);
    dispatch(onAddItemBshop(responseItems.data));
    console.log();
    if (beenScroll && urlPath.slice(0, 15) === '/shopper/shopid') {
      await window.scrollTo({
        top: beenScroll,
      });
    }
  };

  useEffect(() => {
    if (items.length === 0) {
      getItems(countItems, '', '3', '');
    }
  }, []);

  useEffect(() => {
    if (items.length !== 0) {
      if (value === '' && onShopId === '' && onCategoryId === '') {
        getItems(countItems, '', '3', '');
      } else {
        getItems(end, value, onShopId, onCategoryId);
      }
    }
  }, [countItems, value, end, onShopId, onCategoryId]);

  const fetchNewItems = () => {
    dispatch(onScrollCatalog(''));
    if (value === '' && onShopId === '' && onCategoryId === '') {
      dispatch(AddCountItemBshop(countItems + 50));
    } else {
      dispatch(
        onSearchItemBshop({
          value: value,
          end: end + 50,
          onShopId: onShopId,
          onCategoryId: onCategoryId,
        }),
      );
    }
  };

  return (
    <div
      className={s.all_items}
      id="scrollableDiv"
      style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
      {items.length > 0 ? (
        <InfiniteScroll
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: '0, -4px',
            justifyContent: 'space-between',
          }}
          dataLength={items.length}
          next={fetchNewItems}
          hasMore={true}
          loader={
            <div style={{ width: '50%' }}>
              {/* {Array.from(Array(4), (_, i) => (
                <LoadingBlock key={uniqid()} />
              ))} */}
            </div>
          }
          sscrollableTarget="scrollableDiv">
          {items.map((item) => (
            <ItemBshop key={uniqid()} {...item} />
          ))}
        </InfiniteScroll>
      ) : (
        Array.from(Array(4), (_, i) => <LoadingBlock key={uniqid()} />)
      )}
    </div>
  );
};

export default React.memo(ItemsWrapper);
