import React, { useEffect, useState } from 'react';
import s from '../pageMaster.module.css';
import { useSelector, useDispatch } from 'react-redux';

import beautyApi from '../../../api/beautyApi';

import {
  setAllPageMasterCats,
  setAllPageMasterServices,
  setOptionalPageMasterServices,
  setAllPageMasterData,
  setOptionalPageMasterDopServices,
} from '../../../redux/action';

import { ToastContainer, toast } from 'react-toastify';

import PageMasterRecordDates from './PageMasterRecordDates';
const PageMasterRecord = ({ ContentSwitcherOff, wave_error, wave_success }) => {
  const dispatch = useDispatch();

  const [dataMaster, setDataMaster] = useState();

  const [selectedCat, setSelectedCat] = useState('Не выбрано');
  const [selectedServices, setSelectedServices] = useState('Не выбрано');
  const [DopselectedServices, setDopSelectedServices] = useState('Не выбрано');
  const [selectedTime, setselectedTime] = useState('Не выбрано');
  const [selectedDate, setselectedDate] = useState(new Date().toISOString().slice(0, 10));

  const [Comm, setComm] = useState('-');

  const [selectedPrice, setSelectedPrice] = useState('-');

  const AllPageMasterCats = useSelector((state) => state.AllPageMasterCats);
  const AllPageMasterServices = useSelector((state) => state.AllPageMasterServices);
  const AllPageMasterData = useSelector((state) => state.AllPageMasterData);
  const OptionalPageMasterServices = useSelector((state) => state.OptionalPageMasterServices);
  const OptionalPageMasterDopServices = useSelector((state) => state.OptionalPageMasterDopServices);
  const idClient = useSelector((state) => state.accountBeauty?.id);

  var arrObjects1 = [];
  var arrObjects2 = [];
  var arrObjects3 = [];
  var arrObjects4 = [];
  var arrObjects5 = [];

  const urlPath = window.location.search
    .replace('?', '')
    .split('&')
    .reduce(function (p, e) {
      const a = e.split('=');
      p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
      return p;
    }, {});

  useEffect(() => {
    getDataMaster(urlPath.id);
  }, []);

  const getDataMaster = async (id) => {
    const fd = new FormData();
    fd.append('id', id);
    const response = await beautyApi.ServicesOfMaster(1, 1, id);
    // await setDataMaster(response);
    for (let i = 0; i < response.length; i++) {
      let switcher1 = 0;
      let switcher2 = 0;
      let switcher3 = 0;

      let dataItem1 = makeData_Category(
        response[i].cat_id,
        response[i].cat_name,
        response[i].cat_id,
        response[i].cat_name,
        response[i].InnerID,
        response[i].masterId,
        response[i].serives_add_id_names,
        response[i].services_add_id,
        response[i].services_id,
        response[i].services_name,
        response[i].priceServices,
      );
      let dataItem2 = makeData_Category(
        response[i].services_id,
        response[i].services_name,
        response[i].cat_id,
        response[i].cat_name,
        response[i].InnerID,
        response[i].masterId,
        response[i].serives_add_id_names,
        response[i].services_add_id,
        response[i].services_id,
        response[i].services_name,
        response[i].priceServices,
      );

      let dataItem3 = makeData_Category(
        response[i].services_id,
        response[i].services_name,
        response[i].cat_id,
        response[i].cat_name,
        response[i].InnerID,
        response[i].masterId,
        response[i].serives_add_id_names,
        response[i].services_add_id,
        response[i].services_id,
        response[i].services_name,
        response[i].priceServices,
      );

      arrObjects1.map((x, y) =>
        response[i].cat_name == x.value ? (switcher1 = 1) : response[i].cat_name,
      );
      arrObjects2.map((x, y) =>
        response[i].services_name == x.value
          ? response[i].services_name !== 'Все услуги'
            ? (switcher2 = 1)
            : null
          : response[i].services_name,
      );
      response[i].serives_add_id_names == null ? (switcher3 = 1) : console.info();

      switcher1 == 0 ? (arrObjects1[i] = dataItem1) : console.info();
      switcher2 == 0 ? (arrObjects2[i] = dataItem2) : console.info();
      switcher3 == 0 ? (arrObjects4[i] = dataItem3) : console.info();
      //   console.log(arrObjects1.length);
    }
    dispatch(setAllPageMasterCats(arrObjects1));
    dispatch(setAllPageMasterServices(arrObjects2));
    dispatch(setAllPageMasterData(arrObjects4));
  };

  const ContentSwitcherOffProp = () => {
    typeof idClient == 'undefined'
      ? wave_error_not_auth()
      : selectedServices == 'Не выбрано'
      ? wave_error_not_selected()
      : DopselectedServices == 'Не выбрано'
      ? OptionalPageMasterDopServices.length !== 0
        ? wave_error_not_selected()
        : selectedTime == 'Не выбрано'
        ? wave_error_not_selected_time()
        : SendRecordAndGetResponse()
      : selectedTime == 'Не выбрано'
      ? wave_error_not_selected_time()
      : SendRecordAndGetResponse();
  };

  const SendRecordAndGetResponse = async () => {
    const response = await SendRecord();
    response[0] == true ? (response[1] == true ? wave_success() : wave_error()) : wave_error();
    ContentSwitcherOff();
    // response[0] == true ? (response[1] == true ? console.log(1) : console.log(2)) : console.log(3);
  };

  const SendRecord = async () => {
    const fd = new FormData();
    fd.append('idClient', idClient);
    fd.append('idMaster', urlPath.id);
    fd.append('date', selectedDate);
    fd.append('time', selectedTime);
    fd.append('selectedCat', selectedCat);
    fd.append('selectedServices', selectedServices);
    fd.append('DopselectedServices', DopselectedServices);
    fd.append('price', selectedPrice);
    fd.append('comm', Comm);

    const fdd = new FormData();
    fdd.append('idMaster', urlPath.id);

    const response = await beautyApi.sendPersonalRecord(fd);

    const responsePush = await beautyApi.pushIndividual(fdd);

    const responseData = response.json();
    return responseData;
  };

  const handleCatChoose = (event) => {
    setSelectedCat(event.target.value);
    AllPageMasterServices.map((x, y) => {
      x.cat_name == event.target.value ? (arrObjects3[y] = x) : console.info();
    });
    dispatch(setOptionalPageMasterServices(arrObjects3));
    setSelectedServices('Не выбрано');
    setSelectedPrice('-');
  };

  const handleTimeSelect = (time) => {
    setselectedTime(time);
  };

  const handleDateSelect = (Date) => {
    setselectedDate(Date);
  };

  const handleServiceChoose = (event) => {
    setSelectedServices(event.target.value);
    AllPageMasterData.map((x, y) => {
      x.services_name == event.target.value ? (arrObjects5[y] = x) : console.info();
    });

    AllPageMasterServices.map((x, y) => {
      x.services_name == event.target.value
        ? x.serives_add_id_names == null
          ? setSelectedPrice(x.priceServices)
          : setSelectedPrice('-')
        : console.info();
    });

    dispatch(setOptionalPageMasterDopServices(arrObjects5));
    setDopSelectedServices('Не выбрано');
  };

  const handleDopServiceChoose = (event) => {
    setDopSelectedServices(event.target.value);
    AllPageMasterData.map((x, y) => {
      x.serives_add_id_names == event.target.value
        ? setSelectedPrice(x.priceServices)
        : console.info();
    });
  };

  const handleComm = (comm) => {
    setComm(comm);
  };

  const makeData_Category = (
    id,
    value,

    cat_id,
    cat_name,
    InnerID,
    masterId,
    serives_add_id_names,
    services_add_id,
    services_id,
    services_name,
    priceServices,
  ) => {
    return {
      id: id,
      value: value,

      cat_id: cat_id,
      cat_name: cat_name,
      InnerID: InnerID,
      masterId: masterId,
      serives_add_id_names: serives_add_id_names,
      services_add_id: services_add_id,
      services_id: services_id,
      services_name: services_name,
      priceServices,
      // ...другие свойства
    };
  };

  const wave_error_not_auth = () => {
    toast('Пожалуйста авторизуйтесь', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_error_not_selected = () => {
    toast('Пожалуйста выберите услугу', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_error_not_selected_time = () => {
    toast('Пожалуйста выберите время', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_error_not_selected_date = () => {
    toast('Пожалуйста выберите дату', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };
  return (
    <>
      <div className={s.fakeHeader}>
        <div></div>
        <div className={s.fakeHeaderTitle}>Запись</div>
        <div>
          <div className={s.BackButton} onClick={() => ContentSwitcherOff()}>
            <svg className='svg-icon' viewBox='0 0 20 20' height='24px' width='24px'>
              <path
                fill='#404246'
                d='M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z'
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center' }} className={s.content}>
        <div>
          <select
            style={{ margin: 'auto', marginTop: '20px', marginBottom: '20px' }}
            className={s.dropdown_serv}
            onChange={(e) => handleCatChoose(e)}
          >
            <option defaultValue='defaultValue'>{selectedCat}</option>
            {AllPageMasterCats?.map((x, y) => (
              <option key={x.prof} value={x.value}>
                {x.value}
              </option>
            ))}
          </select>
        </div>

        <div style={{ display: selectedCat == 'Не выбрано' ? 'none' : 'block' }}>
          <select
            style={{ margin: 'auto', marginBottom: '20px' }}
            className={s.dropdown_serv}
            onChange={(e) => handleServiceChoose(e)}
          >
            <option defaultValue='defaultValue'>{selectedServices}</option>
            {OptionalPageMasterServices?.map((x, y) => (
              <option key={x.prof} value={x.value}>
                {x.value}
              </option>
            ))}
          </select>
        </div>

        <div
          style={{
            display:
              selectedServices == 'Не выбрано'
                ? 'none'
                : OptionalPageMasterDopServices.length == 0
                ? 'none'
                : 'block',
          }}
        >
          <select
            style={{ margin: 'auto', marginBottom: '20px' }}
            className={s.dropdown_serv}
            onChange={(e) => handleDopServiceChoose(e)}
          >
            <option defaultValue='defaultValue'>{DopselectedServices}</option>
            {OptionalPageMasterDopServices?.map((x, y) => (
              <option key={x.prof} value={x.serives_add_id_names}>
                {x.serives_add_id_names}
              </option>
            ))}
          </select>
        </div>

        <PageMasterRecordDates
          masterID={urlPath.id}
          handleTimeSelect={handleTimeSelect}
          handleDateSelect={handleDateSelect}
        />

        <div
          className={s.button_write}
          style={{
            margin: 'auto',
            display: selectedPrice == '-' ? 'none' : 'block',
            width: '100%',
            marginBottom: '10px',
          }}
        >
          {selectedPrice}
        </div>

        <div className={s.input_label} style={{ marginTop: '0px' }}>
          <input
            onChange={(e) => {
              handleComm(e.target.value);
            }}
            type='text'
            placeholder='Добавить комментарий'
          />
        </div>

        <div
          className={s.button_write}
          onClick={() => {
            ContentSwitcherOffProp();
          }}
          style={{ margin: 'auto' }}
        >
          Отправить
        </div>
      </div>
      <ToastContainer theme='light' />
    </>
  );
};

export default PageMasterRecord;
