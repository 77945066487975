import React, { Component } from 'react';

import { connect } from 'react-redux'
import { setItem, setItemCategory } from '../redux/action';

import Button from '../companents/button';
import SearchInput from '../companents/search-input';
import ItemCafeProduct from '../companents/item-cafe-product';
import SliderSwiper from '../companents/slider';
import CardRestoran from '../companents/cardRestoran'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'

import clock from '../asseets/clock.png'

class Cafe extends Component {

    state = {
        filterList: [],

        showSearchInput: false,

        showCardRestoran: false,

        value: '',
        type: 'btn'
    }

    searchValue = (e) => this.setState({ value: e.target.value, type: 'input' })

    filterCategoryItem = (id) => {
        const listProductCafe = Object.assign({}, ...this.props.itemCafe).ListProduct;
        const filterList = listProductCafe.filter(el => el.idcategory === id)
        this.setState({ filterList, type: 'btn' })
    }

    toogleShowSearchInput = () => this.setState({ showSearchInput: !this.state.showSearchInput })

    showCardRestoran = () => this.setState({ showCardRestoran: true })

    closeModalEvent = (e) => {
        if (e === 'main') this.setState({ showCardRestoran: false })
    }

    closeModal = () => this.setState({ showCardRestoran: false })

    async componentDidMount() {
        const id = this.props.match.params.id

        const formData = await new FormData();
        await formData.append('id', id)
        const res = await fetch(`https://bikli.kz/apiBikli/index.php?method=cafe.edaById`, { method: 'POST', body: formData });
        const json = await res.json();
        await this.props.setItem(json, 'itemCafe')

        const res1 = await fetch(`https://bikli.kz/apiBikli/index.php?method=cafe.cafeCategories`, { method: 'POST', body: formData });
        const json1 = await res1.json();
        this.props.setItemCategory(json1);
    }

    render() {
        const { listProductCafe, itemCategory, itemCafe } = this.props
        const { name, logo } = Object.assign({}, ...this.props.itemCafe)
        const image = `https://bikli.kz/LogoCafe/${logo}`
        const newProd = this.state.type === 'btn'
            ? this.state.filterList
            : listProductCafe.filter(el => el.nameeda.toLowerCase().indexOf(this.state.value.toLowerCase()) !== -1);
        const cafe = Object.assign({}, ...itemCafe);

        // const [, start] = cafe.timestart?.split(" ");
        // const timeStart = start?.split(":").slice(0, 2).join(":");

        // const [, end] = cafe?.timeend?.split(" ");
        // const timeEnd = end?.split(":").slice(0, 2).join(":");
        console.log(this.props.match.params.id);
        return (
            <div className='cafe'>
                <div className='cafe-wrapper'>

                    <div className='cafe-logo-main'>
                        <div className='cafe-log-about'>
                            <img src={image} alt='logo' onClick={() => this.showCardRestoran()} />

                            <div className='delivery-clock'>
                                <img src={clock} alt='' />
                                <span>10.00-23.00</span>
                            </div>
                        </div>
                    </div>

                    <div className='cafe-info'>
                        <div className='cafe-logo-main-info'>
                            <div>
                                <p className='delivery-info'>время доставки</p>
                                <span>{cafe.vremya} мин</span>
                            </div>
                            <div>
                                <p className='delivery-info'>цена доставки</p>
                                <span>{cafe.cenadost} тнг</span>
                            </div>
                            <div>
                                <p className='delivery-info'>мининальный заказ</p>
                                <span>{cafe.minsumma} тнг</span>
                            </div>
                        </div>

                        {this.state.showCardRestoran
                            ? <CardRestoran closeModalEvent={this.closeModalEvent} closeModal={this.closeModal} restoran={this.props.itemCafe} />
                            : null}


                        <div className='line'></div>
                    </div>

                    <div className='cafe-category'>
                        <Button value='search' class='btn-search-category-cafe' click={this.toogleShowSearchInput} />
                        <div className='cafe-category-wrapper'>
                            <SliderSwiper>
                                {itemCategory.map(el => {
                                    return (
                                        <Button key={el.id} value={el.namecategory} class='btn-category-cafe' click={() => this.filterCategoryItem(el.id)} />
                                    )
                                })}
                            </SliderSwiper>
                        </div>
                    </div>

                    {this.state.showSearchInput
                        ? <SearchInput searchValue={this.searchValue} />
                        : null}

                    <ItemCafeProduct menu={listProductCafe} newMenu={newProd} data={this.props} />
                </div >
            </div >
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setItem, setItemCategory }

export default connect(mapStateToProps, mapDispatchToProps)(Cafe);