import React, { Component } from 'react';

// import './style.css'

class SubHistoryItem extends Component {

    render() {
        return (
            <div>
                {this.props.item.map(el => {
                    return (
                        <div className='histoty-sub-item' key={el.id}>
                            <p>{el.nameeda}</p>
                            <span>{el.count} шт.</span>
                            <h4>{el.cenaeda} тнг</h4>
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default SubHistoryItem;