import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import Button from '../companents/button';

class Login extends Component {

    state = {
        // phone_user: '',
        code: '',

        status: true,

        checkToken: false,
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    sendSMS = () => {
        const phone_user = this.props.match.params.phone ? this.props.match.params.phone : this.state.phone_user
        this.props.sendSMS(phone_user)
        this.setState({ phone_user: '' })
    }

    authUser = () => {
        console.log(this.props.phone_user);
        if (this.props.match.params.phone) {
            const data = { code: this.state.code, phone_user: this.props.match.params.phone.replace(/\D/g, "") }
            this.props.authUser(data)
            this.setState({ status: false })
        } else if (this.props.phone_user) {
            const data = { code: this.state.code, phone_user: this.props.phone_user.replace(/\D/g, "") }
            this.props.authUser(data)
        }
        for (const key in this.state) {
            this.setState({ [key]: '' })
        }
    }

    getDataUser = () => {
        this.props.getDataUser()
    }

    componentDidMount() {
        if (!localStorage.getItem('access_token') && !this.props.match.params.phone) {
            this.setState({ checkToken: false })
        } else {
            this.setState({ checkToken: true })
        }
    }

    render() {
        const formPhoneAuth = { title: 'Введите номер телефона', value: 'phone_user', placeholder: 'Введите номер телефона', type: 'text', name: 'phone_user' };
        const formSMSAuth = { title: 'Введите СМС код', value: 'code', placeholder: 'Введите СМС код', type: 'text', name: 'code' };
        console.log(this.props.phone_user);
        return (
            <div className='cabinet'>
                {this.state.status
                    ?
                    <div className='cabinet-wrapper'>
                        <div className='cabinet-text'>
                            <h3>Авторизация</h3>
                        </div>

                        <div className='cabinet-items'>

                            <div className='cabinet-form-reg'>
                                <form onSubmit={e => e.preventDefault()} className='cabinet-form'>
                                    <div className='form-inner'>
                                        <div className='cabinet-form-item'>
                                            <div className='checkToken'>
                                                <span>{formSMSAuth.title}</span>
                                                <input type={formSMSAuth.type} name={formSMSAuth.value}
                                                    value={this.state[formSMSAuth.value]} autoComplete='off' onChange={this.handleChange}
                                                    placeholder={formSMSAuth.placeholder} />
                                            </div>
                                        </div>
                                    </div>
                                    <Button value='Повторно отправить СМС код ' class='btn_send-sms' click={this.sendSMS} />
                                    <Button value='АВТОРИЗАЦИЯ' class='btn_reg_cabinet' click={this.authUser} />
                                </form>
                            </div>
                        </div>
                    </div >
                    :
                    <div className='cabinet-wrapper'>

                        {this.props.error
                            ?
                            <div className='authStatusTrue'>
                                <h3>Вы ввели не корректные данные</h3>
                                <Link to='/main' className='link'>
                                    <Button value='АВТОРИЗАЦИЯ' class='btn_reg_cabinet' />
                                </Link>
                            </div>
                            :
                            <>
                                {this.props.match.params.phone
                                    ?
                                    <div className='authStatusTrue'>
                                        {this.props.match.params.phone
                                            ? <h3>Ваш заказ оформлен</h3>
                                            : <h3>Вы успешно авторизировались</h3>}
                                        <Link to='/main' className='link'>
                                            <Button value='ПРОДОЛЖИТЬ' class='btn_reg_cabinet' click={this.getDataUser} />
                                        </Link>
                                    </div>
                                    :
                                    <div className='authStatusTrue'>
                                        <h3>Вы успешно авторизировались</h3>
                                        <Link to='/main' className='link'>
                                            <Button value='ПРОДОЛЖИТЬ' class='btn_reg_cabinet' click={this.getDataUser} />
                                        </Link>
                                    </div>}
                            </>
                        }
                    </div>
                }

            </div>
        );
    }
}

export default Login;