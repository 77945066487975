import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux'
import { setItem, setItemCategory } from '../redux/action';

import './style.css'


class Gifts extends Component {

    selectedItem = async (id) => {
        const formData = await new FormData();
        await formData.append('id', id)
        const res = await fetch(`https://bikli.kz/apiBikli/index.php?method=cafe.edaById`, { method: 'POST', body: formData });
        const json = await res.json();
        await this.props.setItem(json, 'itemCafe')

        const res1 = await fetch(`https://bikli.kz/apiBikli/index.php?method=cafe.cafeCategories`, { method: 'POST', body: formData });
        const json1 = await res1.json();
        this.props.setItemCategory(json1);
    }


    async componentDidMount() {
        // const user_id = Object.assign({}, ...this.props.user).id;
        // const formData = new FormData();
        // formData.append('user_id', user_id)
        // formData.append('access_token', localStorage.getItem('access_token'))
        try {
            this.props.getData('gifts', 'akcii.SpisokAkcii')
        } catch (error) {
            this.props.errorStatus()
        }
    }

    render() {
        return (
            <div className='gifts'>
                <div className='gifts-wrapper'>
                    <div className='gifts-text'>
                        <h3>Акции</h3>
                    </div>

                    <div className='gifts-items'>

                        {this.props.gifts.map(el => {
                            const image = `https://bikli.kz/imgProduct/${el.img}`;
                            return (
                                <Link to='/cafe' className='card-wrapper' onClick={() => this.selectedItem(el.idcafe)}>
                                    <div className='item-gift' key={el.id} selectedItem={this.selectedItem}>
                                        <img src={image} alt='logo' />
                                        <div className='item-gift-text'>
                                            <h3>{el.name}</h3>
                                            <p>{el.opis}</p>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { setItem, setItemCategory }

export default connect(mapStateToProps, mapDispatchToProps)(Gifts);