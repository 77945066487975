import React from 'react';
import s from '../pageMaster.module.css';

const PageMasterButtons = ({ ContentSwitcherOn }) => {
  const ContentSwitcherOnProp = () => {
    ContentSwitcherOn();
  };
  return (
    <div className={s.buttons_person}>
      <div className={s.button_subscribe} style={{ opacity: 0.6 }}>
        <div>Подписаться</div>
      </div>
      <div
        className={s.button_write}
        onClick={() => {
          ContentSwitcherOnProp();
        }}
        // style={{ opacity: 0.6 }}
      >
        Записаться
      </div>
    </div>
  );
};

export default PageMasterButtons;
