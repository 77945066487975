import React, { useCallback, useEffect, useRef, useState } from 'react';
import s from '../../../headerNew/header.module.css';

import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import Calendar from 'react-calendar';
import '../../Calendar.css';

import dayjs from 'dayjs';
import objToFd from '../../../../common/helpers/objToFd';
import beautyApi from '../../../../api/beautyApi';
import { useSelector, useDispatch } from 'react-redux';
import {
  DisplayType,
  DopDisplayType,
  onAddAllServForClientMainReq,
  onAddServForClientReq,
  onAddServForClientReq_1,
  onAddServForClientReq_2,
  onAddServForClientReq_3,
  onAddServForClientReq_4,
  onAddServForClientReq_5,
  onAddServForClientReq_11,
  onAddServForClientReq_21,
  onAddServForClientReq_31,
  onAddServForClientReq_41,
  onAddServForClientReq_51,
  setauthDisplay,
  setSlippedRecordsNum,
  onSaveAndAddServForClientReq_1,
  onSaveAndAddServForClientReq_2,
  onSaveAndAddServForClientReq_3,
  onSaveAndAddServForClientReq_4,
  onSaveAndAddServForClientReq_5,
  onSaveAndAddServForClientReq_11,
  onSaveAndAddServForClientReq_21,
  onSaveAndAddServForClientReq_31,
  onSaveAndAddServForClientReq_41,
  onSaveAndAddServForClientReq_51,
  onSaveAndAddServForClientPrice,
  onSaveAndAddServForClientTime,
  onSaveAndAddServForClientDate,
  onSaveAndAddServForClientReq,
} from '../../../../redux/action';
import { ToastContainer, toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import ChildSelectModalPage from '../../../headerNew/ChildSelectModalPage';
import Inputmask from 'inputmask';
import { useHistory } from 'react-router-dom';

const RequestCatalog = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, reset } = useForm();

  const beauty_button_confirm = useRef(null);
  const ws = useRef(null);

  const cat = localStorage.getItem('cat');
  const mainCatID = localStorage.getItem('mainCatID');

  const [dropDownDop, setDropDownDop] = useState(false);
  const [time, setTime] = useState(false);
  const [openPrev, setOpenPrev] = useState(false);
  const [catID, setcatID] = useState('-1');
  const [Content, setContent] = useState(0);
  const [timeForCalendar, setTimeForCalendar] = useState('');
  const [catName, setcatName] = useState(cat == 'Космето-логия' ? 'Косметология' : cat);
  const [price, setprice] = useState(0);
  const [comm, setcomm] = useState('');

  const itemsAllServForClientMainReq = useSelector((state) => state.itemsAllServForClientMainReq);
  const profileBounds = useSelector((state) => state.profileBounds);

  const SlippedRecordsNum = useSelector((state) => state.SlippedRecordsNum);

  const itemsServForClientReq = useSelector((state) => state.itemsServForClientReq);
  const itemsServForClientReq_1 = useSelector((state) => state.itemsServForClientReq_1);
  const itemsServForClientReq_2 = useSelector((state) => state.itemsServForClientReq_2);
  const itemsServForClientReq_3 = useSelector((state) => state.itemsServForClientReq_3);
  const itemsServForClientReq_4 = useSelector((state) => state.itemsServForClientReq_4);
  const itemsServForClientReq_5 = useSelector((state) => state.itemsServForClientReq_5);

  const itemsServForClientReq_11 = useSelector((state) => state.itemsServForClientReq_11);
  const itemsServForClientReq_21 = useSelector((state) => state.itemsServForClientReq_21);
  const itemsServForClientReq_31 = useSelector((state) => state.itemsServForClientReq_31);
  const itemsServForClientReq_41 = useSelector((state) => state.itemsServForClientReq_41);
  const itemsServForClientReq_51 = useSelector((state) => state.itemsServForClientReq_51);

  const display_flex = useSelector((state) => state.DisplayType);
  const status = useSelector((state) => state.accountBeauty.status);

  const urlPath = window.location.pathname;
  const id = useSelector((state) => state.accountBeauty.id);

  const options = { month: 'long', day: 'numeric' };

  const [acceptDate, setAcceptDate] = useState(new Date().toISOString().slice(0, 10));
  const [acceptDefDate, setAcceptDefDate] = useState(
    new Date().toLocaleDateString('ru-ru', options),
  );

  useEffect(() => {
    if (itemsAllServForClientMainReq.length === 0) {
      getGeneral();
    }

    if (urlPath !== '/beauty/requests') {
      ws.current = new WebSocket('wss:/beauty.bikli.kz:2346'); // создаем ws соединение
      ws.current.onopen = () => {}; // callback на ивент открытия соединения
      ws.current.onclose = () => {}; // callback на ивент закрытия соединения

      return () => ws.current.close(); // кода меняется isPaused - соединение закрывается
    }
  }, []);

  let status_req = 'block';
  status == 0 ? (status_req = 'none') : (status_req = 'block');

  const callDay = (clikedDay) => {
    let day = dayjs(clikedDay).format('YYYY-MM-DD');
    setAcceptDate(dayjs(day).format('YYYY-MM-DD'));
    setAcceptDefDate(new Date(day).toLocaleDateString('ru-ru', options));
  };

  const makeUser = (id, prof) => {
    return {
      id: id,
      prof: prof,

      // ...другие свойства
    };
  };

  var arrObjects = [];
  const getGeneral = async () => {
    const responseAllServ = await beautyApi.fetchServicesInReq();
    for (let i = 0; i < responseAllServ.data.length; i++) {
      let user = makeUser(responseAllServ.data[i]['id'], responseAllServ.data[i]['prof']);
      arrObjects[i] = user;
    }
    dispatch(onAddAllServForClientMainReq(arrObjects));
  };

  // const hasOnlyDigits = (v) => /^\d+$/.test(v);

  const onSubmit = async (data) => {
    const obj = {
      ...data,
    };

    let timePart_1 = Number(timeForCalendar.split(':')[0]);
    let timePart_2 = Number(timeForCalendar.split(':')[1]);

    isNaN(timePart_1) ? (timePart_1 = -1) : (timePart_1 = timePart_1);
    isNaN(timePart_2) ? (timePart_2 = -1) : (timePart_2 = timePart_2);

    if (acceptDefDate == 'Дата') {
      wave_date_error();
    } else if (timeForCalendar == '') {
      wave_time_error();
    } else if (
      itemsServForClientReq_1 == '' &&
      itemsServForClientReq_2 == '' &&
      itemsServForClientReq_3 == '' &&
      itemsServForClientReq_4 == '' &&
      itemsServForClientReq_5 == ''
    ) {
      wave_dopServ_error();
    } else {
      if (timePart_1 == -1 || timePart_2 == -1) {
        timeForCalendar = '';
      }

      let itemsServForClientReqNew = mainCatID;

      if (itemsServForClientReq === '') {
        itemsServForClientReqNew = mainCatID;
      } else {
        itemsServForClientReqNew = parseInt(itemsServForClientReq) + 1;
      }

      if (status == 0) {
        setTimeout(SetTimeT, 0);
        setTimeout(SetTimeF, 5000);

        let beauty_button_confirm_pos = beauty_button_confirm.current.getBoundingClientRect();

        beauty_button_confirm.current.style.position = 'fixed';
        beauty_button_confirm.current.style.left = beauty_button_confirm_pos['x'] + 'px';
        beauty_button_confirm.current.style.top = beauty_button_confirm_pos['y'] + 'px';
        beauty_button_confirm.current.style.border = 'none';
        beauty_button_confirm.current.style.zIndex = 32767;

        let profileBoundsPos = profileBounds.current.getBoundingClientRect();
        let start_x = beauty_button_confirm_pos['x'] + 0.5 * beauty_button_confirm_pos['width'];
        let start_y = beauty_button_confirm_pos['y'] + 0.5 * beauty_button_confirm_pos['height'];
        let delta_x = profileBoundsPos['x'] + 0.5 * profileBoundsPos['width'] - start_x;
        let delta_y = profileBoundsPos['y'] + 0.5 * profileBoundsPos['height'] - start_y;

        beauty_button_confirm.current.style.transform = 'translateX(' + delta_x + 'px)';
        beauty_button_confirm.current.style.transform += 'translateY(' + delta_y + 'px)';
        beauty_button_confirm.current.style.transform += 'scale(0.0001)'; // уменьшаем до 25%
        beauty_button_confirm.current.style.transition = '1s'; // всё происходит за 1 секунду

        dispatch(setSlippedRecordsNum(SlippedRecordsNum + 1));

        const fd = new FormData();
        // await objToFd(fd, obj);
        await fd.append('price', price);
        await fd.append('comm', comm);
        await fd.append('date', acceptDate);
        await fd.append('time', timeForCalendar);
        await fd.append('clientId', id);
        await fd.append('order', itemsServForClientReqNew);
        await fd.append('order1', itemsServForClientReq_1);
        await fd.append('order2', itemsServForClientReq_2);
        await fd.append('order3', itemsServForClientReq_3);
        await fd.append('order4', itemsServForClientReq_4);
        await fd.append('order5', itemsServForClientReq_5);
        await fd.append('order11', itemsServForClientReq_11);
        await fd.append('order21', itemsServForClientReq_21);
        await fd.append('order31', itemsServForClientReq_31);
        await fd.append('order41', itemsServForClientReq_41);
        await fd.append('order51', itemsServForClientReq_51);
        await beautyApi.ClientService(fd);

        const fdd = new FormData();
        await fdd.append('cat_name', catName);
        await fdd.append('order1', itemsServForClientReq_1);
        await fdd.append('order2', itemsServForClientReq_2);
        await fdd.append('order3', itemsServForClientReq_3);
        await fdd.append('order4', itemsServForClientReq_4);
        await fdd.append('order5', itemsServForClientReq_5);
        await fdd.append('order11', itemsServForClientReq_11);
        await fdd.append('order21', itemsServForClientReq_21);
        await fdd.append('order31', itemsServForClientReq_31);
        await fdd.append('order41', itemsServForClientReq_41);
        await fdd.append('order51', itemsServForClientReq_51);
        // await beautyApi.pushN(fdd);

        await reset();
        await dispatch(DopDisplayType('block'));
        await setDropDownDop(!dropDownDop);
        wave_success();
        ws.current.send('send');

        dispatch(onAddServForClientReq_1(''));
        dispatch(onAddServForClientReq_2(''));
        dispatch(onAddServForClientReq_3(''));
        dispatch(onAddServForClientReq_4(''));
        dispatch(onAddServForClientReq_5(''));

        dispatch(onAddServForClientReq_11(''));
        dispatch(onAddServForClientReq_21(''));
        dispatch(onAddServForClientReq_31(''));
        dispatch(onAddServForClientReq_41(''));
        dispatch(onAddServForClientReq_51(''));

        setTimeForCalendar('');
        setOpenPrev(false);
        setprice(0);
        setcomm('');

        setTimeout(() => (beauty_button_confirm.current.style.display = 'none'), 3200);
        setTimeout(() => (beauty_button_confirm.current.style.transition = 'none'), 3400);
        setTimeout(() => (beauty_button_confirm.current.style.transform = 'none'), 3410);
        setTimeout(() => (beauty_button_confirm.current.style.display = 'block'), 3620);
        setTimeout(() => (beauty_button_confirm.current.style.position = 'initial'), 3600);

        // beauty_button_confirm.current.style.transform = 'none'; // уменьшаем до 25%
        // beauty_button_confirm.current.style.transition = '3s';
      } else {
        wave_error();
        // setreqPrice(data.price)
        dispatch(setauthDisplay('block'));

        let itemsServForClientReqNew = parseInt(itemsServForClientReq) + 1;
        localStorage.setItem('itemsServForClientReqNew', itemsServForClientReqNew);
        localStorage.setItem('SavedCatName', catName);

        localStorage.setItem('setauthDisplay', 'block');

        localStorage.setItem('onSaveAndAddServForClientReq', true);

        localStorage.setItem('onSaveAndAddServForClientReq_1', itemsServForClientReq_1);
        localStorage.setItem('onSaveAndAddServForClientReq_2', itemsServForClientReq_2);
        localStorage.setItem('onSaveAndAddServForClientReq_3', itemsServForClientReq_3);
        localStorage.setItem('onSaveAndAddServForClientReq_4', itemsServForClientReq_4);
        localStorage.setItem('onSaveAndAddServForClientReq_5', itemsServForClientReq_5);

        localStorage.setItem('onSaveAndAddServForClientReq_11', itemsServForClientReq_11);
        localStorage.setItem('onSaveAndAddServForClientReq_21', itemsServForClientReq_21);
        localStorage.setItem('onSaveAndAddServForClientReq_31', itemsServForClientReq_31);
        localStorage.setItem('onSaveAndAddServForClientReq_41', itemsServForClientReq_41);
        localStorage.setItem('onSaveAndAddServForClientReq_51', itemsServForClientReq_51);

        localStorage.setItem('onSaveAndAddServForClientDate', acceptDate);
        localStorage.setItem('onSaveAndAddServForClientTime', timeForCalendar);
        localStorage.setItem('onSaveAndAddServForClientPrice', price);

        history.push('/beauty/profile');
      }
    }
  };

  const SetTimeT = () => {
    setTime(true);
  };
  const SetTimeF = () => {
    setTime(false);
  };

  const wave_error = () => {
    toast('Вы не авторизированы', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_date_error = () => {
    toast('Пожалуйста выберите дату', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_time_error = () => {
    toast('Пожалуйста выберите время', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_dopServ_error = () => {
    toast('Пожалуйста выберите как минимум одну услугу', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_success = () => {
    toast('Ваша заявка успешно принята!', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
    });
  };

  const handleNumber = (event) => {
    setcatID(event.target.value);
    dispatch(onAddServForClientReq(event.target.value));

    itemsAllServForClientMainReq.map((x, y) =>
      event.target.value == y ? setcatName(x.prof) : null,
    );

    dispatch(onAddServForClientReq_1(''));
    dispatch(onAddServForClientReq_2(''));
    dispatch(onAddServForClientReq_3(''));
    dispatch(onAddServForClientReq_4(''));
    dispatch(onAddServForClientReq_5(''));

    dispatch(onAddServForClientReq_11(''));
    dispatch(onAddServForClientReq_21(''));
    dispatch(onAddServForClientReq_31(''));
    dispatch(onAddServForClientReq_41(''));
    dispatch(onAddServForClientReq_51(''));

    setOpenPrev(true);
  };

  let newcatID = parseInt(catID) + 1;

  return (
    <>
      <div className={s.modal_aut} style={{ display: `${status_req}` }}>
        <p>Для отправки заявки необходимо авторизироваться</p>
      </div>
      <ToastContainer position='top-center' theme='light' limit={4} />
      <div
        style={{
          display: Content == 1 ? 'block' : 'none',
          marginBottom: '150px',
          textAlign: 'center',
        }}
      >
        <Calendar
          onClickDay={(value) => callDay(value)}
          minDetail='month'
          next2Label={null}
          prev2Label={null}
        />

        <button
          style={{ marginTop: '15px' }}
          onClick={() => setContent(0)}
          className={s.beauty_button_confirm}
        >
          Ок
        </button>
      </div>

      <div style={{ display: Content == 0 ? 'block' : 'none' }}>
        {/* <form method='post' onSubmit={handleSubmit(onSubmit)}> */}
        <div className={s.modal} ref={beauty_button_confirm}>
          <div className={s.content}>
            <select className={s.dropdown_serv} onChange={(e) => handleNumber(e)}>
              <option defaultValue='defaultValue'>{catName}</option>
              {itemsAllServForClientMainReq.map((x, y) => (
                <option key={x.prof} value={y}>
                  {x.prof}
                </option>
              ))}
            </select>

            <ChildSelectModalPage catID={newcatID} mainCatID={mainCatID} openPrev={openPrev} />
            <div className={s.dateTimeWrapper}>
              <div className={s.dropdown_date3}>
                <div
                  onClick={() => {
                    setContent(1);
                  }}
                  style={{ marginTop: '9px' }}
                >
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      // marginTop: '10px',
                    }}
                  >
                    {acceptDefDate}
                  </p>
                </div>
              </div>
              <div className={s.input_time3}>
                <input
                  type='time'
                  placeholder='09:00'
                  value={timeForCalendar}
                  // className={s.inputTime}
                  onChange={(e) => setTimeForCalendar(e.target.value)}
                />
              </div>
            </div>

            <div className={s.input_label} style={{ display: `${display_flex}` }}>
              <input
                {...register('price')}
                type='number'
                placeholder='Предложить цену'
                onChange={(e) => setprice(e.target.value)}
              />
            </div>

            <div className={s.input_label} style={{ display: `${display_flex}` }}>
              <input
                {...register('comm')}
                type='text'
                placeholder='Добавить комментарий'
                onChange={(e) => setcomm(e.target.value)}
              />
            </div>
          </div>

          {/* <img
            style={{ width: '20px', position: 'fixed' }}
            src={'https://bshop.kz/beauty/AvatarPhotos/0.png'}
            alt='logo'
            // ref={beauty_button_confirm}
          /> */}

          <button
            disabled={time ? true : false}
            onClick={() => onSubmit()}
            className={s.beauty_button_confirm}
            style={{ opacity: time ? 0.3 : 1 }}
          >
            Отправить
          </button>
        </div>

        {/* </form> */}
      </div>
    </>
  );
};

export default RequestCatalog;
