import React, { useEffect, useRef, useState } from 'react';
import s from './req.module.css';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import objToFd from '../../../common/helpers/objToFd';
import beautyApi from '../../../api/beautyApi';
import { useSelector, useDispatch } from 'react-redux';
import { DisplayType, DopDisplayType, onAddMasterForClientInfo } from '../../../redux/action';
import { useHistory } from 'react-router-dom';

const AcceptModulforClient = (props) => {

    const {
        register,
        handleSubmit,
        reset
      } = useForm();
  
      const [dropDownDop, setDropDownDop] = useState(false);
      const [acceptDate, setAcceptDate] = useState(props.date);
      const dispatch = useDispatch();
      const masterId = useSelector((state) => state.accountBeauty.id);
      const WebSocketConnection = useSelector((state) => state.WebSocketConnection);
      const history = useHistory();

      const WSCSend = props.WSCSend
 
      const ws = useRef(null);
      const urlPath = window.location.pathname;
      // ws.current = new WebSocket('wss:/beauty.bikli.kz:2346'); // создаем ws соединение
      const options = { weekday: 'long',  month: 'long', day: 'numeric' };
      const day = new Date(props.date);

      const  handleAccept = async () => {
        const response = await beautyApi.AcceptMaster(props.id, props.MasterID, props.price, props.orderID, 1);
        let message = 'ClientAccept,' + props.MasterID
        WSCSend(message)
        const pushResponse = await beautyApi.pushCompletedClient(props.MasterID);
      };

      const  handleNotAccept = async () => {
        const response = await beautyApi.AcceptMaster(props.id, props.MasterID, props.price, props.orderID, 0);
        let message = 'ClientRefused,' + props.MasterID
        WSCSend(message)
      };


      // useEffect(() => {
        
      //     ws.current = new WebSocket('wss:/beauty.bikli.kz:2346'); // создаем ws соединение
      //     ws.current.onopen = () => {
      //       console.log('Соединение открыто');
      //       // dispatch(onWsCon(true));
      //     }; // callback на ивент открытия соединения
      //     ws.current.onclose = () => {
      //       console.log('Соединение закрыто');
      //       // dispatch(onWsCon(false));
      //     }; // callback на ивент закрытия соединения
    
      //     return () => ws.current.close(); // кода меняется isPaused - соединение закрывается

      // }, []);



    const onSubmit = async (data) => {
        const obj = {
            ...data
        };

        obj.order == '' ?  obj.order = props.orderName : obj.order = obj.order;
        obj.time == 'Время' ?  obj.time = props.time : obj.time = obj.time;
        obj.price == '' ?  obj.price = props.price : obj.price = obj.price;
        obj.comm == '' ?  obj.comm = 'Мастер не оставил комментария' : obj.comm = obj.comm;

        const fd = new FormData();
        await objToFd(fd, obj);
        await fd.append('date', acceptDate);
        await fd.append('orderId', props.id);
        await fd.append('clientId', masterId);

        beautyApi.MasterReqAccept(fd);
        reset();
        dispatch(DopDisplayType('block'));
        setDropDownDop(!dropDownDop);
        };

        const toItem = () => {
          history.push(`/beauty/master?id=${props.MasterID}`);
        };

  return (
    <Popup
    trigger={<button className={s.dropdown_accept}>Подробнее</button>}
    modal
    nested
  >
    {close => (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <div className={s.modal}>
        <button className={s.close} onClick={close}>
          &times;
        </button>
        <div className={s.header}>Заявка {props.orderID}</div>
        <div className={s.content}>
                <p>Услуги:</p>
                <p>{props.orderName}</p>
                <p>{props.orderName2}</p>
                <p>{props.orderName3}</p>
                <p>{props.orderName4}</p>
                <p>{props.orderName5}</p>
                <p><br /></p>
                <p>Дата: {day.toLocaleDateString("ru-ru", options)}</p>
                <p>Время: {props.time}</p>
                <p><br /></p>
                <p>Цена: {props.price} тнг.</p>
                <p><br /></p>
                <p>О мастере:</p>
                <p><br /></p>
                <div onClick={toItem}>
                <div className={s.item_masterA}>
      <div className={s.item_beauty_master_image}>
      <img
          src={
            props.img && props.img !== ''
              ? 'https://bshop.kz/beauty/AvatarPhotos/' + props.img
              : 'https://bshop.kz/beauty/AvatarPhotos/0.png'
          }
          alt="logo"
        />
      </div>

    <div className={s.item_beauty_master_name}>{props.FIO}</div>
    <div className={s.master_short_inf_salon}><p>Салон: {props.SalonName}</p></div>
    <div className={s.master_short_inf}>
        <div className={s.subscrib}>
        <svg 
        className={s.svg_icon}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
            <path fill="#404246" d="M10,10.9c2.373,0,4.303-1.932,4.303-4.306c0-2.372-1.93-4.302-4.303-4.302S5.696,4.223,5.696,6.594C5.696,8.969,7.627,10.9,10,10.9z M10,3.331c1.801,0,3.266,1.463,3.266,3.263c0,1.802-1.465,3.267-3.266,3.267c-1.8,0-3.265-1.465-3.265-3.267C6.735,4.794,8.2,3.331,10,3.331z" />
            <path fill="#404246" d="M10,12.503c-4.418,0-7.878,2.058-7.878,4.685c0,0.288,0.231,0.52,0.52,0.52c0.287,0,0.519-0.231,0.519-0.52c0-1.976,3.132-3.646,6.84-3.646c3.707,0,6.838,1.671,6.838,3.646c0,0.288,0.234,0.52,0.521,0.52s0.52-0.231,0.52-0.52C17.879,14.561,14.418,12.503,10,12.503z"></path>
        </svg>
        {12}+
        </div>
        
        <div className={s.completed}>
        <svg 
        className={s.svg_icon}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
            <path fill="#404246" d="M10.219,1.688c-4.471,0-8.094,3.623-8.094,8.094s3.623,8.094,8.094,8.094s8.094-3.623,8.094-8.094S14.689,1.688,10.219,1.688 M10.219,17.022c-3.994,0-7.242-3.247-7.242-7.241c0-3.994,3.248-7.242,7.242-7.242c3.994,0,7.241,3.248,7.241,7.242C17.46,13.775,14.213,17.022,10.219,17.022 M15.099,7.03c-0.167-0.167-0.438-0.167-0.604,0.002L9.062,12.48l-2.269-2.277c-0.166-0.167-0.437-0.167-0.603,0c-0.166,0.166-0.168,0.437-0.002,0.603l2.573,2.578c0.079,0.08,0.188,0.125,0.3,0.125s0.222-0.045,0.303-0.125l5.736-5.751C15.268,7.466,15.265,7.196,15.099,7.03"></path>
        </svg>
        {12}
        </div>
        
        <div className={s.rating}>
        <svg 
        className={s.svg_icon}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path fill="#404246" d="M12.173,16.086c0.72,0,1.304-0.584,1.304-1.305V6.089c0-0.72-0.584-1.304-1.304-1.304c-0.721,0-1.305,0.584-1.305,1.304v8.692C10.868,15.502,11.452,16.086,12.173,16.086z M11.738,6.089c0-0.24,0.194-0.435,0.435-0.435s0.435,0.194,0.435,0.435v8.692c0,0.24-0.194,0.436-0.435,0.436s-0.435-0.195-0.435-0.436V6.089zM16.52,16.086c0.72,0,1.304-0.584,1.304-1.305v-11.3c0-0.72-0.584-1.304-1.304-1.304c-0.721,0-1.305,0.584-1.305,1.304v11.3C15.215,15.502,15.799,16.086,16.52,16.086z M16.085,3.481c0-0.24,0.194-0.435,0.435-0.435s0.435,0.195,0.435,0.435v11.3c0,0.24-0.194,0.436-0.435,0.436s-0.435-0.195-0.435-0.436V3.481z M3.48,16.086c0.72,0,1.304-0.584,1.304-1.305v-3.477c0-0.72-0.584-1.304-1.304-1.304c-0.72,0-1.304,0.584-1.304,1.304v3.477C2.176,15.502,2.76,16.086,3.48,16.086z M3.045,11.305c0-0.24,0.194-0.435,0.435-0.435c0.24,0,0.435,0.194,0.435,0.435v3.477c0,0.24-0.195,0.436-0.435,0.436c-0.24,0-0.435-0.195-0.435-0.436V11.305z M18.258,16.955H1.741c-0.24,0-0.435,0.194-0.435,0.435s0.194,0.435,0.435,0.435h16.517c0.24,0,0.435-0.194,0.435-0.435S18.498,16.955,18.258,16.955z M7.826,16.086c0.72,0,1.304-0.584,1.304-1.305V8.696c0-0.72-0.584-1.304-1.304-1.304S6.522,7.977,6.522,8.696v6.085C6.522,15.502,7.106,16.086,7.826,16.086z M7.392,8.696c0-0.239,0.194-0.435,0.435-0.435s0.435,0.195,0.435,0.435v6.085c0,0.24-0.194,0.436-0.435,0.436s-0.435-0.195-0.435-0.436V8.696z"></path>        
        </svg>
        {12}
        </div>
    </div>
    </div>
                </div>
                
        </div>
            
           
  
        <span className={s.beauty_button1} onClick={() => {handleAccept(); close();}} >Принять</span>
        <span className={s.beauty_button2} onClick={() => {handleNotAccept(); close();}}>Отклонить</span>
      </div>
      
      </form>
    )}
  </Popup>
)
       
  

};

export default AcceptModulforClient;
