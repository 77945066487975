import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ModalMenu from '../modal-menu';

import './style.css';

import menu_icon from '../../asseets/menu.png';
import basket from '../../asseets/shop.png';
import arrow from '../../asseets/arrow-bottom.png';

class Header extends Component {
  state = {
    showMenu: false,

    urlNow: null,
  };

  showMenu = () => this.setState({ showMenu: !this.state.showMenu });

  closeModalEvent = (e) => {
    if (e === 'main') this.setState({ showMenu: !this.state.showMenu });
  };

  closeMenuWithDelLS = () => {
    localStorage.removeItem('access_token');
    this.setState({ showMenu: !this.state.showMenu });
  };

  render() {
    const urlPath = window.location.pathname === '/main' ? '/' : '/main';
    // console.log(window.location.pathname);
    return (
      <div className="header">
        {this.state.showMenu ? (
          <ModalMenu
            closeMenu={this.showMenu}
            closeModalEvent={this.closeModalEvent}
            closeMenuWithDelLS={this.closeMenuWithDelLS}
          />
        ) : null}
        {/* <ModalMenu closeMenu={this.showMenu} closeModalEvent={this.closeModalEvent} /> */}
        <div className="header-wrapper">
          <div className="header-log-prevBtn">
            <Link to={urlPath}>
              <img src={arrow} alt="prev" className="header-prevBtn" />
            </Link>
            <Link to="/main">BIKLI-FOOD</Link>
          </div>
          <div className="header-icons">
            {this.props.backetCount ? (
              <Link to="/backet" className="basket-logo">
                <img src={basket} alt="shop" className="basket-icon" />
                <span>{this.props.backetCount}</span>
              </Link>
            ) : null}
            <div className="menu-logo" onClick={this.showMenu}>
              <img src={menu_icon} alt="menu" className="menu-icon" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
