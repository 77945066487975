import React, { useEffect, useState } from 'react';
import s from '../pageMaster.module.css';
import { useSelector, useDispatch } from 'react-redux';

import beautyApi from '../../../api/beautyApi';

import { setSortedDateTime } from '../../../redux/action';

import PageMasterRecordDatesTimeList from './PageMasterRecordDatesTimeList';

const PageMasterRecordDates = ({ masterID, handleTimeSelect, handleDateSelect }) => {
  const dispatch = useDispatch();

  const [freeDates, setfreeDates] = useState();
  const [timeButtonId, settimeButtonId] = useState('');
  const [selectedDate, setselectedDate] = useState('today');

  const [todayDate, settodayDate] = useState(new Date().toISOString().slice(0, 10));
  const [DateTommorow, setDatetommorow] = useState(
    new Date(new Date().getTime() + 86400000).toISOString().slice(0, 10),
  );
  const SortedDateTime = useSelector((state) => state.SortedDateTime);

  var arrObjects1 = [];

  const ClickCssSet = {
    background: 'linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%)',
    border: 'none',
    color: 'aliceblue',
  };

  useEffect(() => {
    getResponse();
  }, []);

  const getResponse = async () => {
    const data = await getDataMaster();
    setfreeDates(data);
    SortData(data);
  };

  const getDataMaster = async () => {
    const fd = new FormData();
    fd.append('idMaster', masterID);
    // fd.append('date', '2022-10-11');
    const response = await beautyApi.pullDate(fd);
    const data = response.json();
    return data;
  };

  const SortData = (data) => {
    data.map((x, y) => {
      let switcher1 = 0;

      let dataItem1 = make_GeneralDateList(x.id, x.idMaster, x.date);

      arrObjects1.map((z, c) =>
        x.date == z.date ? (switcher1 = 1) : x.date == '-' ? (switcher1 = 1) : console.info(),
      );

      switcher1 == 0 ? (arrObjects1[y] = dataItem1) : console.info();
    });

    arrObjects1.map((x, y) => {
      var arrObjects2 = [];
      let dataItem2 = {};

      data.map((z, c) => {
        let switcher2 = 0;

        z.date == x.date ? (dataItem2 = make_TimeList(z.time, z.status)) : console.info();

        arrObjects2.map((x, y) => (x.time == z.time ? (switcher2 = 1) : console.info()));

        switcher2 == 0
          ? z.date == x.date
            ? (arrObjects2[c] = dataItem2)
            : console.info()
          : console.info();
      });

      let dataItem3 = make_DateTimeList(x.date, arrObjects2);
      arrObjects1[y].date = dataItem3;
    });
    dispatch(setSortedDateTime(arrObjects1));
  };

  const handleTimeClick = (time) => {
    handleTimeSelect(time);
    settimeButtonId(time);
  };

  const handleTodayClick = () => {
    setselectedDate('today');
    handleDateSelect(todayDate);

    handleTimeSelect('Не выбрано');
    settimeButtonId('Не выбрано');
  };

  const handleTommorowClick = () => {
    setselectedDate('tommorow');
    handleDateSelect(DateTommorow);

    handleTimeSelect('Не выбрано');
    settimeButtonId('Не выбрано');
  };

  const handleDateClick = (date) => {
    setselectedDate(date.target.value);
    handleDateSelect(date.target.value);

    handleTimeSelect('Не выбрано');
    settimeButtonId('Не выбрано');
  };

  const make_GeneralDateList = (id, idMaster, date) => {
    return {
      id: id,
      idMaster: idMaster,
      date: date,
    };
  };

  const make_DateTimeList = (date, time) => {
    return {
      date: date,
      time: time,
    };
  };

  const make_TimeList = (time, time_status) => {
    return {
      time: time,
      time_status: time_status,
    };
  };

  return (
    <>
      <div className={s.DatesGroup}>
        <div
          className={s.today}
          onClick={(e) => {
            handleTodayClick();
          }}
          style={selectedDate == 'today' ? ClickCssSet : null}
        >
          Сегодня
        </div>

        <div
          className={s.tommorow}
          onClick={(e) => {
            handleTommorowClick();
          }}
          style={selectedDate == 'tommorow' ? ClickCssSet : null}
        >
          Завтра
        </div>

        <div className={s.DatesList}>
          <select
            style={{ margin: 'auto', marginBottom: '20px' }}
            className={s.dropdown_date}
            onChange={(e) => handleDateClick(e)}
          >
            <option defaultValue='defaultValue'>
              {selectedDate == 'today'
                ? 'Дата'
                : selectedDate == 'tommorow'
                ? 'Дата'
                : selectedDate}
            </option>
            {SortedDateTime?.map((x, y) => (
              <option key={y} value={x.date.date}>
                {x.date.date}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={s.timeButtonsList}>
        {SortedDateTime?.map((date, index) => {
          if (selectedDate == 'today') {
            if (date.date.date == todayDate) {
              return (
                <PageMasterRecordDatesTimeList
                  data={date.date.time}
                  timeButtonId={timeButtonId}
                  handleTimeClick={handleTimeClick}
                />
              );
            }
          } else if (selectedDate == 'tommorow') {
            if (date.date.date == DateTommorow) {
              return (
                <PageMasterRecordDatesTimeList
                  data={date.date.time}
                  timeButtonId={timeButtonId}
                  handleTimeClick={handleTimeClick}
                />
              );
            }
          } else {
            if (date.date.date == selectedDate) {
              return (
                <PageMasterRecordDatesTimeList
                  data={date.date.time}
                  timeButtonId={timeButtonId}
                  handleTimeClick={handleTimeClick}
                />
              );
            }
          }
        })}
      </div>
    </>
  );
};

export default PageMasterRecordDates;
