import React, { Component } from 'react';

import { connect } from 'react-redux'

import './style.css'


import HistoryItem from '../companents/history-item';

class History extends Component {

    async componentDidMount() {
        const user_id = Object.assign({}, ...this.props.user).id;
        console.log(user_id);
        const formData = new FormData();
        formData.append('user_id', user_id)
        formData.append('access_token', localStorage.getItem('access_token'))
        try {
            this.props.getData('history', 'hist.HistOrder', formData)
        } catch (error) {
            this.props.errorStatus()
        }
    }

    render() {
        return (

            <div className='history' >
                <div className='history-wrapper'>
                    <div className='history-text'>
                        <h3>История</h3>
                    </div>

                    <div className='history-items'>
                        {this.props.history.length
                            ? this.props.history.map(el => {
                                return (
                                    <HistoryItem el={el} key={el.id} />
                                )
                            })
                            : <p>Вы не сделали ни одни заказ</p>}
                    </div>
                </div>
            </div >

        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(History);



