import React from 'react';
import s from './favorites.module.css';
const Favorites = () => {
  return (
    <div className={s.cart_empty}>
      <img
        data-v-2493e0fd=""
        src="https://ouch-cdn2.icons8.com/rz9DWS7aLW_Pl2QRv8a1GZQIEsxtSiNU9OhwxAqwvVE/rs:fit:456:456/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvMTE1/LzkwMjIwYjMzLTg2/YTEtNGNlZS05YmNj/LTAxMjcyOGYwNjVh/OC5zdmc.png"
        alt="dev"
      />
      <p>Данный раздел находиться в разработке.</p>
    </div>
  );
};

export default Favorites;
