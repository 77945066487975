import React, { useCallback, useEffect, useRef, useState } from 'react';
import s from './header.module.css';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
// import CalendarCss from '../beauty/Calendar.css';

import Calendar from 'react-calendar';

import dayjs from 'dayjs';
import objToFd from '../../common/helpers/objToFd';
import beautyApi from '../../api/beautyApi';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Inputmask from 'inputmask';
import { useHistory } from 'react-router-dom';

import './popup.css';
import {
  setauthDisplay,
  DisplayType,
  DopDisplayType,
  onAddAllServForClientMainReq,
  onAddServForClientReq,
  onSaveAndAddServForClientReq,
  DisplayType1,
  DisplayType2,
  DisplayType3,
  DisplayType4,
  onAddServForClientReq_1,
  onAddServForClientReq_2,
  onAddServForClientReq_3,
  onAddServForClientReq_4,
  onAddServForClientReq_5,
  onAddServForClientReq_11,
  onAddServForClientReq_21,
  onAddServForClientReq_31,
  onAddServForClientReq_41,
  onAddServForClientReq_51,
  onAddAllServForClientReq,
  onAddAllSDServsForClientReq,
  setSlippedRecordsNum,
  onSaveAndAddServForClientReq_1,
  onSaveAndAddServForClientReq_2,
  onSaveAndAddServForClientReq_3,
  onSaveAndAddServForClientReq_4,
  onSaveAndAddServForClientReq_5,
  onSaveAndAddServForClientReq_11,
  onSaveAndAddServForClientReq_21,
  onSaveAndAddServForClientReq_31,
  onSaveAndAddServForClientReq_41,
  onSaveAndAddServForClientReq_51,
  onSaveAndAddServForClientPrice,
  onSaveAndAddServForClientTime,
  onSaveAndAddServForClientDate,
} from '../../redux/action';

import ChildSelectModal from './ChildSelectModal';
const ClientRequest = (props) => {
  const { register, handleSubmit, reset } = useForm();

  const [dropDownDate, setDropDownDate] = useState(false);
  const [dropDownDop, setDropDownDop] = useState(false);
  const inputP = useRef();
  const beauty_button_confirm = useRef(null);

  const [catID, setcatID] = useState('-1');
  const [catName, setcatName] = useState('Категория услуги');
  const options = { month: 'long', day: 'numeric' };
  const [Content, setContent] = useState(0);
  const [openPrev, setOpenPrev] = useState(false);
  const [time, setTime] = useState('');
  const [price, setprice] = useState(0);
  const [comm, setcomm] = useState('');

  const [acceptDate, setAcceptDate] = useState(new Date().toISOString().slice(0, 10));
  const [acceptDefDate, setAcceptDefDate] = useState(
    new Date().toLocaleDateString('ru-ru', options),
  );

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    dispatch(DisplayType1('none'));
    dispatch(DisplayType2('none'));
    dispatch(DisplayType3('none'));
    dispatch(DisplayType4('none'));

    dispatch(onAddServForClientReq_1(''));
    dispatch(onAddServForClientReq_2(''));
    dispatch(onAddServForClientReq_3(''));
    dispatch(onAddServForClientReq_4(''));
    dispatch(onAddServForClientReq_5(''));

    dispatch(onAddServForClientReq_11(''));
    dispatch(onAddServForClientReq_21(''));
    dispatch(onAddServForClientReq_31(''));
    dispatch(onAddServForClientReq_41(''));
    dispatch(onAddServForClientReq_51(''));

    dispatch(onAddAllServForClientReq([]));
    dispatch(onAddAllSDServsForClientReq([]));

    setcatID('-1');
    setcatName('Категория услуги');
    setOpenPrev(false);
    setTime('');
    setprice(0);
    setcomm('');
  };
  const history = useHistory();

  // new Date().toISOString().slice(0, 10)
  const dispatch = useDispatch();
  const profileBounds = useSelector((state) => state.profileBounds);
  const authDisplay = useSelector((state) => state.authDisplay);
  const itemsAllServForClientMainReq = useSelector((state) => state.itemsAllServForClientMainReq);

  const SlippedRecordsNum = useSelector((state) => state.SlippedRecordsNum);

  const itemsServForClientReq = useSelector((state) => state.itemsServForClientReq);
  const itemsServForClientReq_1 = useSelector((state) => state.itemsServForClientReq_1);
  const itemsServForClientReq_2 = useSelector((state) => state.itemsServForClientReq_2);
  const itemsServForClientReq_3 = useSelector((state) => state.itemsServForClientReq_3);
  const itemsServForClientReq_4 = useSelector((state) => state.itemsServForClientReq_4);
  const itemsServForClientReq_5 = useSelector((state) => state.itemsServForClientReq_5);

  const itemsServForClientReq_11 = useSelector((state) => state.itemsServForClientReq_11);
  const itemsServForClientReq_21 = useSelector((state) => state.itemsServForClientReq_21);
  const itemsServForClientReq_31 = useSelector((state) => state.itemsServForClientReq_31);
  const itemsServForClientReq_41 = useSelector((state) => state.itemsServForClientReq_41);
  const itemsServForClientReq_51 = useSelector((state) => state.itemsServForClientReq_51);

  const display_flex = useSelector((state) => state.DisplayType);
  const status = useSelector((state) => state.accountBeauty?.status);
  const ws = useRef(null);
  const urlPath = window.location.pathname;
  const id = useSelector((state) => state.accountBeauty?.id);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (itemsAllServForClientMainReq.length === 0) {
      getGeneral();
    }

    if (urlPath !== '/beauty/requests') {
      ws.current = new WebSocket('wss:/beauty.bikli.kz:2346'); // создаем ws соединение
      ws.current.onopen = () => {
        // console.log('Соединение открыто');
      }; // callback на ивент открытия соединения
      ws.current.onclose = () => {
        // console.log('Соединение закрыто');
      }; // callback на ивент закрытия соединения
      gettingData();
      return () => ws.current.close(); // кода меняется isPaused - соединение закрывается
    }
  }, []);

  const gettingData = useCallback(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      //подписка на получение данных по вебсокету
      const message = JSON.parse(e.data);
      // console.log( message[0].allOrder)

      if (message[0].MasterAccepted) {
        message[0].MasterAccepted[0].data.clientId === id && setData(message[0].MasterAccepted);
      } else if (message[0].masterAoR) {
        message[0].masterAoR[0].data.clientId === id && setData(message[0].masterAoR);
      }
      // console.log(message[0].MasterAccepted)
    };
  }, []);

  let status_req = 'block';
  status == 0 ? (status_req = 'none') : (status_req = 'block');
  //   setDropDownDate(false)
  //   setDropDownTime(false)

  const ReqSave = () => {
    // удалить в редаксе
    // dispatch(onSaveAndAddServForClientReq(true))

    // dispatch(onSaveAndAddServForClientReq_1(itemsServForClientReq_1))
    // dispatch(onSaveAndAddServForClientReq_2(itemsServForClientReq_2))
    // dispatch(onSaveAndAddServForClientReq_3(itemsServForClientReq_3))
    // dispatch(onSaveAndAddServForClientReq_4(itemsServForClientReq_4))
    // dispatch(onSaveAndAddServForClientReq_5(itemsServForClientReq_5))

    // dispatch(onSaveAndAddServForClientReq_11(itemsServForClientReq_11))
    // dispatch(onSaveAndAddServForClientReq_21(itemsServForClientReq_21))
    // dispatch(onSaveAndAddServForClientReq_31(itemsServForClientReq_31))
    // dispatch(onSaveAndAddServForClientReq_41(itemsServForClientReq_41))
    // dispatch(onSaveAndAddServForClientReq_51(itemsServForClientReq_51))

    // dispatch(onSaveAndAddServForClientDate(acceptDefDate))
    // dispatch(onSaveAndAddServForClientTime(time))
    // dispatch(onSaveAndAddServForClientPrice(reqPrice))

    closeModal();
    history.push('/beauty/profile');
  };

  const callDay = (clikedDay) => {
    // console.log(dayjs(clikedDay).format('YYYY-MM-DD'))
    let day = dayjs(clikedDay).format('YYYY-MM-DD');
    setAcceptDefDate(new Date(day).toLocaleDateString('ru-ru', options));
    setAcceptDate(dayjs(clikedDay).format('YYYY-MM-DD'));
  };

  const dateButton = (clikedDay) => {
    setDropDownDate(!dropDownDate);

    display_flex == 'flex' ? dispatch(DisplayType('none')) : dispatch(DisplayType('flex'));
  };

  const plusButton = () => {
    setDropDownDop(!dropDownDop);
    dispatch(DopDisplayType('none'));
  };

  const makeUser = (id, prof) => {
    return {
      id: id,
      prof: prof,

      // ...другие свойства
    };
  };

  var arrObjects = [];
  const getGeneral = async () => {
    const responseAllServ = await beautyApi.fetchServicesInReq();
    for (let i = 0; i < responseAllServ.data.length; i++) {
      let user = makeUser(responseAllServ.data[i]['id'], responseAllServ.data[i]['prof']);
      arrObjects[i] = user;
    }
    dispatch(onAddAllServForClientMainReq(arrObjects));
  };

  // const hasOnlyDigits = (v) => /^\d+$/.test(v);

  const onSubmit = async () => {
    // let timePart_1 = Number(time.split(':')[0]);
    // let timePart_2 = Number(time.split(':')[1]);

    // isNaN(timePart_1) ? (timePart_1 = -1) : (timePart_1 = timePart_1);
    // isNaN(timePart_2) ? (timePart_2 = -1) : (timePart_2 = timePart_2);

    if (acceptDefDate == 'Дата') {
      wave_date_error();
    } else if (time == '') {
      wave_time_error();
    } else if (
      itemsServForClientReq_1 == '' &&
      itemsServForClientReq_2 == '' &&
      itemsServForClientReq_3 == '' &&
      itemsServForClientReq_4 == '' &&
      itemsServForClientReq_5 == ''
    ) {
      wave_dopServ_error();
    } else {
      if (status == 0) {
        // setDisableSendButton(true);
        let itemsServForClientReqNew = parseInt(itemsServForClientReq) + 1;
        const fd = new FormData();
        // await objToFd(fd, obj);

        let beauty_button_confirm_pos = beauty_button_confirm.current.getBoundingClientRect();

        beauty_button_confirm.current.style.position = 'fixed';
        beauty_button_confirm.current.style.left = beauty_button_confirm_pos['x'] + 'px';
        beauty_button_confirm.current.style.top = beauty_button_confirm_pos['y'] + 'px';
        beauty_button_confirm.current.style.border = 'none';
        beauty_button_confirm.current.style.zIndex = 32767;

        let profileBoundsPos = profileBounds.current.getBoundingClientRect();
        let start_x = beauty_button_confirm_pos['x'] + 0.5 * beauty_button_confirm_pos['width'];
        let start_y = beauty_button_confirm_pos['y'] + 0.5 * beauty_button_confirm_pos['height'];
        let delta_x = profileBoundsPos['x'] + 0.5 * profileBoundsPos['width'] - start_x;
        let delta_y = profileBoundsPos['y'] + 0.5 * profileBoundsPos['height'] - start_y;

        beauty_button_confirm.current.style.transform = 'translateX(' + delta_x + 'px)';
        beauty_button_confirm.current.style.transform += 'translateY(' + delta_y + 'px)';
        beauty_button_confirm.current.style.transform += 'scale(0.0001)'; // уменьшаем до 25%
        beauty_button_confirm.current.style.transition = '1s'; // всё происходит за 1 секунду

        dispatch(setSlippedRecordsNum(SlippedRecordsNum + 1));
        await fd.append('price', price);
        await fd.append('comm', comm);
        await fd.append('date', acceptDate);
        await fd.append('time', time);
        await fd.append('clientId', id);
        await fd.append('order', itemsServForClientReqNew);
        await fd.append('order1', itemsServForClientReq_1);
        await fd.append('order2', itemsServForClientReq_2);
        await fd.append('order3', itemsServForClientReq_3);
        await fd.append('order4', itemsServForClientReq_4);
        await fd.append('order5', itemsServForClientReq_5);
        await fd.append('order11', itemsServForClientReq_11);
        await fd.append('order21', itemsServForClientReq_21);
        await fd.append('order31', itemsServForClientReq_31);
        await fd.append('order41', itemsServForClientReq_41);
        await fd.append('order51', itemsServForClientReq_51);
        await beautyApi.ClientService(fd);

        const fdd = new FormData();
        await fdd.append('cat_name', catName);
        await fdd.append('order1', itemsServForClientReq_1);
        await fdd.append('order2', itemsServForClientReq_2);
        await fdd.append('order3', itemsServForClientReq_3);
        await fdd.append('order4', itemsServForClientReq_4);
        await fdd.append('order5', itemsServForClientReq_5);
        await fdd.append('order11', itemsServForClientReq_11);
        await fdd.append('order21', itemsServForClientReq_21);
        await fdd.append('order31', itemsServForClientReq_31);
        await fdd.append('order41', itemsServForClientReq_41);
        await fdd.append('order51', itemsServForClientReq_51);
        // await beautyApi.pushN(fdd);

        await reset();
        await dispatch(DopDisplayType('block'));
        await setDropDownDop(!dropDownDop);
        // const fdd = new FormData();
        // await fdd.append('cat_name', 'Ресницы');
        // await beautyApi.clientServNotification(fdd);

        wave_success();
        ws.current.send('send');
        setTimeout(closeModal, 3000);

        dispatch(DisplayType1('none'));
        dispatch(DisplayType2('none'));
        dispatch(DisplayType3('none'));
        dispatch(DisplayType4('none'));

        // dispatch(DopDisplayType('block'));

        dispatch(onAddServForClientReq_1(''));
        dispatch(onAddServForClientReq_2(''));
        dispatch(onAddServForClientReq_3(''));
        dispatch(onAddServForClientReq_4(''));
        dispatch(onAddServForClientReq_5(''));

        dispatch(onAddServForClientReq_11(''));
        dispatch(onAddServForClientReq_21(''));
        dispatch(onAddServForClientReq_31(''));
        dispatch(onAddServForClientReq_41(''));
        dispatch(onAddServForClientReq_51(''));
      } else {
        wave_error();
        // setreqPrice(data.price)
        dispatch(setauthDisplay('block'));

        let itemsServForClientReqNew = parseInt(itemsServForClientReq) + 1;
        localStorage.setItem('itemsServForClientReqNew', itemsServForClientReqNew);
        localStorage.setItem('SavedCatName', catName);

        localStorage.setItem('setauthDisplay', 'block');

        localStorage.setItem('onSaveAndAddServForClientReq', true);

        localStorage.setItem('onSaveAndAddServForClientReq_1', itemsServForClientReq_1);
        localStorage.setItem('onSaveAndAddServForClientReq_2', itemsServForClientReq_2);
        localStorage.setItem('onSaveAndAddServForClientReq_3', itemsServForClientReq_3);
        localStorage.setItem('onSaveAndAddServForClientReq_4', itemsServForClientReq_4);
        localStorage.setItem('onSaveAndAddServForClientReq_5', itemsServForClientReq_5);

        localStorage.setItem('onSaveAndAddServForClientReq_11', itemsServForClientReq_11);
        localStorage.setItem('onSaveAndAddServForClientReq_21', itemsServForClientReq_21);
        localStorage.setItem('onSaveAndAddServForClientReq_31', itemsServForClientReq_31);
        localStorage.setItem('onSaveAndAddServForClientReq_41', itemsServForClientReq_41);
        localStorage.setItem('onSaveAndAddServForClientReq_51', itemsServForClientReq_51);

        localStorage.setItem('onSaveAndAddServForClientDate', acceptDefDate);
        localStorage.setItem('onSaveAndAddServForClientTime', time);
        localStorage.setItem('onSaveAndAddServForClientPrice', price);

        history.push('/beauty/profile');
      }

      // wave_success();
    }
  };

  const wave_error = () => {
    toast('Вы не авторизированы');
  };

  const wave_date_error = () => {
    toast('Пожалуйста, выберите дату', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_time_error = () => {
    toast('Пожалуйста, выберите время', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const wave_dopServ_error = () => {
    toast('Пожалуйста, выберите как минимум одну услугу', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
    });
  };

  const wave_success = () => {
    toast('Ваша заявка успешно принята!', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
    });
  };

  const handleNumber = (event) => {
    setcatID(event.target.value);

    dispatch(onAddServForClientReq(event.target.value));

    itemsAllServForClientMainReq.map((x, y) =>
      event.target.value == y ? setcatName(x.prof) : null,
    );

    dispatch(DisplayType1('none'));
    dispatch(DisplayType2('none'));
    dispatch(DisplayType3('none'));
    dispatch(DisplayType4('none'));

    dispatch(onAddServForClientReq_1(''));
    dispatch(onAddServForClientReq_2(''));
    dispatch(onAddServForClientReq_3(''));
    dispatch(onAddServForClientReq_4(''));
    dispatch(onAddServForClientReq_5(''));

    dispatch(onAddServForClientReq_11(''));
    dispatch(onAddServForClientReq_21(''));
    dispatch(onAddServForClientReq_31(''));
    dispatch(onAddServForClientReq_41(''));
    dispatch(onAddServForClientReq_51(''));

    setOpenPrev(true);
  };

  let newcatID = parseInt(catID) + 1;

  inputP.current && Inputmask({ mask: '99:99' }).mask(inputP.current);

  return (
    <>
      <button type='button' className={s.beauty_button} onClick={() => setOpen((o) => !o)}>
        Подать заявку
      </button>
      <Popup open={open} modal nested>
        <div className={s.modal} ref={beauty_button_confirm}>
          <button
            className={s.close_main}
            onClick={() => {
              closeModal();
            }}
          >
            &times;
          </button>

          <div className={s.header}> Заявка </div>
          <div className={s.content}>
            <div
              style={{
                display: Content == 1 ? 'block' : 'none',
                marginBottom: '150px',
                textAlign: 'center',
                marginTop: '-40px',
              }}
            >
              <Calendar
                minDetail='month'
                onClickDay={(value) => callDay(value)}
                //   className={CalendarCss}
                next2Label={null}
                prev2Label={null}
              />

              <span
                style={{
                  margin: 'auto',
                  marginTop: '15px',
                  display: 'block',
                  paddingTop: '5px',
                }}
                onClick={() => setContent(0)}
                className={s.beauty_button_confirm}
              >
                Ок
              </span>
            </div>

            <div style={{ display: Content == 0 ? 'block' : 'none' }}>
              <select className={s.dropdown_serv} onChange={(e) => handleNumber(e)}>
                <option defaultValue='defaultValue'>{catName}</option>
                {itemsAllServForClientMainReq.map((x, y) => (
                  <option key={x.prof} value={y}>
                    {x.prof}
                  </option>
                ))}
              </select>

              <ChildSelectModal catID={newcatID} openPrev={openPrev} />

              <div className={s.dateTimeWrapper}>
                <div className={s.dropdown_date3}>
                  <div
                    onClick={() => {
                      setContent(1);
                    }}
                    style={{ marginTop: '9px' }}
                  >
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        // marginTop: '10px',
                      }}
                    >
                      {acceptDefDate}
                    </p>
                  </div>
                </div>
                <div className={s.input_time3}>
                  <input
                    type='time'
                    placeholder='09:00'
                    value={time}
                    // className={s.inputTime}
                    onChange={(e) => setTime(e.target.value)}
                  />
                </div>
              </div>

              <div className={s.input_label} style={{ display: `${display_flex}` }}>
                <input
                  {...register('price')}
                  type='number'
                  placeholder='Предложить цену'
                  onChange={(e) => setprice(e.target.value)}
                />
              </div>

              <div className={s.input_label} style={{ display: `${display_flex}` }}>
                <input
                  {...register('comm')}
                  type='text'
                  placeholder='Добавить комментарий'
                  onChange={(e) => setcomm(e.target.value)}
                />
              </div>

              <button className={s.beauty_button_confirm} onClick={() => onSubmit()}>
                Отправить
              </button>
            </div>
            <a
              onClick={() => ReqSave()}
              style={{
                textDecoration: `none`,
                display: `${authDisplay}`,
                margin: 'auto',
                marginTop: '-3px',
                paddingTop: '7px',
              }}
              className={s.beauty_button_confirm}
            >
              Авторизация
            </a>
          </div>
        </div>
        <div className={s.modal_aut} style={{ display: `${status_req}` }}>
          <p>Для отправки заявки необходимо авторизироваться</p>
        </div>
        <ToastContainer theme='light' />
      </Popup>
    </>
  );
};

export default ClientRequest;
