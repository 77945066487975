import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import Inputmask from 'inputmask';
import uniqid from 'uniqid';

import s from './CartNew.module.css';

import { removeBasketBshop, addBasketBshop } from '../../redux/action';

import emptyCart from '../../companents/bshop/assets/empty-cart.png';
import ButtonOrange from '../../companents/bshop/buttonOrange/ButtonOrange';
import objToFd from '../../common/helpers/objToFd';
import OrderDoneModal from '../../companents/orderDoneModal/orderDoneModal';

import bshopApi from '../../api/bshopApi';

const CartNew = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const items = useSelector((state) => state.basketBshop);
  const [order, setOrder] = useState(true);
  const [delivery, setDelivery] = useState(false);
  const [pay, setPay] = useState(false);
  const pay_to_cart_bank = useRef();
  const pay_to_cash = useRef();
  const modalOpen = useRef();
  const inputP = useRef();
  const [inputPhone, setInputPhone] = useState('');

  const [typePayNow, setTypePayNow] = useState(false);
  const [typePayGet, setTypePayGet] = useState(false);

  const [responseOrder, setResponseOrder] = useState(false);

  const clickHeader = (open, close, closeTwo) => {
    close(false);
    closeTwo(false);
    open(true);
  };

  const delItem = (target) => {
    const obj = {
      nameItem: [target.dataset.name],
      id: [target.dataset.itemid],
      catId: [target.dataset.catid],
      shopId: [target.dataset.shopid],
    };
    dispatch(removeBasketBshop(obj));
  };

  const onSubmit = async (data) => {
    const obj = {
      ...data,
      phone: inputPhone,
      type_pay: typePayNow ? 'Оплата онлайн' : typePayGet && 'Оплата при получении',
      is_pay_now: pay_to_cash.current.checked
        ? pay_to_cash.current.value
        : pay_to_cart_bank.current.checked && pay_to_cart_bank.current.value,
    };

    const fd = new FormData();
    await objToFd(fd, obj);
    const itemsJSON = JSON.stringify(items);

    await fd.append('items', itemsJSON);
    await goToOrder(fd);
    setInputPhone('');
    reset();
  };

  const goToOrder = async (obj) => {
    setResponseOrder(!responseOrder);
    document.querySelector('#cart_of_bank').checked = false;
    document.querySelector('#cash').checked = false;
    document.querySelector('#upon_receipt').checked = false;
    document.querySelector('#pay_now').checked = false;
    setTypePayNow(false);
    setTypePayGet(false);
    dispatch(addBasketBshop(null));
    await bshopApi.toOrder(obj);
  };

  const onClickTypePay = (val_1, val_2) => {
    document.querySelector('#cart_of_bank').checked = false;
    document.querySelector('#cash').checked = false;

    setTypePayNow(val_2);
    setTypePayGet(val_1);
  };
  inputP.current && Inputmask({ mask: '+79999999999' }).mask(inputP.current);

  return (
    <div className={s.root}>
      <div className={s.title_cart}>Корзина</div>
      <div className={s.cart_header_btns}>
        <div
          className={order ? s.header_btn_active : s.header_btn}
          onClick={() => clickHeader(setOrder, setDelivery, setPay)}>
          <svg
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21 6V19H3V6M10 10H14M1 1H23V6H1V1Z"
              stroke={order ? '#404246' : '#A8A8A8'}
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div
          className={delivery ? s.header_btn_active : s.header_btn}
          onClick={() => clickHeader(setDelivery, setOrder, setPay)}>
          <svg
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16 14V1H1V14H16ZM16 14H23V9L20 6H16V14ZM8 16.5C8 17.8807 6.88071 19 5.5 19C4.11929 19 3 17.8807 3 16.5C3 15.1193 4.11929 14 5.5 14C6.88071 14 8 15.1193 8 16.5ZM21 16.5C21 17.8807 19.8807 19 18.5 19C17.1193 19 16 17.8807 16 16.5C16 15.1193 17.1193 14 18.5 14C19.8807 14 21 15.1193 21 16.5Z"
              stroke={delivery ? '#404246' : '#A8A8A8'}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div
          className={pay ? s.header_btn_active : s.header_btn}
          onClick={() => clickHeader(setPay, setDelivery, setOrder)}>
          <svg
            width="24"
            height="18"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 7H23M3 1H21C22.1046 1 23 1.89543 23 3V15C23 16.1046 22.1046 17 21 17H3C1.89543 17 1 16.1046 1 15V3C1 1.89543 1.89543 1 3 1Z"
              stroke={pay ? '#404246' : '#A8A8A8'}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      {order && (
        <>
          <div className={s.items_wrapper}>
            {items.length > 0 ? (
              items.map((item) => {
                return (
                  <div
                    key={uniqid()}
                    className={s.item}
                    data-shopid={item.shopId}
                    data-catid={item.catId}
                    data-itemid={item.id}>
                    <div className={s.item_img}>
                      <img
                        src={`https://www.bshop.kz/photos/${
                          item.photo === '' ? 'nophoto.jpg' : item.photo
                        }`}
                        alt="item"
                      />
                    </div>
                    <div className={s.item_content}>
                      <div className={s.item_title}>{item.nameItem}</div>
                      <div className={s.item_desc}>
                        <div className={s.item_detail}>
                          Размер:<div key={uniqid()}>{item.size}</div>
                        </div>
                        <div className={s.item_detail}>
                          Магазин: <div>{item.shopName}</div>
                        </div>
                        <div className={s.item_detail}>
                          Цена: <div>{item.price.toLocaleString('ru')} тг.</div>
                        </div>
                      </div>
                      <button
                        onClick={(e) => delItem(e.target)}
                        data-shopid={item.shopId}
                        data-catid={item.catId}
                        data-itemid={item.id}
                        data-name={item.nameItem}>
                        Удалить
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={s.cart_empty}>
                <h2>
                  Корзина пустая <i>😕</i>
                </h2>
                <p>
                  Вероятней всего, вы не добавили товары в корзину. Для того, чтобы добавить товары,
                  перейди в каталог.
                </p>
                <img src={emptyCart} alt="Empty cart" />
              </div>
            )}
          </div>
          {items.length > 0 && (
            <ButtonOrange>
              <div
                className={s.btnOformit}
                onClick={() => clickHeader(setDelivery, setOrder, setPay)}>
                <div>Оформить</div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {items.length} шт.,
                  <div style={{ marginLeft: '5px' }}>
                    {items
                      .reduce(
                        (sum, item) => (Number(item.price) >= 0 ? sum + Number(item.price) : null),
                        0,
                      )
                      .toLocaleString('ru')}
                    тг
                  </div>
                </div>
              </div>
            </ButtonOrange>
          )}
        </>
      )}

      <form method="post" className={s.form_delivery} onSubmit={handleSubmit(onSubmit)}>
        {delivery && (
          <>
            <div className={s.input_label}>
              <label>адрес доставки:</label>
              <input
                {...register('address', {
                  required: 'Поле обязательно к заполнению',
                })}
                type="text"
              />
              <div className={s.address}>
                {errors?.address && <span>{errors?.address?.message || 'Error!'}</span>}
              </div>
            </div>
            <div className={s.input_label}>
              <label>телефон:</label>
              <input
                ref={inputP}
                onChange={(e) => setInputPhone(e.target.value)}
                value={inputPhone}
                type="text"
              />
              <div className={s.errorForm}>
                {errors?.phone && <span>{errors?.phone?.message || 'Error!'}</span>}
              </div>
            </div>
            <div className={s.input_label}>
              <label>имя получателя:</label>
              <input
                {...register('name', {
                  required: 'Поле обязательно к заполнению',
                })}
                type="text"
              />
              <div className={s.errorForm}>
                {errors?.name && <span>{errors?.name?.message || 'Error!'}</span>}
              </div>
            </div>
            <div className={s.input_label}>
              <label>дополнительно:</label>
              <textarea
                type="text"
                name="additionaly"
                style={{ height: '112px' }}
                {...register('additionaly', {
                  required: 'Поле обязательно к заполнению',
                })}
              />
            </div>
            <div className={s.btn_back_next}>
              <div
                onClick={() => clickHeader(setOrder, setDelivery, setPay)}
                style={{ marginRight: '20px' }}>
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.0999 0.999999L1.0999 8L8.0999 15M17.8999 1L10.8999 8L17.8999 15"
                    stroke="#404246"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Назад</span>
              </div>

              <div
                style={{ fontWeight: 'bold', backgroundColor: '#FF7A00' }}
                onClick={() => clickHeader(setPay, setDelivery, setOrder)}>
                <span>Далее</span>
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.4001 15L18.4001 8L11.4001 1M1.6001 15L8.6001 8L1.6001 1"
                    stroke="#404246"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
        {pay && (
          <>
            <div className={s.input_label}>
              <label>Способ оплаты</label>
            </div>
            <div className={s.radio}>
              <input
                type="radio"
                name="radio"
                id="upon_receipt"
                value="0"
                onChange={() => onClickTypePay(true, false)}
              />
              <label htmlFor="upon_receipt">При получении:</label>
            </div>
            <div className={s.under_group_0}>
              <div className={s.radio}>
                <input
                  ref={pay_to_cart_bank}
                  disabled={!typePayGet ? 'disabled' : false}
                  type="radio"
                  id="cart_of_bank"
                  name="type_pay"
                  value="Карта банка"
                  style={{ marginLeft: '96px' }}
                />
                <label htmlFor="cart_of_bank">Карта банка:</label>
              </div>
              <div className={s.radio}>
                <input
                  ref={pay_to_cash}
                  disabled={!typePayGet ? 'disabled' : false}
                  type="radio"
                  id="cash"
                  value="Наличные"
                  name="type_pay"
                  style={{ marginLeft: '96px' }}
                />
                <label htmlFor="cash">Наличные:</label>
              </div>
            </div>
            <div className={s.radio} style={{ marginBottom: '17px' }}>
              <input
                disabled
                onChange={() => onClickTypePay(false, true)}
                type="radio"
                name="radio"
                id="pay_now"
                value="1"
              />
              <label htmlFor="pay_now">Оплатить сейчас:</label>
            </div>
            <div className={s.input_label}>
              <input
                disabled={!typePayNow ? 'disabled' : false}
                type="number"
                placeholder="номер карты"
                // {...register('number_card')}
              />
            </div>
            <div
              className={s.input_label}
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <input
                disabled={!typePayNow ? 'disabled' : false}
                type="number"
                placeholder="мм. гг."
                style={{ width: '45%' }}
                // {...register('date_card')}
              />
              <input
                disabled={!typePayNow ? 'disabled' : false}
                type="password"
                name="CVV"
                placeholder="CVV"
                style={{ width: '45%' }}
                // {...register('CVV')}
              />
            </div>
            <button>Оформить</button>
            <div className={s.btn_back_next} style={{ marginTop: '28px' }}>
              <div onClick={() => clickHeader(setDelivery, setOrder, setPay)}>
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.0999 0.999999L1.0999 8L8.0999 15M17.8999 1L10.8999 8L17.8999 15"
                    stroke="#404246"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Назад</span>
              </div>
            </div>
            {responseOrder && (
              <OrderDoneModal setResponseOrder={setResponseOrder} modalOpen={modalOpen} />
            )}
          </>
        )}
      </form>
    </div>
  );
};

export default CartNew;
