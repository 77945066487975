import { React, memo } from 'react';
import s from './slider_catalog.module.css';
import OneSlide from './OneSlide';
import picSlide1 from './picSlide1.jpg';
import picSlide2 from './picSlide2.jpeg';
import picSlide3 from './picSlide3.jpg';
import picSlide4 from './picSlide4.jpg';
import picSlide5 from './picSlide5.jpg';
import { Carousel } from 'react-responsive-carousel';
import uniqid from 'uniqid';

const BeautyMastersChooseSlider = () => {
  const slides = [
    <OneSlide picSlide={picSlide1} />,
    <OneSlide picSlide={picSlide2} />,
    <OneSlide picSlide={picSlide3} />,
    <OneSlide picSlide={picSlide4} />,
    <OneSlide picSlide={picSlide5} />,
  ];
  return (
    <div className={s.root}>
      <div className={s.wrapper_slider}>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay={true}
          interval={3000}
          infiniteLoop={true}>
          {slides?.map((item, i) => (
            <div key={uniqid()}>{item}</div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default memo(BeautyMastersChooseSlider);