import React, { useEffect, useState } from 'react';
import s from '../pageMaster.module.css';

const PageMasterRecordDatesTimeList = ({ data, timeButtonId, handleTimeClick }) => {
  const ClickCssSet = {
    background: 'linear-gradient(266.64deg, #d432d8 -0.07%, #ff608d 51.49%, #f4a582 99.93%)',
    border: 'none',
    color: 'aliceblue',
  };

  const notClickCssSet = {
    background: '#0e0e0e26',
    border: 'none',
    color: 'aliceblue',
  };

  return (
    <>
      {data.map((x, y) => {
        return x.time_status == '0' ? (
          <div
            id={x.time}
            style={x.time == timeButtonId ? ClickCssSet : null}
            className={s.timeButton}
            onClick={(e) => {
              handleTimeClick(x.time);
            }}
          >
            {x.time}
          </div>
        ) : (
          <div
            id={x.time}
            style={notClickCssSet}
            className={s.timeButton}
            // onClick={(e) => {
            //   handleTimeClick(x.time);
            // }}
          >
            {x.time}
          </div>
        );
      })}
    </>
  );
};

export default PageMasterRecordDatesTimeList;
