import React, { Component } from 'react';

import './style.css'

class SearchInput extends Component {
    render() {
        return (
            <div className='input'>
                <input type='text' placeholder='Поиск' autoComplete='off' onChange={(e) => this.props.searchValue(e)} />
            </div>
        );
    }
}

export default SearchInput;