import React, { Component } from 'react';

import records from '../asseets/dost-page.png'

class Recorcds extends Component {
    render() {
        return (
            <div className='records'>
                <div className='records-wrapper'>
                    <div className='records-text'>
                        <h3>Достижения</h3>
                    </div>

                    <div className='recodrs-content'>
                        <div className='block-bonus'>
                            <p>Накоплено</p>
                            <h3>500 бонусов</h3>
                        </div>

                        <h2>МОИ НАГРАДЫ</h2>

                        <div className='items-trophy'>

                            <div className='trophy'>
                                <img src={records} alt='trophy' />
                                <div className='line-trophy'></div>
                                <p>Описание</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Recorcds;