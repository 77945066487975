import React, { useState } from 'react';
import s from './req.module.css';
import uniqid from 'uniqid';
import Popup from 'reactjs-popup';
import AcceptModulforClient from './AcceptModulforClient';
import ReviewModulforClient from './ReviewModulforClient';
import Comm from '../../../asseets/Frame 348.png';
import Comm2 from '../../../asseets/Frame 349.png';

const MyAcceptedRequestForClient = ({
  WSCSend,
  orderId,
  FIO,
  date,
  id,
  orderName,
  orderName2,
  orderName3,
  orderName4,
  orderName5,
  phome,
  price,
  status,
  time,
  comm,
  img,
  clientId,
  MasterID,
  SalonName,
  Adress,
  Email,
  Phone,
}) => {
  const [dropDownReqAccept, setDropDownReqAccept] = useState(false);
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  const day = new Date(date);
  const WSCSend2 = WSCSend;

  let backgroundImage =
    'linear-gradient(white, white), linear-gradient(to right, #edcd73, #ff015f)';
  let backgroundOrigin = 'border-box';
  let backgroundClip = 'padding-box, border-box';
  //   status === '0' ? backgroundImage = 'linear-gradient(white, white), linear-gradient(to right, #edcd73, #ff015f)'  :
  status === '0'
    ? (backgroundImage =
        'linear-gradient(#f3f3f3, #f3f3f3), linear-gradient(to right, #f4a582, #d432d8)')
    : status === '6'
    ? (backgroundImage =
        'linear-gradient(white, #fdb4b4), linear-gradient(to right, #edcd73, #ff015f)')
    : status === '5'
    ? (backgroundImage =
        'linear-gradient(#f3f3f3, #f3f3f3), linear-gradient(to right, #f4a582, #d432d8)')
    : status === '4'
    ? (backgroundImage =
        'linear-gradient(white, #95f5c4), linear-gradient(to right, #edcd73, #ff015f)')
    : status === '3'
    ? (backgroundImage =
        'linear-gradient(white, rgb(183 193 188)), linear-gradient(to right, rgb(237, 205, 115), rgb(255, 1, 95))')
    : status === '2'
    ? (backgroundImage =
        'linear-gradient(white, #fdb4b4), linear-gradient(to right, #edcd73, #ff015f)')
    : console.info();

  const ReqAccept = () => {
    setDropDownReqAccept(!dropDownReqAccept);
  };
  return (
    <div style={{ marginBottom: '20px' }}>
      <div
        className={s.item_bshop}
        key={uniqid()}
        style={{
          backgroundImage: `${backgroundImage}`,
          backgroundOrigin: `${backgroundOrigin}`,
          backgroundClip: `${backgroundClip}`,
          marginBottom: '50px',
        }}
      >
        <div
          style={{ marginTop: '8px' }}
          className={s.item__bshop_image}
          onClick={() => ReqAccept()}
        >
          <img
            style={{ height: '60px', width: '60px' }}
            src={
              img && img !== ''
                ? 'https://bshop.kz/beauty/AvatarPhotos/' + img
                : 'https://bshop.kz/beauty/AvatarPhotos/0.png'
            }
            alt='logo'
          />

          <p className={s.item_client_req_fio}>{FIO}</p>
        </div>

        <div className={s.item_client_req_info}>
          <div style={{ marginBottom: '8px' }} className={s.item_client_req_info_name_id}>
            <div style={{ width: '180px' }} className={s.item_client_req_info_date}>
              {orderName} <br />
            </div>
            {orderName2 == '' ? null : (
              <div style={{ width: '180px' }} className={s.item_client_req_info_date}>
                {orderName2} <br />
              </div>
            )}
            {orderName3 == '' ? null : (
              <div style={{ width: '180px' }} className={s.item_client_req_info_date}>
                {orderName3} <br />
              </div>
            )}
            {orderName4 == '' ? null : (
              <div style={{ width: '180px' }} className={s.item_client_req_info_date}>
                {orderName4} <br />
              </div>
            )}
            {orderName5 == '' ? null : (
              <div style={{ width: '180px' }} className={s.item_client_req_info_date}>
                {orderName5} <br />
              </div>
            )}

            {/* <div className={s.item_client_req_info_time}>{time}</div> */}
          </div>
          {/* <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Категория: {status}</div>
              <div></div>
              <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Дата: {day.toLocaleDateString("ru-ru", options)}</div>
              <div></div>
              <div style = {{display: 'inline'}} className={s.item_client_req_info_nameClient}>Время: {time}</div>
              <div className={s.item_client_req_info_comm_price}>
                  <div 
                  style = {{marginLeft: '15px'}} 
                  className={s.item_client_req_info_price}>Цена: {price} тнг</div>
              </div> */}

          <div
            style={{
              display: 'block',
              fontSize: '12px',
              float: 'left',
              marginLeft: '10px',
              lineHeight: '18px',
              fontFamily: 'Gotham Pro Regular',
              textAlign: 'left',
            }}
          >
            {' '}
            Дата:
            <br />
            <br /> Время:
            <br /> Цена:
          </div>
          <div
            style={{ display: 'inline-block', marginBottom: '10px', textAlign: 'left' }}
            className={s.item_client_req_info_nameClient}
          >
            {day.toLocaleDateString('ru-ru', options)}
            <br />
            {time}
            <br />
            <b>{price}</b> тг
          </div>
          {comm == 'Комментариев нет' || comm == '' || comm == '-' ? (
            <div style={{ display: 'inline-block' }}>
              {' '}
              <img src={Comm2} />
            </div>
          ) : (
            <Popup
              trigger={
                <div style={{ display: 'inline-block' }}>
                  {' '}
                  <img src={Comm} />
                </div>
              }
              modal
              nested
            >
              {(close) => (
                <div style={{ height: `216px`, marginTop: '210px' }} className={s.modal_comm}>
                  <p>
                    <b>Комментарий</b>
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>{comm}</p>
                  <button
                    onClick={close}
                    style={{
                      textAlign: 'center',
                      height: '32px',
                      width: '152px',
                      textDecoration: `none`,
                      position: 'absolute',
                      top: '370px',
                      left: '70px',
                    }}
                    className={s.beauty_button_confirm}
                  >
                    Понятно
                  </button>
                </div>
              )}
            </Popup>
          )}
        </div>
      </div>

      {/* <Popup trigger={<button className={s.dropdown_comm}> Комментарий мастера </button>} modal nested>
            <div className={s.modal}>

                <div className={s.header}> Комментарий </div>
                <div className={s.content}>
                    <p>
                        {comm}
                    </p>
                </div>




            </div>

        </Popup> */}

      {status === '0' ? (
        <AcceptModulforClient
          WSCSend={WSCSend2}
          Adress={Adress}
          Email={Email}
          Phone={Phone}
          FIO={FIO}
          SalonName={SalonName}
          img={img}
          orderID={orderId}
          MasterID={MasterID}
          orderName2={orderName2}
          orderName3={orderName3}
          orderName4={orderName4}
          orderName5={orderName5}
          orderName={orderName}
          date={date}
          id={id}
          phome={phome}
          price={price}
          time={time}
          clientId={clientId}
        />
      ) : status === '4' ? (
        <ReviewModulforClient
          WSCSend={WSCSend2}
          Adress={Adress}
          Email={Email}
          Phone={Phone}
          FIO={FIO}
          SalonName={SalonName}
          img={img}
          orderID={orderId}
          MasterID={MasterID}
          orderName2={orderName2}
          orderName3={orderName3}
          orderName4={orderName4}
          orderName5={orderName5}
          orderName={orderName}
          date={date}
          id={id}
          phome={phome}
          price={price}
          time={time}
          clientId={clientId}
        />
      ) : status === '2' ? (
        <p className={s.beauty_button_refused}>Отклонен Вами</p>
      ) : status === '1' ? (
        <p className={s.beauty_button_fin}>Ожидание мастера</p>
      ) : status === '3' ? (
        <p className={s.beauty_button_fin}>Завершен мастером</p>
      ) : status === '6' ? (
        <p className={s.beauty_button_refused}>Отклонен Мастером</p>
      ) : status === '5' ? (
        <p className={s.beauty_button_fin}>Принято мастером</p>
      ) : null}
    </div>
  );
};

export default MyAcceptedRequestForClient;
