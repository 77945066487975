import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';

import s from '../companents/bshop/MainBshop.module.css';

import ButtonOrange from '../companents/bshop/buttonOrange/ButtonOrange';
import Slider from '../companents/bshop/sliderCatalogBshop/Slider_catalog';
import ItemsWrapper from '../companents/bshop/itemsWrapper/ItemsWrapper';
import bshopApi from '../api/bshopApi';
import { onSearchItemBshop, onShopGo, onScrollCatalog } from '../redux/action';
import history from '../companents/history';
import ArrowUp from '../companents/arrowUp/ArrowUp';

const MainBshop = () => {
  const dispatch = useDispatch();
  const { value, onShopId, onCategoryId } = useSelector((state) => state.onSearchItemBshop);
  const [dropDownFilter, setDropDownFilter] = useState(false);
  const [shops, setShops] = useState([]);
  const [category, setCategory] = useState(null);
  const [onSearch, setOnSearch] = useState(value);
  const [onShop, setOnShop] = useState('');
  const [onCategory, setOnCategory] = useState('');
  const selectShopIdOnlyCategory = useRef();
  const [categoryOnShop, setCategroyOnShop] = useState('');
  const [shopInfo, setShopInfo] = useState('');
  const [arrowUp, setArrowUp] = useState(false);

  useEffect(() => {
    getShops();
    getCategory(onShopId);
    window.addEventListener('scroll', () => {
      window.pageYOffset > 400 ? setArrowUp(true) : setArrowUp(false);
    });

    return () => {
      setShops([]);
      setCategory([]);
    };
  }, []);

  const getShops = async () => {
    const responseItem = await bshopApi.fetchShops();
    setShops(responseItem);
  };

  const getCategory = async (id) => {
    const responseItem = await bshopApi.fetchCategories(id);
    setCategory(responseItem);
  };

  const getShopInfo = async (id) => {
    const responseItem = await bshopApi.fetchShopInfo(id);
    setShopInfo(responseItem);
  };

  const searchDeb = React.useCallback(
    debounce((value, onShopId, onCategoryId) => {
      dispatch(onScrollCatalog(''));
      dispatch(
        onSearchItemBshop({
          value: value,
          end: 100,
          onShopId: onShopId,
          onCategoryId: onCategoryId,
        }),
      );
    }, 1000),
    [],
  );

  const search = (value) => {
    setOnSearch(value);
    searchDeb(value, onShopId, onCategoryId);
    onShopId && onChangeCategory(onShopId);
  };

  const onChangeShop = (value) => {
    dispatch(onScrollCatalog(''));

    setOnShop(value);
    dispatch(
      onSearchItemBshop({
        value: '',
        end: 100,
        onShopId: value,
        onCategoryId: onCategoryId,
      }),
    );
    getShopInfo(value);
    setDropDownFilter(false);
    history.push(`/shopper/shopid=${value}`);
    dispatch(onShopGo(true));
    getCategory(value);
  };

  const onChangeCategory = (value) => {
    dispatch(onScrollCatalog(''));

    setOnCategory(value);
    !onShop.length > 0 &&
      setCategroyOnShop(
        selectShopIdOnlyCategory.current.options[selectShopIdOnlyCategory.current.selectedIndex]
          .dataset.shopid,
      );
  };

  const goToFilter = () => {
    dispatch(onScrollCatalog(''));
    dispatch(
      onSearchItemBshop({
        value: value,
        end: 100,
        onShopId: categoryOnShop,
        onCategoryId: onCategory,
      }),
    );
  };

  const goToReset = () => {
    dispatch(onScrollCatalog(''));
    dispatch(onSearchItemBshop({ value: '', end: 10, onShopId: '', onCategoryId: '' }));
    getCategory();
    setOnCategory('');
    setOnSearch('');
  };

  return (
    <div style={{ marginBottom: '70px' }}>
      <>
        <Slider />

        <div className={s.catalog_items}>
          <div className={s.filterInItem}>
            <input
              type="text"
              className={s.inputSearch}
              placeholder="Поиск"
              value={onSearch}
              onChange={(e) => search(e.target.value)}
            />
            <svg
              className={s.inputImgSearch}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9459 16.7062L12.1027 10.863C13.0094 9.69071 13.4999 8.25746 13.4999 6.74997C13.4999 4.94548 12.7957 3.25349 11.5222 1.97774C10.2487 0.701997 8.55221 0 6.74997 0C4.94773 0 3.25124 0.704247 1.97774 1.97774C0.701997 3.25124 0 4.94548 0 6.74997C0 8.55221 0.704247 10.2487 1.97774 11.5222C3.25124 12.7979 4.94548 13.4999 6.74997 13.4999C8.25746 13.4999 9.68846 13.0094 10.8607 12.1049L16.7039 17.9459C16.7211 17.9631 16.7414 17.9767 16.7638 17.9859C16.7862 17.9952 16.8102 18 16.8344 18C16.8587 18 16.8827 17.9952 16.9051 17.9859C16.9275 17.9767 16.9478 17.9631 16.9649 17.9459L17.9459 16.9672C17.9631 16.95 17.9767 16.9297 17.9859 16.9073C17.9952 16.8849 18 16.8609 18 16.8367C18 16.8124 17.9952 16.7884 17.9859 16.766C17.9767 16.7437 17.9631 16.7233 17.9459 16.7062ZM10.314 10.314C9.35996 11.2657 8.09546 11.7899 6.74997 11.7899C5.40448 11.7899 4.13998 11.2657 3.18599 10.314C2.23424 9.35996 1.70999 8.09546 1.70999 6.74997C1.70999 5.40448 2.23424 4.13773 3.18599 3.18599C4.13998 2.23424 5.40448 1.70999 6.74997 1.70999C8.09546 1.70999 9.36221 2.23199 10.314 3.18599C11.2657 4.13998 11.7899 5.40448 11.7899 6.74997C11.7899 8.09546 11.2657 9.36221 10.314 10.314Z"
                fill="#A8A8A8"
              />
            </svg>

            <div className={s.dropdown_filters} onClick={() => setDropDownFilter(!dropDownFilter)}>
              {!dropDownFilter ? (
                <svg
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0L8 8L16 2.79753e-06" fill="#404246" />
                </svg>
              ) : (
                <svg
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 8L8 6.99382e-07L0 8" fill="#404246" />
                </svg>
              )}
            </div>
          </div>

          {dropDownFilter && (
            <div className={s.open_filter}>
              {/* <div className={s.filter_line_trade_house}>
                  <select className={s.trade_house_select}>
                    <option>Торговый дом</option>
                  </select>
                  <select className={s.corpus_select}>
                    <option>Корпус</option>
                  </select>
                </div> */}
              <select
                className={s.shop_select}
                onChange={(e) => onChangeShop(e.target.value, onShopId, onCategoryId)}>
                <option>Магазин</option>
                {shops?.map((item) => {
                  return (
                    <option
                      key={item.id}
                      value={item.id}
                      selected={onShopId && onShopId === item.id && 'selected'}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <div className={s.filter_last_line}>
                <select
                  className={s.product_filter_select}
                  ref={selectShopIdOnlyCategory}
                  onChange={(e) => onChangeCategory(e.target.value)}>
                  <option>Товар</option>
                  {category?.map((item) => {
                    return (
                      <option
                        key={`${item.shopid}_${item.id}`}
                        data-shopid={item.shopid}
                        value={item.id}
                        selected={
                          onCategoryId &&
                          onCategoryId === item.id &&
                          onShopId === item.shopid &&
                          'selected'
                        }>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <div className={s.button_filter_svg}>
                  <svg
                    width="28"
                    height="26"
                    viewBox="0 0 28 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <line
                      x1="1"
                      y1="3"
                      x2="27"
                      y2="3"
                      stroke="#404246"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <line
                      x1="1"
                      y1="13"
                      x2="27"
                      y2="13"
                      stroke="#404246"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <line
                      x1="1"
                      y1="23"
                      x2="27"
                      y2="23"
                      stroke="#404246"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <circle cx="7" cy="3" r="2" fill="#F3F3F3" stroke="#404246" strokeWidth="2" />
                    <circle cx="21" cy="13" r="2" fill="#F3F3F3" stroke="#404246" strokeWidth="2" />
                    <circle cx="12" cy="23" r="2" fill="#F3F3F3" stroke="#404246" strokeWidth="2" />
                  </svg>
                </div>
              </div>
              <div className={s.btnWrapper}>
                <div className={s.btnFilter} onClick={() => goToFilter()}>
                  <ButtonOrange>Применить</ButtonOrange>
                </div>
                <div className={s.btnFilter} onClick={() => goToReset()}>
                  <ButtonOrange>Сбросить</ButtonOrange>
                </div>
              </div>
            </div>
          )}
          {arrowUp && <ArrowUp />}
          <ItemsWrapper />
        </div>
      </>
    </div>
  );
};

export default MainBshop;