import React, { useRef, useState, useEffect } from 'react';
import '../../pages/beauty/style/Registration_form_style.css';
import Registration_text_field from '../../pages/beauty/Registration_text_field';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Inputmask from 'inputmask';
import star from '../../../src/asseets/star.png';
import { accountBeauty } from '../../redux/action';

const Registartion_form_client = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputP = useRef();

  const [UserName, setName] = useState('');
  const [Phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [phoneInput, setphoneInput] = useState(false);
  const [city_id, setCityId] = useState(0);

  const urlPath = localStorage.getItem('urlPath');
  const emregex = /\S+@\S+\.\S+/;

  inputP.current && Inputmask({ mask: '+7 (999)-999-99-99' }).mask(inputP.current);

  const [verificationCode, setVerificationCode] = useState('');
  const [serverVerificationCode, setServerVerificationCode] = useState('');
  const [passwordInp, setPasswordInp] = useState('');
  const [agreePasswordInp, setAgreePasswordInp] = useState('');
  const [tab, setTab] = useState(0);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    getCityList();
  }, []);

  const onPhoneInput = () => {
    // inputP.current.value = "";
    setphoneInput(true);
  };

  const validationErrorMessage = (field) => {
    toast('Ошибка! Некорректно введенное поле ' + field, {
      backgroundColor: '#323131',
      color: '#ffffff',
    });
  };

  const showMessage = (msg, type = 'success') => {
    if (type == 'error') {
      toast.error(msg, {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    } else if (type == 'success') {
      toast.success(msg, {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    }
  };

  const wave_success = () =>
    toast(
      'Отлично! Вы успешно зарегистрировались! Для входа используйте введенный номер телефона и введенный пароль',
      {
        backgroundColor: '#10df3d',
        color: '#ffffff',
      },
    );

  const verifyCode_error = () =>
    toast('Неверно введен код', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });

  const wave_yandex = () =>
    toast(
      'Похоже, что вы используете почту Яндекс, поэтому вам может прийти "пустое" сообщение. Для получения скрытой информации перейдите в свойства письма.',
      {
        backgroundColor: '#10df3d',
        color: '#ffffff',
      },
    );

  const wave_mail_not_success = () =>
    toast('Ошибка! Не удалось отправить письмо на указанную почту', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
    });

  const wave_repeat_not_success = () =>
    toast('Ошибка! Клиент уже зарегистрирован!', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });

  const historyPush = () => {
    history.push('/beauty/profile');
  };

  const undef_error = () =>
    toast('Возникла ошибка на стороне сервера. Попробуйте снова', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });

  const no_agree_pass = () =>
    toast('Пароли не совпадают', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });

  const wave_call = () =>
    toast(
      'В течении нескольких минут Вам позвонит робот, отвечать на звонок не нужно! Запомните последние 4 цифры и введите их в поле для кода.',
      {
        backgroundColor: '#10df3d',
        color: '#ffffff',
        autoClose: false,
      },
    );
  const getCityList = async () => {
    let cities = await fetch('https://beauty.bikli.kz/beautyApi/getListOfCities.php');
    cities = await cities.json();
    setCities(cities);
  };

  const formatPhone = (number) => {
    number = number.replaceAll('-', '');
    number = number.replaceAll(' ', '');
    number = number.replaceAll('(', '');
    number = number.replaceAll(')', '');
    number = number.replace('+7', '7');
    return number;
  };

  const FlashhCallError = () => {
    undef_error();
    setTimeout(PageReload, 1500);
  };

  const PageReload = () => {
    window.location.reload();
  };

  const FlashhCallSucces = (code) => {
    setServerVerificationCode(code);
    wave_call();
  };

  const Authorization = async () => {
    wave_success();

    const regdata = new FormData();
    // regdata.append('phone', Phone);
    regdata.append('pass', passwordInp);
    regdata.append('phone', Phone);
    regdata.append('status', 0);

    let phone = Phone;
    let response = await fetch(`https://bshop.kz/beauty/beautyApi/profil/logIn.php`, {
      method: 'POST',
      body: regdata,
    });

    let commits = await response.json(); // читаем ответ в формате JSON
    if (commits) {
      dispatch(accountBeauty(commits[0]));
      localStorage.setItem('account', JSON.stringify({ login: phone, pass: passwordInp }));
      localStorage.setItem('avatar', commits[0].img);
      historyPush();
    }
  };

  const PhoneCode = async () => {
    if (
      phoneInput == true &&
      inputP.current.inputmask.undoValue !== undefined &&
      inputP.current.inputmask.undoValue[17] !== '_' &&
      inputP.current.inputmask.undoValue !== ''
    ) {
      if (UserName !== '') {
        setTab(1);
        inputP.current.setAttribute('disabled', 'disabled');
        const phonedata = new FormData();
        phonedata.append('phone', Phone);
        fetch(`https://beauty.bikli.kz/beautyApi/flashcall.php`, {
          method: 'POST',
          body: phonedata,
        })
          .then((response) => response.json())
          .then((res) => (res == 400 ? FlashhCallError() : FlashhCallSucces(res.code)));
      } else {
        validationErrorMessage('Имя');
      }
    } else {
      validationErrorMessage('Телефон');
    }
  };

  const SendRegForm = async (e) => {
    e.preventDefault();
    if (passwordInp === agreePasswordInp) {
      if (
        phoneInput == true &&
        inputP.current.inputmask.undoValue !== undefined &&
        inputP.current.inputmask.undoValue[17] !== '_' &&
        inputP.current.inputmask.undoValue !== ''
      ) {
        if (UserName !== '') {
          const regdata = new FormData();

          regdata.append('FIO', UserName);
          regdata.append('Phone', Phone);
          regdata.append('SalonName', '__Клиент__');
          regdata.append('status', 0);
          regdata.append('password', passwordInp);
          regdata.append('city_id', city_id);

          let response = await fetch(`https://bshop.kz/beauty/beautyApi/BRegistration_form3.php`, {
            method: 'POST',
            body: regdata,
          });
          let commits = await response.json(); // читаем ответ в формате JSON
          const PHPresponse = commits[0].response;
          console.log(PHPresponse);
          // setTimeout(historyPush, 5000);
          PHPresponse == 'Успешно!'
            ? Authorization()
            : PHPresponse == 'Клиент уже зарегистрирован!'
            ? wave_repeat_not_success()
            : PHPresponse == 'Ошибка отправки письма'
            ? wave_success()
            : undef_error();

          if (email.indexOf('yandex') + 1) {
            showMessage(
              'Похоже, что вы используете почту Яндекс, поэтому вам может прийти "пустое" сообщение. Для получения скрытой информации перейдите в свойства письма.',
            );
          }
        } else {
          validationErrorMessage('Телефон');
        }
      } else {
        validationErrorMessage('Телефон');
      }
    } else {
      showMessage('Пароли не совпадают', 'error');
    }
  };

  return (
    <div className='reg_body'>
      <div style={{ marginTop: '40px' }} className='reg_wrapper'>
        <h1 className='reg_title' style={{ marginTop: '90px' }}>
          Регистрация
        </h1>

        <div style={{ textAlign: 'center' }} className='registration_form'>
          <div
            style={{
              width: 'fit-content',
              margin: 'auto',
              textAlign: 'left',
            }}
          >
            <p style={{ display: 'inline' }}>Имя</p>{' '}
            <img style={{ marginBottom: '8px' }} src={star}></img>
            <Registration_text_field
              name='password'
              onChange={(e) => setName(e.target.value)}
              error='none'
              // placeholder="Пароль"
              className='reg_placeholder'
            />
            <p style={{ display: 'inline' }}>Телефон</p>{' '}
            <img style={{ marginBottom: '8px' }} src={star}></img>
            <div className='inputGroup'>
              <input
                ref={inputP}
                className='reg_placeholder'
                type='text'
                onChange={(e) => setPhone(e.target.value)}
                onClick={() => {
                  onPhoneInput();
                }}
              />
            </div>
            <p style={{ display: 'inline' }}>Город</p>{' '}
            <img style={{ marginBottom: '8px' }} src={star}></img>
            <div className='inputGroup'>
              <select
                className='reg_placeholder'
                type='text'
                onChange={(e) => setCityId(e.target.value)}
              >
                <option value='0'>Не указано</option>
                {cities.map((item) => (
                  <option key={item.city_id} value={item.city_id}>
                    {item.city}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div style={{ display: tab == 0 ? 'block' : 'none' }} className='inputGroup'>
            <button onClick={PhoneCode} className='reg_btn' style={{ marginBottom: 20 }}>
              Получить код
            </button>
            <p>
              Мы позвоним на указанный номер, вашим кодом будут последние 4 цифры звонившего номера
            </p>
          </div>
          <div style={{ display: tab == 1 ? 'block' : 'none' }}>
            <p style={{ marginLeft: '25px', display: 'inline' }}>Введите последние 4 цифр номера</p>

            <div
              style={{
                width: 'fit-content',
                margin: 'auto',
                textAlign: 'left',
              }}
            >
              <Registration_text_field
                name='passwordCode'
                onChange={(e) => setVerificationCode(e.target.value)}
                error='none'
                placeholder='Код'
                className='reg_placeholder'
                // label={"Код"}
              />

              <p style={{ display: 'inline' }}>Введите новый пароль</p>
              <Registration_text_field
                name='password'
                onChange={(e) => setPasswordInp(e.target.value)}
                error='none'
                placeholder='Пароль'
                className='reg_placeholder2'
                // label={"Код"}
              />
              <p style={{ display: 'inline' }}>Повторите пароль</p>
              <Registration_text_field
                name='password'
                onChange={(e) => setAgreePasswordInp(e.target.value)}
                error='none'
                placeholder='Повторите пароль'
                className='reg_placeholder2'
                // label={"Код"}
              />
            </div>

            <div className='inputGroup'>
              <button
                onClick={(e) => {
                  if (verificationCode == serverVerificationCode.substr(2, 4)) {
                    SendRegForm(e);
                    console.log('success');
                  } else {
                    verifyCode_error();
                  }
                }}
                className='reg_btn'
                style={{ marginBottom: 20 }}
              >
                Продолжить
              </button>

              <button
                onClick={(e) => {
                  window.location.reload();
                }}
                className='reg_btn'
                style={{ marginBottom: 20, fontSize: '14px' }}
              >
                Введен неверный номер
              </button>
            </div>
          </div>
          <button
            className='reg_abs'
            style={{ textAlign: 'center', marginBottom: 20 }}
            onClick={() => history.goBack()}
          >
            назад
          </button>
          <div>
            <img style={{ display: 'inline' }} src={star}></img>
            <p style={{ marginLeft: '10px', display: 'inline' }}> поля обязательные к заполнению</p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ToastContainer position='top-center' theme='dark' limit={3} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registartion_form_client;
