import React from 'react'
import  "./style/Registration_form_style.css";

const Registration_text_field = ({name, className, onChange, onBlur, error, label, placeholder}) => (
  <div className = 'inputGroup'>
    <label>
      {label}
      <input
        className = {className}
        type="text"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder = {placeholder}
        autoComplete="none"
      />
      </label>
  </div>
);

export default Registration_text_field;