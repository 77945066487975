const baseBeautyUrl = 'https://bshop.kz/beauty/beautyApi/';

const beautyApi = {
  fetchMastersInCatalog: async (counItems, profName, city) => {
    const response = await fetch(
      baseBeautyUrl +
        `CatAndOrder/getCategory.php?start=0&end=${counItems}&prof=${profName}&city_id=${city}`,
    );
    const data = response.json();
    return data;
  },

  fetchServicesInCatalog: async (counItems, profName) => {
    const response = await fetch(
      baseBeautyUrl + `CatAndOrder/PullOrderCat.php?start=0&end=${counItems}&prof=${profName}`,
    );
    const data = response.json();
    return data;
  },

  fetchServicesInReq: async () => {
    const response = await fetch(baseBeautyUrl + `PullOrderCatReq.php`);
    const data = response.json();
    return data;
  },

  fetchServicesInReq2: async () => {
    const response = await fetch(baseBeautyUrl + `PullOrderCatReq2.php`);
    const data = response.json();
    return data;
  },

  fetchServicesInReq3: async () => {
    const response = await fetch(baseBeautyUrl + `PullOrderCatReq3.php`);
    const data = response.json();
    return data;
  },

  ClientServiceRequests: async () => {
    const response = await fetch(baseBeautyUrl + `PullOrderTorgy2.php`, {
      method: 'POST',
    }).then((response) => response.json());
    // console.log(response);
    return response;
  },

  MyRequests: async (RServiceEnd, RServicesValue, myId) => {
    const data = new FormData();
    data.append('start', RServiceEnd);
    data.append('end', RServicesValue);
    data.append('clientId', myId);

    const response = await fetch(baseBeautyUrl + `PullHistoryOrder.php`, {
      method: 'POST',
      body: data,
    }).then((response) => response.json());
    // console.log(response);
    return response;
  },

  MyAcceptedRequests: async (RServiceEnd, RServicesValue, myId) => {
    const data = new FormData();
    data.append('start', RServiceEnd);
    data.append('end', RServicesValue);
    data.append('clientId', myId);
    const response = await fetch(baseBeautyUrl + `profil/PullOrderMasterToClient.php`, {
      method: 'POST',
      body: data,
    }).then((response) => response.json());
    // console.log(response);
    return response;
  },

  ServicesOfMaster: async (RServiceEnd, RServicesValue, masterID) => {
    const data = new FormData();
    data.append('userId', masterID);
    const response = await fetch(baseBeautyUrl + `profil/putOrderMaster.php`, {
      method: 'POST',
      body: data,
    }).then((response) => response.json());
    // console.log(response);
    return response;
  },

  MyAcceptedRequestMaster: async (masterID) => {
    const data = new FormData();

    data.append('id', masterID);

    const response = await fetch(baseBeautyUrl + `profil/masterProfill.php`, {
      method: 'POST',
      body: data,
    }).then((response) => response.json());
    // console.log(response);
    return response;
  },

  AcceptMaster: async (id, masterId, price, orderId, status) => {
    const data = new FormData();

    data.append('id', id);
    data.append('masterId', masterId);
    data.append('price', price);
    data.append('orderId', orderId);
    data.append('status', status);

    const response = await fetch(baseBeautyUrl + `torgy/AcceptOrderClient.php`, {
      method: 'POST',
      body: data,
    })
      .then
      // (response) => response.json(),
      ();
    // console.log(response);
    return response;
  },

  ClientService: async (fd) => {
    const response = await fetch(baseBeautyUrl + `torgy/ApplyOrder.php`, {
      method: 'POST',
      body: fd,
    });
    const data = response;
    return data;
  },

  ClientServiceRequestsForInstagram: async () => {
    const response = await fetch(baseBeautyUrl + `PullOrderTorgy3.php`, {
      method: 'POST',
    }).then((response) => response.json());
    return response;
  },

  NameEdit: async (fd) => {
    // console.log(fd)
    const response = await fetch(baseBeautyUrl + `profil/editProfileName.php`, {
      method: 'POST',
      body: fd,
    });
    const data = response;
    // return data;
  },

  CityEdit: async (fd) => {
    // console.log(fd)
    const response = await fetch(baseBeautyUrl + `profil/editProfileCity.php`, {
      method: 'POST',
      body: fd,
    });
    const data = response;
    // return data;
  },

  MasterReqAccept: async (fd) => {
    const response = await fetch(
      `https://beauty.bikli.kz/beautyApi/torgy/beautyOrderApplyMaster.php`,
      {
        method: 'POST',
        body: fd,
      },
    );
    const data = response;
    return data;
  },

  fetchDataMaster: async (obj) => {
    const response = await fetch(baseBeautyUrl + `profil/profil.php`, {
      method: 'POST',
      body: obj,
    });
    const data = response.json();
    return data;
  },

  fetchBlogMaster: async (obj) => {
    const response = await fetch(baseBeautyUrl + `profil/PullPubImg.php`, {
      method: 'POST',
      body: obj,
    });
    const data = response.json();
    return data;
  },

  fetchPublications: async (counItems, onSearch) => {
    const response = await fetch(
      baseBeautyUrl +
        `getPublications.php?start=0&end=${counItems}${onSearch && '&nameitem=' + onSearch}`,
    );
    const data = response.json();
    return data;
  },

  uploadAvatarUser: async (obj) => {
    const response = await fetch(baseBeautyUrl + `uploadImg.php`, {
      method: 'POST',
      body: obj,
    });
    const data = response.json();
    return data;
  },

  clientServNotification: async (obj) => {
    const response = await fetch(`https://beauty.bikli.kz/beautyApi/Notifications/pushBrown.php`, {
      method: 'POST',
      body: obj,
    });
    // const data = response.json();
    // return data;
  },

  userNotification: async (obj) => {
    const response = await fetch(
      `https://admin.bikli.kz/api/Notifications/appropriateTagLoginClient.php`,
      {
        method: 'POST',
        body: obj,
      },
    );
    // const data = response.json();
    // return data;
  },

  pushN: async (obj) => {
    const response = await fetch(`https://admin.bikli.kz/api/Notifications/pushN.php`, {
      method: 'POST',
      body: obj,
    });
    // const data = response.json();
    // return data;
  },

  pullCalendar: async (obj) => {
    const response = await fetch(`https://bshop.kz/beauty/beautyApi/calendar/pullCalendar.php`, {
      method: 'POST',
      body: obj,
    });
    const data = response.json();
    return data;
  },

  pushCompletedClient: async (masterId) => {
    const data = new FormData();
    data.append('masterId', masterId);
    const response = await fetch(
      `https://admin.bikli.kz/api/Notifications/pushCompletedClient.php`,
      {
        method: 'POST',
        body: data,
      },
    );
    // const data = response.json();
    // return data;
  },

  logout: async (data) => {
    const response = await fetch(`https://admin.bikli.kz/api/Notifications/logoutClient.php`, {
      method: 'POST',
      body: data,
    });
    // const data = response.json();
    // return data;
  },

  pullDate: async (data) => {
    const response = await fetch(`https://bshop.kz/beauty/beautyApi/works/pullDate.php`, {
      method: 'POST',
      body: data,
    });
    const getdata = response;
    return getdata;
  },

  pullDasdate: async (data) => {
    const response = await fetch(`https://bshop.kz/beauty/beautyApi/works/pullDasdate.php`, {
      method: 'POST',
      body: data,
    });
    const getdata = response;
    return getdata;
  },

  sendPersonalRecord: async (data) => {
    const response = await fetch(`https://bshop.kz/beauty/beautyApi/individual_entry/entry.php`, {
      method: 'POST',
      body: data,
    });
    const getdata = response;
    return getdata;
  },

  pushIndividual: async (data) => {
    const response = await fetch(`https://admin.bikli.kz/api/Notifications/pushIndividual.php`, {
      method: 'POST',
      body: data,
    });
    const getdata = response;
    return getdata;
  },

  PullOrderIndividualEntryToClient: async (id) => {
    const data = new FormData();
    data.append('clientId', id);
    const response = await fetch(
      `https://bshop.kz/beauty/beautyApi/profil/PullOrderIndividualEntryToClient.php`,
      {
        method: 'POST',
        body: data,
      },
    );
    const getdata = response.json();
    return getdata;
  },
};

export default beautyApi;
