import React from 'react';
import s from './ArrowUp.module.css';
import ArrowUpImg from '../../asseets/arrow-up-catalog.png';
const ArrowUp = () => {
  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={s.root}>
      <img src={ArrowUpImg} alt="arrow-up" onClick={() => onClick()} />
    </div>
  );
};

export default ArrowUp;
