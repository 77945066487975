import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import store from './redux/store';

import { Router } from 'react-router-dom';
import history from './companents/history.js';

const backup = console.error;

console.error = function filterWarnings(msg) {
  const supressedWarnings = [
    'Warning: Each child in a list should have a unique "key" prop.',
    'other warning text',
  ];
  if (!supressedWarnings.some((entry) => msg.includes(entry))) {
    backup.apply(console, arguments);
  }
};

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root'),
);
