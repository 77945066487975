import React, { Component } from 'react';

import { connect } from 'react-redux'
import { bodyOverflowToCard } from '../../redux/action'

import { Link } from 'react-router-dom';

import Card from '../card'

import './style.css'

import reitStar from '../../asseets/reitYellow.png'

class ItemCafeProduct extends Component {

    state = {
        showCard: false,

        showGoBasket: false,

        cardData: []
    }

    showSetCard = (card) => {
        this.setState({ showCard: true, cardData: card })
        this.props.bodyOverflowToCard(this.state.showCard)
    }

    closeModalEvent = (e) => {
        if (e === 'main') this.setState({ showCard: false })
        this.props.bodyOverflowToCard(this.state.showCard)
    }

    closeModal = () => {
        this.setState({ showCard: false })
        setTimeout(() => {
            this.setState({ showGoBasket: true })
        }, 200);
        this.props.bodyOverflowToCard(this.state.showCard)
    }

    render() {
        const menu = this.props.newMenu.length !== 0 ? this.props.newMenu : this.props.menu;

        return (
            <div className='item-cafe'>

                <div className='item-cafe-wrapper'>

                    <div className='items-product-cafe'>
                        {menu.map(el => {
                            const image = `https://bikli.kz/imgProduct/${el.image}`
                            return (
                                <div className='item-prod' key={el.id} onClick={() => this.showSetCard(el)}>
                                    <div className='item-prod-text'>
                                        <h1>{el.nameeda}</h1>
                                        <p>цена: {el.cenaeda} т</p>
                                        {Array(5).fill(reitStar).map((el, i) => <img src={`${el}`} alt='riet' key={i} className='item-prod-star-reit' />)}
                                    </div>
                                    <img src={image} alt='prod' />
                                </div>
                            )
                        })}

                        {this.state.showCard
                            ? <Card card={this.state.cardData} closeModalEvent={this.closeModalEvent} closeModal={this.closeModal} />
                            : null}

                        {this.props.basket.length
                            ? <div className='showGoBasket'>
                                <Link to='/backet' className='goBasket'>
                                    <div>
                                        <p>В корзине</p>
                                        <p>{this.props.totalPrice} тнг</p>
                                    </div>
                                </Link>
                            </div>
                            : null}

                    </div>
                </div>

            </div >
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = { bodyOverflowToCard }

export default connect(mapStateToProps, mapDispatchToProps)(ItemCafeProduct);