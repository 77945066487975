
import React, { useEffect, useState } from 'react';
import beautyApi from '../../../api/beautyApi';
import Publication from './Publication';
import s from './PublicationWrapper.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import BeautyPostsLoadingBlock from './BeautyPostsLoadingBlock';
import { useSelector, useDispatch } from 'react-redux';
import { onSearchItemPubl, onScrollCatalog, AddCountItemPubl, onAddPubl} from '../../../redux/action';
import uniqid from 'uniqid';

const PublicationWrapper = () => {
  const dispatch = useDispatch();
  const { publvalue, end, onShopId, onCategoryId } = useSelector((state) => state.onSearchItemPubl);
  const Publs = useSelector((state) => state.itemsPubl);
  const PublItems = useSelector((state) => state.PublItems);
  const beenScroll = useSelector((state) => state.scrollCatalog);
  const urlPath = window.location.pathname;

  // формируем локальное хранилище: отправляем запрос, получаем данные (bshopApi) и записываем их в локальное хранилище (dispatch)
  const getPublications = async (end, publvalue) => {
    const responsePublications = await beautyApi.fetchPublications(end, publvalue);
    dispatch(onAddPubl(responsePublications.data));

    if (beenScroll && urlPath.slice(0, 15) === '/shopper/shopid') {
      await window.scrollTo({
        top: beenScroll,
      });
    }
  };
  
  useEffect(() => {
    if (Publs.length === 0) {
      // console.log('Первый ');
      getPublications(PublItems, '', '3', '');
    }
  }, []);

  useEffect(() => {
    if (Publs.length !== 0) {
      if (publvalue === '' && onShopId === '' && onCategoryId === '') {
        // console.log('второй');
        getPublications(PublItems, '', '3', '');
      } else {

        getPublications(end, publvalue, onShopId, onCategoryId);
      }
    }
  }, [PublItems, publvalue, end, onShopId, onCategoryId]);

  const fetchNewItems = () => {
    dispatch(onScrollCatalog(''));
    if (publvalue === '' && onShopId === '' && onCategoryId === '') {
      dispatch(AddCountItemPubl(PublItems + 2));
    } else {
      dispatch(
        onSearchItemPubl({
          publvalue: publvalue,
          end: end + 2,
          onShopId: onShopId,
          onCategoryId: onCategoryId,
        }),
      );
    }
  };

  return (
    <div
      className={s.all_items}
      id="scrollableDiv"
      style={{ overflowY: "scroll", overflowX: "hidden" }}
    >
      {Publs.length > 0 ? (
        <InfiniteScroll
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: "0, -4px",
            justifyContent: "space-between",
          }}
          dataLength={Publs.length}
          next={fetchNewItems}
          hasMore={true}
          loader={
            <div style={{ width: "50%" }}>
              {/* {Array.from(Array(4), (_, i) => (
                      <LoadingBlock key={uniqid()} />
                    ))} */}
            </div>
          }
          sscrollableTarget="scrollableDiv"
        >
          {Publs.map((item) => (
            <Publication key={uniqid()} {...item} />
          ))}
        </InfiniteScroll>
      ) : (
        Array.from(Array(2), (_) => <BeautyPostsLoadingBlock key={uniqid()} />)
      )}
    </div>
  );
};

export default React.memo(PublicationWrapper);
