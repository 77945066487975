import React, { Component } from 'react';

import { connect } from 'react-redux'
import { addItemBasket } from '../../redux/action';

import './style.css'

import Button from '../button';

import close from '../../asseets/close-gray.png'

class Card extends Component {

    state = {
        count: 1,
    }

    countMinus = () => {
        if (this.state.count < 1) return
        this.setState({ count: this.state.count - 1 })
    }

    countPlus = () => this.setState({ count: this.state.count + 1 })

    totalItem = () => {
        const itemCard = Object.assign(this.props.card, { count: this.state.count, amount: this.state.count * this.props.card.cenaeda, })
        this.props.addItemBasket(itemCard)
        this.props.closeModal()
    }

    render() {
        const card = this.props.card;
        const image = `https://bikli.kz/imgProduct/${card.image}`;
        const totalPrice = `В корзину ${this.state.count * card.cenaeda}`
        return (
            <div className='modal-menu' data-type='main' onClick={(e) => this.props.closeModalEvent(e.target.dataset.type)}>
                <div className='card-product'>
                    <img src={close} alt='close' className='close-card-product' onClick={this.props.closeModal} />

                    <div className='card-product-wrapper'>
                        <div className='card-product-info'>
                            <img src={image} alt='product' />
                            <h3>{card.nameeda}</h3>
                            <span>{card.sostav}</span>
                        </div>

                    </div>

                    <div className='line-card'></div>

                    <div className='item-num card-num'>
                        {this.state.count === 0
                            ? <Button class='btn-backet-minus-card none-btn' />
                            : <Button value='-' class='btn-backet-minus-card' click={this.countMinus} />}
                        <p>{this.state.count}</p>
                        <Button value='+' class='btn-backet-plus-card' click={this.countPlus} />

                        {this.state.count
                            ? <Button value={totalPrice} class='btn_basket_card' click={this.totalItem} />
                            : <div className='btn_basket_card_null' disabled>
                                <p>Товар не выбран</p>
                            </div>}
                    </div>

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = { addItemBasket }

export default connect(null, mapDispatchToProps)(Card);