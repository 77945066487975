import React, { useEffect, useRef, useState } from "react";
import s from "./ServiceMasters.module.css";
import BeautyMastersChooseSlider from "./Slider/BeautyMastersChooseSlider";
import "react-calendar/dist/Calendar.css";
// import MastersFilter from "./MastersFilter";
import ServicesOrMasters from "./ServicesOrMasters";
const ServiceMastersMenu = (props) => {
	return (
		<div className={s.MastersAndServicesWrapper}>
			<>
				<BeautyMastersChooseSlider />
				{/* <MastersFilter /> */}
				<ServicesOrMasters />
			</>
		</div>
	);
};

export default ServiceMastersMenu;
