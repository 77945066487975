import React, { Component } from 'react';

import { Link } from 'react-router-dom'

import star from '../../asseets/reit.png'
import clock from '../../asseets/clock.png'

import './style.css'

class CafeItem extends Component {

    render() {
        const el = this.props.el;
        const image = `https://bikli.kz/LogoCafe/${el.logo}`;
        let url = `cafe/${el.id}`;
        return (
            <div className='cafe-item'>

                <div className='cardM'>
                    <Link to={url} className='card-wrapper' onClick={() => this.props.selectedItem(el.id)}>

                        <img className='logo-item-cafe' alt='logo' src={image} />

                        <p className='name-cafe'>{el.name}</p>

                        <div className='info'>
                            <div className='info-left'>
                                <p>заказ от {el.minsumma} т</p>
                                <div>
                                    <img className='icon-item-cafe' src={star} alt='logo' />
                                    <p>{el.reiting}</p>
                                </div>
                            </div >
                            <div className='info-right'>
                                <img className='icon-item-cafe' src={clock} alt='logo' />
                                <p>{el.vremya} мин</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default CafeItem;