import React, { Component } from 'react';

import SubHistoryItem from '../sub-item-history'

import arrow from '../../asseets/arrow-bottom.png'

class HistoryItem extends Component {

    state = {
        showDetailDelivery: false
    }

    render() {
        const { ...el } = this.props.el
        const marginBottom = this.state.showDetailDelivery ? 'item-history' : 'item-history marginBottom'
        return (
            <div>
                <div className={marginBottom} key={el.id}>
                    <h3>{el.name_cafe}</h3>
                    <p>{el.datazakaza}</p>
                    <div className='item-history-text'>
                        <span className='status'>{el.status_name}</span>
                        <div className='item-history-price-arrow'>
                            <h3>{el.totalPrice}</h3>
                            <img src={arrow} alt='v' onClick={() => this.setState({ showDetailDelivery: !this.state.showDetailDelivery, item: el.itemProduct, id: el.id })} />
                        </div>
                    </div>
                </div>
                {this.state.showDetailDelivery ? <SubHistoryItem item={el.zakaz} showDetailDelivery={this.state.showDetailDelivery} /> : null}
            </div>
        );
    }
}

export default HistoryItem;