export const chooseTypeSection = (typeSection) => {
  return {
    type: 'CHOOSE_TYPE_SECTION',
    typeSection,
  };
};

export const setDate = (json, item) => {
  return {
    type: 'SET_DATE',
    payload: json,
    item,
  };
};

export const setItem = (json, item) => {
  return {
    type: 'SET_ITEM',
    payload: json,
    item,
  };
};

export const setItemCategory = (category) => {
  return {
    type: 'SET_ITEM_CATEGORY',
    payload: category,
  };
};

export const filterCategory = (id) => {
  return {
    type: 'FILTER_CATEGORY',
    payload: id,
  };
};

export const addItemBasket = (item) => {
  return {
    type: 'ADD_ITEM_BASKET',
    payload: item,
  };
};

export const countItem = (id, operator, item) => {
  return {
    type: 'COUNT_ITEM',
    payload: id,
    operator,
    item,
  };
};

export const onEmptyBasket = () => {
  return {
    type: 'ON_EMPTY_BASKET',
  };
};

export const errorStatus = () => {
  return {
    type: 'ERROR',
  };
};

export const getUserData = (data) => {
  return {
    type: 'GET_USER_DATA',
    payload: data,
  };
};

export const delAdressDeliveryBasket = (id) => {
  return {
    type: 'DEL_ADRESS_DELIVERY_BASKET',
    payload: id,
  };
};

export const logOut = () => {
  return {
    type: 'LOG_OUT',
  };
};

export const bodyOverflowToCard = (state) => {
  return {
    type: 'BODY_OVERFLOW_TO_CARD',
    state,
  };
};
export const addBasketBshop = (obj) => {
  return {
    type: 'ADD_ITEM_BASKET_BSHOP',
    payload: obj,
  };
};

export const removeBasketBshop = (obj) => {
  return {
    type: 'REMOVE_ITEM_BASKET_BSHOP',
    payload: obj,
  };
};

export const onSearchItemBshop = (value) => {
  return {
    type: 'ON_SEARCH_ITEM_BSHOP',
    payload: value,
  };
};

export const onSearchItemPubl = (value) => {
  return {
    type: 'ON_SEARCH_ITEM_PUBL',
    payload: value,
  };
};

export const onSearchItemAllServ = (value) => {
  return {
    type: 'ON_SEARCH_ITEM_ALL_SERV',
    payload: value,
  };
};

export const onSearchItemAllServRequests = (value) => {
  return {
    type: 'ON_SEARCH_ITEM_ALL_SERV_REQUESTS',
    payload: value,
  };
};

export const onShopGo = (value) => {
  return {
    type: 'TO_SHOP',
    payload: value,
  };
};
export const onItemGo = (value) => {
  return {
    type: 'TO_ITEM',
    payload: value,
  };
};

export const onScrollCatalog = (value) => {
  return {
    type: 'TO_SCROLL_CATALOG',
    payload: value,
  };
};

export const onMastersCategory = (value) => {
  return {
    type: 'TO_MASTERS_CATEGORY',
    payload: value,
  };
};

export const onAddItemBshop = (value) => {
  return {
    type: 'TO_ADD_ITEM_BSHOP',
    payload: value,
  };
};

export const onAddPubl = (value) => {
  return {
    type: 'TO_ADD_ITEM_PUBL',
    payload: value,
  };
};

export const onAddAllServ = (value) => {
  return {
    type: 'TO_ADD_ITEM_ALL_SERV',
    payload: value,
  };
};

export const onAddAllServR = (value) => {
  return {
    type: 'TO_ADD_ITEM_ALL_SERV_R',
    payload: value,
  };
};

export const onAddAllAcceptedServR = (value) => {
  return {
    type: 'TO_ADD_ITEM_ALL_ACCEPTED_SERV_R',
    payload: value,
  };
};

export const onAddAllMasterServR = (value) => {
  return {
    type: 'TO_ADD_ITEM_ALL_MASTER_SERV_R',
    payload: value,
  };
};

export const onAddMasterForClientInfo = (value) => {
  return {
    type: 'TO_ADD_MASTER_FOR_CLIENT_INFO',
    payload: value,
  };
};

export const onAddAllServForClientReq = (value) => {
  return {
    type: 'TO_ADD_ITEM_ALL_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddChildAllServForClientReq = (value) => {
  return {
    type: 'TO_ADD_CHILD_ITEM_ALL_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddAllSDServsForClientReq = (value) => {
  return {
    type: 'TO_ADD_ITEM_ALL_SECOND_DOP_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddChildAllSDServsForClientReq = (value) => {
  return {
    type: 'TO_ADD_CHILD_ITEM_ALL_SECOND_DOP_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddServForClientReq = (value) => {
  return {
    type: 'TO_ADD_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddServForClientReq_1 = (value) => {
  return {
    type: 'TO_ADD_1_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddServForClientReq_11 = (value) => {
  return {
    type: 'TO_ADD_11_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddServForClientReq_2 = (value) => {
  return {
    type: 'TO_ADD_2_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddServForClientReq_21 = (value) => {
  return {
    type: 'TO_ADD_21_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddServForClientReq_3 = (value) => {
  return {
    type: 'TO_ADD_3_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddServForClientReq_31 = (value) => {
  return {
    type: 'TO_ADD_31_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddServForClientReq_4 = (value) => {
  return {
    type: 'TO_ADD_4_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddServForClientReq_41 = (value) => {
  return {
    type: 'TO_ADD_41_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddServForClientReq_5 = (value) => {
  return {
    type: 'TO_ADD_5_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddServForClientReq_51 = (value) => {
  return {
    type: 'TO_ADD_51_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onAddAllServForClientMainReq = (value) => {
  return {
    type: 'TO_ADD_ITEM_ALL_SERV_FOR_CLIENT_MAIN_REQ',
    payload: value,
  };
};

export const AddCountItemBshop = (value) => {
  return {
    type: 'TO_ADD_COUNT_ITEMS_BSHOP',
    payload: value,
  };
};

export const AddCountItemPubl = (value) => {
  return {
    type: 'TO_ADD_COUNT_ITEMS_PUBL',
    payload: value,
  };
};

export const AddCountItemAllServ = (value) => {
  return {
    type: 'TO_ADD_COUNT_ITEMS_ALL_SERV',
    payload: value,
  };
};

export const AddCountItemAllServR = (value) => {
  return {
    type: 'TO_ADD_COUNT_ITEMS_ALL_SERV_R',
    payload: value,
  };
};

export const DisplayType = (value) => {
  return {
    type: 'DISPLAY_FLEX',
    payload: value,
  };
};

export const DisplayType1 = (value) => {
  return {
    type: 'DISPLAY_FLEX1',
    payload: value,
  };
};

export const DisplayType2 = (value) => {
  return {
    type: 'DISPLAY_FLEX2',
    payload: value,
  };
};

export const DisplayType3 = (value) => {
  return {
    type: 'DISPLAY_FLEX3',
    payload: value,
  };
};

export const DisplayType4 = (value) => {
  return {
    type: 'DISPLAY_FLEX4',
    payload: value,
  };
};

export const DopDisplayType = (value) => {
  return {
    type: 'DOP_DISPLAY_FLEX',
    payload: value,
  };
};

export const PageDisplayType1 = (value) => {
  return {
    type: 'PAGE_DISPLAY_FLEX1',
    payload: value,
  };
};

export const PageDisplayType2 = (value) => {
  return {
    type: 'PAGE_DISPLAY_FLEX2',
    payload: value,
  };
};

export const PageDisplayType3 = (value) => {
  return {
    type: 'PAGE_DISPLAY_FLEX3',
    payload: value,
  };
};

export const PageDisplayType4 = (value) => {
  return {
    type: 'PAGE_DISPLAY_FLEX4',
    payload: value,
  };
};

export const PageDopDisplayType = (value) => {
  return {
    type: 'PAGE_DOP_DISPLAY_FLEX',
    payload: value,
  };
};

export const accountBeauty = (value) => {
  return {
    type: 'TO_ON_ACCOUNT_BEAUTY',
    payload: value,
  };
};

export const onPBCborderBottom = (value) => {
  return {
    type: 'TO_ON_PBC_BORDER_BOTTOM',
    payload: value,
  };
};

export const onPBCborderImage = (value) => {
  return {
    type: 'TO_ON_PBC_BORDER_IMAGE',
    payload: value,
  };
};

export const onPBCcolor = (value) => {
  return {
    type: 'TO_ON_PBC_COLOR',
    payload: value,
  };
};

export const onPBCfontWeight = (value) => {
  return {
    type: 'TO_ON_PBC_FONT_WEIGHT',
    payload: value,
  };
};

export const onPBCmenu = (value) => {
  return {
    type: 'TO_ON_PBC_MENU',
    payload: value,
  };
};

export const onServMastersMenu = (value) => {
  return {
    type: 'TO_ON_SERVMASTERS_MENU',
    payload: value,
  };
};

export const onSaveAndAddServForClientReq = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientReq_1 = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_1_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientReq_11 = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_11_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientReq_2 = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_2_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientReq_21 = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_21_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientReq_3 = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_3_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientReq_31 = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_31_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientReq_4 = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_4_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientReq_41 = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_41_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientReq_5 = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_5_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientReq_51 = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_51_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientDate = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_DATE_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientTime = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_TIME_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const onSaveAndAddServForClientPrice = (value) => {
  return {
    type: 'TO_SAFE_AND_ADD_PRICE_ITEM_SERV_FOR_CLIENT_REQ',
    payload: value,
  };
};

export const setauthDisplay = (value) => {
  return {
    type: 'TO_SET_AUTHDISPLAY',
    payload: value,
  };
};

export const setAllPageMasterCats = (value) => {
  return {
    type: 'TO_SET_AllPageMasterCats',
    payload: value,
  };
};

export const setAllPageMasterServices = (value) => {
  return {
    type: 'TO_SET_AllPageMasterServices',
    payload: value,
  };
};

export const setOptionalPageMasterServices = (value) => {
  return {
    type: 'TO_SET_OptionalPageMasterServices',
    payload: value,
  };
};

export const setAllPageMasterData = (value) => {
  return {
    type: 'TO_SET_AllPageMasterData',
    payload: value,
  };
};

export const setOptionalPageMasterDopServices = (value) => {
  return {
    type: 'TO_SET_OptionalPageMasterDopServices',
    payload: value,
  };
};

export const setCalendarReqDay = (value) => {
  return {
    type: 'TO_SET_CalendarReqDay',
    payload: value,
  };
};

export const setSortedDateTime = (value) => {
  return {
    type: 'TO_SET_SortedDateTime',
    payload: value,
  };
};

export const setprofileBounds = (value) => {
  return {
    type: 'TO_SET_profileBounds',
    payload: value,
  };
};

export const setSlippedRecordsNum = (value) => {
  return {
    type: 'TO_SET_SlippedRecordsNum',
    payload: value,
  };
};
