import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import beautyApi from '../../../api/beautyApi';
import { onAddAllServR, setCalendarReqDay } from '../../../redux/action';

import s from './GeneralCalendar.module.css';

import MyRequestForCalendar from '../ItemClientReq/MyRequestForCalendar';

import Calendar from 'react-calendar';
import InfiniteScroll from 'react-infinite-scroll-component';
import uniqid from 'uniqid';
import dayjs from 'dayjs';
import moment from 'moment';

const GeneralCalendar = ({}) => {
  const options = { month: 'long', day: 'numeric' };

  const dispatch = useDispatch();

  const myId = useSelector((state) => state.accountBeauty.id);
  const AllServR = useSelector((state) => state.itemsAllServR);
  const CalendarReqDay = useSelector((state) => state.CalendarReqDay);

  const [acceptDate, setAcceptDate] = React.useState(new Date().toISOString().slice(0, 10));
  const [acceptDefDate, setAcceptDefDate] = React.useState(
    new Date().toLocaleDateString('ru-ru', options),
  );

  var arrObjects_CalendarReqDay = [];
  var arrObjects_AllServR = [];

  React.useEffect(() => {
    getServReq(myId).then((data) => SortData(data));
  }, []);

  const makeDate = (
    id,
    orderName,
    orderName2,
    orderName3,
    orderName4,
    orderName5,
    date,
    time,
    price,
    comm,
    clientId,
    Adress,
    masterFIO,
    Phone,
    SalonName,
  ) => {
    return {
      id: id,
      orderName: orderName,
      orderName2: orderName2,
      orderName3: orderName3,
      orderName4: orderName4,
      orderName5: orderName5,
      date: date,
      time: time,
      price: price,
      comm: comm,
      clientId: clientId,
      Adress: Adress,
      masterFIO: masterFIO,
      Phone: Phone,
      SalonName: SalonName,
    };
  };

  const getServReq = async (myId) => {
    const fd = new FormData();
    fd.append('clientId', myId);
    const data = await beautyApi.pullCalendar(fd);
    return data;
  };

  const SortData = (responseAllServR) => {
    for (let i = 0; i < responseAllServR.length; i++) {
      let data = makeDate(
        responseAllServR[i]['id'],
        responseAllServR[i]['orderName'],
        responseAllServR[i]['orderName2'],
        responseAllServR[i]['orderName3'],
        responseAllServR[i]['orderName4'],
        responseAllServR[i]['orderName5'],
        responseAllServR[i]['date'],
        responseAllServR[i]['time'],
        responseAllServR[i]['price'],
        responseAllServR[i]['comm'],
        responseAllServR[i]['clientId'],
        responseAllServR[i][0].master['Adress'],
        responseAllServR[i][0].master['FIO'],
        responseAllServR[i][0].master['Phone'],
        responseAllServR[i][0].master['SalonName'],
      );
      arrObjects_CalendarReqDay[i] = new Date(responseAllServR[i]['date']);
      arrObjects_AllServR[i] = data;
    }

    dispatch(setCalendarReqDay(arrObjects_CalendarReqDay.reverse()));
    dispatch(onAddAllServR(arrObjects_AllServR.reverse()));
  };

  const callDay = (clikedDay) => {
    let day = dayjs(clikedDay).format('YYYY-MM-DD');
    setAcceptDefDate(new Date(day).toLocaleDateString('ru-ru', options));
    setAcceptDate(dayjs(clikedDay).format('YYYY-MM-DD'));
  };

  return (
    <div
      // style={{ textAlign: 'center' }}
      className={s.content}
    >
      {/* {console.log(CalendarReqDay)} */}
      <Calendar
        onClickDay={(value) => callDay(value)}
        minDetail='month'
        // className={CalendarCss}
        next2Label={null}
        prev2Label={null}
        tileClassName={({ date, view }) => {
          if (CalendarReqDay.find((x) => String(x).slice(0, 15) == String(date).slice(0, 15))) {
            return s.highlight;
            // console.log('________________');
          }
        }}
        // value={CalendarReqDay.length > 0 ? CalendarReqDay : null}
      />

      <div className={s.line}></div>
      {/* {console.log(acceptDefDate)}
      {console.log(acceptDate)} */}
      <div className={s.clikedDay}>{acceptDefDate}</div>

      {AllServR.length > 0 ? (
        <InfiniteScroll
          // style={{}}
          dataLength={AllServR.length}
          // next={fetchNewItems}
          // hasMore={true}
          loader={
            <div style={{ width: '50%' }}>
              {/* {Array.from(Array(4), (_, i) => (
                        <LoadingBlock key={uniqid()} />
                      ))} */}
            </div>
          }
          sscrollableTarget='scrollableDiv'
        >
          {AllServR.map((item) => (
            <MyRequestForCalendar key={uniqid()} {...item} />
          ))}
        </InfiniteScroll>
      ) : (
        <>
          <p style={{ marginLeft: '20px' }}>Вы еще не создали ни одной заявки &#9203;</p>
        </>
        // Array.from(Array(4), (_) => <ServicesLoadingBlock key={uniqid()} />)
      )}
    </div>
  );
};

export default GeneralCalendar;
