import React, { Component } from 'react';

import { connect } from 'react-redux'

import { Link } from 'react-router-dom';

import { IMaskInput } from "react-imask";

import Button from '../companents/button';
import Alert from '../companents/alert';
import SliderSwiper from '../companents/slider'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './style.css'

import geo from '../asseets/geo.png'
import card from '../asseets/e-card.png'
// import food from '../asseets/food.png'
import arrow from '../asseets/arrow-bottom.png'
import shop from '../asseets/shop.png'

class ShoppingBacket extends Component {

    state = {
        showBasketItems: true,
        showChoiceDetailOrder: false,

        showAlert: false,
        showAlertInfoDelivery: false,
        showAlertDelivery: false,
        showConfirmtWithDelLastItem: false,

        selectedAdress: null,

        countFlatware: 0,

        deliveryInfo: {
            adress: '',
            street: '',
            apartment: '',
            floor: '',
            entrance: '',
            intercom: false,
            privethouse: false,
            bill: '',
            comments: '',
            typePayment: '',
        },
        user: {
            name_user: '',
            phone_user: '',
        }
    }

    //Получение адресов доставки пользователя
    async componentDidMount() {
        if (localStorage.getItem('access_token')) {
            const formData = await new FormData();
            await formData.append('user_id', Object.assign({}, ...this.props.user).id)
            await this.props.getData('adressFromUserDelivery', 'user.adress.get', formData)
        }
    }

    handleChange = async (e) => {
        if (e.target.name === 'privethouse') await this.setState({ ...this.state, deliveryInfo: { ...this.state.deliveryInfo, [e.target.name]: e.target.checked } });
        else if (e.target.name === 'intercom') await this.setState({ ...this.state, deliveryInfo: { ...this.state.deliveryInfo, [e.target.name]: e.target.checked } });
        else await this.setState({ ...this.state, deliveryInfo: { ...this.state.deliveryInfo, [e.target.name]: e.target.value } });
    }

    handleChangeAlert = (e) => {
        this.setState({ ...this.state, user: { ...this.state.user, [e.target.name]: e.target.value } })
    }

    handleChangeAlertPhone = (phone) => {
        this.setState({ ...this.state, user: { ...this.state.user, phone_user: phone } })
    }

    handleChangeDataType = (e) => {
        this.setState({ ...this.state, deliveryInfo: { ...this.state.deliveryInfo, typePayment: e.target.dataset.type } })
    }

    //ставит значение в инпуты адреса
    replacementAdress = (el) => {
        this.setState(
            {
                ...this.state,
                deliveryInfo: {
                    ...this.state.deliveryInfo, adress: el.adress, street: el.street, apartment: el.apartmetnts, floor: el.floor, entrance: el.entrance
                }
            }
        )
    }

    countItemBasket = (id, operator, item) => {
        if (this.props.basket.length === 1) {
            this.setState({ showConfirmtWithDelLastItem: true })
        } else {
            this.props.countItem(id, operator, item)
        }
    }

    onEmptyBasket = () => this.props.onEmptyBasket()

    orderDelivery = () => {
        if (!this.state.deliveryInfo.adress || !this.state.deliveryInfo.street) {
            this.setState({ showAlertInfoDelivery: true })
        } else {
            if (localStorage.getItem('access_token')) {
                this.props.orderDelivery(this.state.deliveryInfo)
                this.setState({ showAlertDelivery: true })
            } else {
                this.setState({ showAlert: true })
            }
        }
    }

    closeAlert = (state) => {
        const val = state
        this.setState({ [val]: false })
        if (val === 'showAlertDelivery') this.props.onEmptyBasket()
        if (val === 'showAlert') {
            this.setState({ [val]: false })
            this.props.regNewUser({ name_user: this.state.user.name_user, phone_user: this.state.user.phone_user.replace(/\D/g, "") })
        }
        this.setState({ showBasketItems: true, showChoiceDetailOrder: false })
    }

    showChoiceDetailOrder = (val) => {
        this.setState({ [val]: !this.state[val] })
        if (val === 'change') {
            this.setState({ showBasketItems: false, showChoiceDetailOrder: true })
        }
    }

    countMinus = () => {
        if (this.state.countFlatware < 1) return
        this.setState({ countFlatware: this.state.countFlatware - 1 })
    }

    countPlus = () => this.setState({ countFlatware: this.state.countFlatware + 1 })

    delAdressDeliveryBasket = (id) => this.props.delAdressDeliveryBasket(id)

    render() {
        const { basket, totalPrice, deliveryPrice, adressFromUserDelivery } = this.props;

        const formReg = [
            { title: 'Имя', value: 'name_user', placeholder: 'Ваше имя', type: 'text', name: 'name_user' },
            { title: 'Номер телефона', value: 'phone_user', placeholder: 'Номер телефона', type: 'text', name: 'phone_user' }
        ]

        const payment = [
            { option: 'Наличные', data: 'cash' },
            { option: 'Безналичные', data: 'card' }
        ];

        const adressDelivery = [
            { title: '', value: 'adress', placeholder: 'Улицу', type: 'text', name: 'adress' },
            { title: '', value: 'street', placeholder: 'Дом', type: 'text', name: 'street' },
            { title: '', value: 'entrance', placeholder: 'Подъезда', type: 'text', name: 'entrance' },
            { title: '', value: 'apartment', placeholder: 'Квартиры', type: 'text', name: 'apartment' },
            { title: '', value: 'floor', placeholder: 'Этаж', type: 'text', name: 'floor' },
            { title: 'Домофон', value: 'intercom', placeholder: 'Домофон', type: 'checkbox', name: 'intercom' },
        ];
        const showChoiceDetailOrder = this.state.showChoiceDetailOrder ? 'choice-delivery ' : 'choice-delivery  none';
        const showBasketItems = this.state.showBasketItems ? 'basket-main' : 'basket-main none'
        console.log(this.state.user);
        return (
            <div className='backet'>

                {basket.length
                    ? <div className='backet-wrapper'>

                        <div className='shop'>
                            <div className='shop-text' onClick={() => this.showChoiceDetailOrder('showBasketItems')}>
                                <h3>Корзина</h3>
                                <img src={arrow} alt='arrow' />
                            </div>

                            <div className={showBasketItems}>
                                <div className='clear-basket'>
                                    <span onClick={this.onEmptyBasket} >Очистить</span>
                                </div>

                                <div className='shop-data'>
                                    <div className='shop-data-items'>

                                        {basket.map(el => {
                                            const image = `https://bikli.kz/imgProduct/${el.image}`;
                                            return (
                                                <div className='item' key={el.id}>
                                                    <div className='item-name'>
                                                        <img src={image} alt='foto' />
                                                        <p>{el.nameeda}</p>
                                                    </div>
                                                    <div className='item-num'>
                                                        {el.count === 0
                                                            ? <Button class='btn-backet-minus none-btn' />
                                                            : <Button value='-' class='btn-backet-minus' click={() => this.countItemBasket(el.id, '-', el)} />}
                                                        <p>{el.count}</p>
                                                        <Button value='+' class='btn-backet-plus' click={() => this.countItemBasket(el.id, '+', el)} />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        <div className='total'>
                                            <div>
                                                <span>Сумма заказа</span>
                                                <span>{totalPrice}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='line'></div>

                        {/* <div className='info-buy'>
                            <h3>Детали заказа</h3>
                            <div className='address'>
                                <div>
                                    <img src={food} alt='geo' />
                                    <span>Персоны</span>
                                </div>
                                <div className='item-num'>
                                    <Button value='-' class='btn-backet-minus-card' click={this.countMinus} />
                                    <p>{this.state.countFlatware}</p>
                                    <Button value='+' class='btn-backet-plus-card' click={this.countPlus} />
                                </div>
                            </div>
                        </div> */}

                        <div className='info-buy'>
                            <div className='address' onClick={() => this.showChoiceDetailOrder('showChoiceDetailOrder')} >
                                <h3>Ваши данные</h3>
                                <img src={arrow} alt='arrow' />
                            </div>

                            <div className={showChoiceDetailOrder}>
                                <div className='address'>
                                    <div>
                                        <img src={geo} alt='geo' />
                                        <span>Адрес доставки</span>
                                    </div>
                                </div>

                                <div className='adressUserFromDelivery'>
                                    <SliderSwiper>
                                        {adressFromUserDelivery.map(el => {
                                            return (
                                                <div className='adressUserFromDelivery-item' key={el.id}>
                                                    <div>
                                                        <p onClick={() => this.replacementAdress(el)}>{el.adr.length > 5 ? el.adr.split("").slice(0, 6).join("").concat("...") : el.adr} {el.street}</p>
                                                        <Button value='+' class='btn_del_adress_basket' click={() => this.delAdressDeliveryBasket(el.id)} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </SliderSwiper>
                                </div>
                                <div >

                                    <div className='cabinet-form-item-switch'>
                                        <span>Частный дом</span>
                                        <input type='checkbox' name='privethouse' value={this.state.cottedge} autoComplete='off' onChange={this.handleChange} />
                                    </div>

                                    <form onSubmit={e => e.preventDefault()} className='profile-add-adress-form'>
                                        {(this.state.deliveryInfo.privethouse ? adressDelivery.slice(0, 2) : adressDelivery).map((el, i) => {
                                            return (
                                                <div key={i} className='cabinet-form-item-shoop'>
                                                    <span>{el.title}</span>
                                                    <input type={el.type} name={el.value} value={this.state.deliveryInfo[el.value]} autoComplete='off' onChange={this.handleChange} placeholder={el.placeholder} />
                                                </div>
                                            )
                                        })}
                                    </form>
                                </div>

                                <div className='address'>
                                    <div>
                                        <img src={card} alt='geo' />
                                        <span>Способ оплаты</span>
                                    </div>
                                </div>
                                <form onSubmit={e => e.preventDefault()} className='profile-payment-form'>
                                    <div className='profile-payment-form-map'>
                                        {payment.map((el, i) => {
                                            return (
                                                <div className='choice-payment' key={i} data-type={el.data}
                                                    onClick={this.handleChangeDataType}>
                                                    {el.option}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {this.state.deliveryInfo.typePayment === 'cash'
                                        ? <input type='number' placeholder='Купюра оплаты' name='bill' value={this.state.bill} onChange={this.handleChange} />
                                        : null}

                                    <div className='comment-input'>
                                        <input type='text' className='comment' name='comments' onChange={this.handleChange}
                                            placeholder='Комментарий к заказу' autoComplete='off' />
                                    </div>

                                </form>
                            </div>
                        </div>

                        <div className='info-buy-price'>
                            <h3>Итого к оплате</h3>

                            <div className='total'>
                                <div>
                                    <span>Сумма доставки</span>
                                    <span>{deliveryPrice}</span>
                                </div>
                                <div>
                                    <span>Итоговая сумма</span>
                                    <span>{deliveryPrice + totalPrice}</span>
                                </div>
                            </div>
                        </div>

                        {this.state.showBasketItems
                            ? <Button value='Далее' class='btn-pay' click={() => this.showChoiceDetailOrder('change')} />
                            : <Button value='Оплатить заказ' class='btn-pay' click={this.orderDelivery} />}

                        {this.state.showAlert
                            ? <Alert>
                                <div className='alert-wrapper'>
                                    <form onSubmit={e => e.preventDefault()}>
                                        {formReg.map((el, i) => {
                                            return (
                                                <div key={i} className='alert-form-item'>
                                                    {/* <span>{el.title}</span> */}
                                                    {el.value === 'phone_user'
                                                        ? <IMaskInput
                                                            mask="+{7}(000)000-00-00" radix="." lazy={false} unmask={true} overwrite={true} className='alert-input'
                                                            inputRef={(el) => (this.input = el)} onAccept={(value, mask) => this.handleChangeAlertPhone(value)} />
                                                        // <MaskedInput className='alert-input' mask="+7(***) *** - ****" onChange={this.handleChangeAlert} name={el.name} />
                                                        : <input className='alert-input' type={el.type} name={el.value} autoComplete='off' onChange={this.handleChangeAlert} placeholder={el.placeholder} />}
                                                </div>
                                            )
                                        })}
                                    </form>

                                    <Link to={`/auth/${this.state.user.phone_user}`}>
                                        <Button value='АВТОРИЗАЦИЯ' class='btn_reg_cabinet' click={() => this.closeAlert('showAlert')} />
                                    </Link>
                                </div>
                            </Alert>
                            : null}

                        {this.state.showAlertDelivery
                            ? <Alert>
                                <div className='alert-wrapper'>
                                    <h2>Ваш заказ принят</h2>
                                    <p>За изменением статуса заказа вы можете наблюдать в разделе "Исторя".</p>

                                    <Link to='/history'>
                                        <Button value='ИСТОРИЯ' class='btn_reg_cabinet' click={() => this.closeAlert('showAlertDelivery')} />
                                    </Link>

                                    <Button value='ЗАКРЫТЬ' class='btn_reg_cabinet' click={() => this.closeAlert('showAlertDelivery')} />
                                </div>
                            </Alert>
                            : null}

                        {this.state.showAlertInfoDelivery
                            ? <Alert>
                                <div className='alert-wrapper'>
                                    <h2>Ошибка</h2>
                                    <p>Заполните все поля раздела "Ваши данные"</p>

                                    <Button value='ЗАКРЫТЬ' class='btn_reg_cabinet' click={() => this.closeAlert('showAlertInfoDelivery')} />
                                </div>
                            </Alert>
                            : null}

                        {this.state.showConfirmtWithDelLastItem
                            ? <Alert>
                                <div className='alert-wrapper'>
                                    <p>Вы точно хотите очистить корзину?</p>

                                    <div className='confirm-del-last-item'>
                                        <Button value='ДА' class='btn_reg_cabinet' click={() => this.onEmptyBasket()} />
                                        <Button value='НЕТ' class='btn_reg_cabinet' click={() => this.setState({ showConfirmtWithDelLastItem: false })} />
                                    </div>
                                </div>
                            </Alert>
                            : null}

                    </div >

                    : <div className='backet-wrapper'>
                        <div className='backet-empty'>
                            <img src={shop} alt='' />
                            <p>В корзине нет товара</p>
                        </div>
                    </div>
                }

            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingBacket);
