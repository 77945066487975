import { Component } from "react";
import Slider from "react-slick";

import './style.css'

class SliderSwiper extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3.3,
            slidesToScroll: 1,
            arrows: false,
            pauseOnFocus: true,
            pauseOnHover: false
        };

        const settingsSlider = this.props.main ? {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnFocus: true,
            pauseOnHover: false
        } : settings

        return (
            <div className="s">
                <Slider {...settingsSlider}>
                    {this.props.children}
                </Slider>
            </div >
        );
    }
};


export default SliderSwiper