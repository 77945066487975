import React from 'react';
import s from './orderDoneModal.module.css';

const orderDoneModal = ({ setResponseOrder, modalOpen }) => {
  const onClickClose = (e) => {
    modalOpen.current !== e.target && setResponseOrder(false);
  };
  return (
    <div className={s.wrapperModalorderDone} onClick={(e) => onClickClose(e)}>
      <div className={s.modalOrderDone}>
        <div ref={modalOpen} className={s.modal}>
          <div className={s.titleModal}>Ваш заказ оформлен!</div>
          <button onClick={() => setResponseOrder(false)}>Хорошо</button>
        </div>
      </div>
    </div>
  );
};

export default orderDoneModal;
