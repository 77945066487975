import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { onServMastersMenu } from '../../../redux/action';
import uniqid from 'uniqid';
import beautyApi from '../../../api/beautyApi';
import ServicesOfMaster from '../../../companents/headerNew/PageMasterContentComponents/ServicesOfMaster/ServicesOfMaster';
import ContentLoader from 'react-content-loader';

import s from '../pageMaster.module.css';
import BlogOfMaster from '../../../companents/headerNew/PageMasterContentComponents/BlogOfMaster/BlogOfMaster';

const PageMasterContent = ({}) => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);

  const tabs = ['услуги', 'блог', 'акции', 'отзывы'];

  const [dataMaster, setDataMaster] = useState();
  const [dataBlog, setDataBlog] = useState();

  const urlPath = window.location.search
    .replace('?', '')
    .split('&')
    .reduce(function (p, e) {
      const a = e.split('=');
      p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
      return p;
    }, {});

  useEffect(() => {
    getDataMaster(urlPath.id);
    dispatch(onServMastersMenu('Мастера'));
  }, []);

  const getDataMaster = async (id) => {
    const fd = new FormData();
    fd.append('id', id);
    const response = await beautyApi.MyAcceptedRequestMaster(id);
    await setDataMaster(response);
    const responseBlog = await beautyApi.fetchBlogMaster(fd);
    setDataBlog(responseBlog);
  };

  return (
    <div className={s.content}>
      <div className={s.content_address}>
        <div className={s.content_address_geo}>
          <svg
            width='16'
            height='18'
            viewBox='0 0 16 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14.0909 7.54545C14.0909 12.6364 7.54545 17 7.54545 17C7.54545 17 1 12.6364 1 7.54545C1 5.80949 1.68961 4.14463 2.91712 2.91712C4.14463 1.68961 5.80949 1 7.54545 1C9.28142 1 10.9463 1.68961 12.1738 2.91712C13.4013 4.14463 14.0909 5.80949 14.0909 7.54545Z'
              stroke='url(#paint0_linear_102_5165)'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M7.54545 9.72727C8.75044 9.72727 9.72727 8.75044 9.72727 7.54545C9.72727 6.34047 8.75044 5.36364 7.54545 5.36364C6.34047 5.36364 5.36364 6.34047 5.36364 7.54545C5.36364 8.75044 6.34047 9.72727 7.54545 9.72727Z'
              stroke='url(#paint1_linear_102_5165)'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <defs>
              <linearGradient
                id='paint0_linear_102_5165'
                x1='14.1011'
                y1='0.999999'
                x2='0.272843'
                y2='1.66492'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#D432D8' />
                <stop offset='0.515625' stopColor='#FF608D' />
                <stop offset='1' stopColor='#F4A582' />
              </linearGradient>
              <linearGradient
                id='paint1_linear_102_5165'
                x1='14.1011'
                y1='0.999999'
                x2='0.272843'
                y2='1.66492'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#D432D8' />
                <stop offset='0.515625' stopColor='#FF608D' />
                <stop offset='1' stopColor='#F4A582' />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className={s.content_address_text}>{dataMaster ? dataMaster[0].Adress : '-'}</div>
      </div>
      <div className={s.tabs_master}>
        <div className={s.name_tabs}>
          {tabs.map((item, key) => (
            <>
              <div
                key={uniqid()}
                className={key === tab ? s.active_tab : s.nonactive_tab}
                // onClick={() => setTab(key)}
              >
                {item}
              </div>
            </>
          ))}
        </div>
        <div className={s.tabs}>
          {tab === 0 && <ServicesOfMaster masterID={urlPath.id} />}

          {tab === 1 && <BlogOfMaster />}
          {tab === 2 && <div className={s.tab}>акции</div>}
          {tab === 3 && <div className={s.tab}>отзывы</div>}
        </div>
      </div>
    </div>
  );
};

export default PageMasterContent;
