import React, { Component } from 'react';

import { IMaskInput } from "react-imask";

import { Link } from 'react-router-dom';

import Button from '../companents/button'
// import Alert from '../companents/alert';

import './style.css'

class Cabinet extends Component {

    state = {
        name_user: '',
        phone_user: '',

        showAlert: false
    }

    handleChange = async (e) => {
        if (e.target.name === 'phone_user') await this.setState({ [e.target.name]: e.target.value })
        else await this.setState({ [e.target.name]: e.target.value });
    }

    handlePhone = (phone) => {
        this.setState({ phone_user: phone })
    }

    regNewUser = () => {
        const data = { name_user: this.state.name_user, phone_user: this.state.phone_user.replace(/\D/g, "") }
        this.props.regNewUser({ ...data });
        for (const key in this.state) {
            this.setState({ [key]: '' })
        }
        this.setState({ showAlert: true })
    }

    closeAlert = (state) => {
        const val = state
        this.setState({ [val]: false })
    }

    render() {
        const formReg = [
            { title: 'Имя', value: 'name_user', placeholder: 'Ваше имя', type: 'text', name: 'name_user' },
            { title: 'Номер телефона', value: 'phone_user', placeholder: 'Номер телефона', type: 'text', name: 'phone_user' }
        ]
        console.log(this.state);
        return (
            <div className='cabinet'>
                <div className='cabinet-wrapper'>
                    <div className='cabinet-text'>
                        <h3>Регистрация/Авторизация</h3>
                    </div>

                    <div className='cabinet-items'>

                        <div className='cabinet-form-reg'>
                            <form onSubmit={e => e.preventDefault()} className='cabinet-form'>
                                <div className='form-inner'>
                                    {formReg.map((el, i) => {
                                        const type = el.type === 'text' ? 'cabinet-form-item' : 'cabinet-form-item-switch'
                                        return (
                                            <div key={i} className={type}>
                                                <span>{el.title}</span>
                                                {el.value === 'phone_user'
                                                    ? <IMaskInput
                                                        mask="+{7}(000)000-00-00" radix="." lazy={false} unmask={true} overwrite={true}
                                                        inputRef={(el) => (this.input = el)} onAccept={(value, mask) => this.handlePhone(value)} />
                                                    : <input type={el.type} name={el.value} value={this.state[el.value]} autoComplete='off' onChange={this.handleChange} placeholder={el.placeholder} />}
                                            </div>
                                        )
                                    })}
                                </div>
                                <Link to='/auth' className='link'>
                                    <Button value='РЕГИСТРАЦИЯ / АВТОРИЗАЦИЯ' class='btn_reg_cabinet' click={this.regNewUser} />
                                </Link>
                            </form>
                        </div>

                        {/* {this.state.showAlert
                            ? <Alert>
                                <div className='alert-wrapper'>
                                    <h2>Поздравляем!</h2>
                                    <p>Вы зарегистрировались! Пройдите Авторизацию</p>

                                    <Link to='/auth' className='link'>
                                        <Button value='АВТОРИЗАЦИЯ' class='btn_reg_cabinet' click={() => this.closeAlert('showAlert')} />
                                    </Link>
                                </div>
                            </Alert>
                            : null} */}

                    </div>
                </div>
            </div>
        );
    }
}

export default Cabinet;