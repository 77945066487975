import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import { useDispatch } from 'react-redux';

import s from './oneItem.module.css';
import ButtonOrange from '../../buttonOrange/ButtonOrange';
import shop from './Rectangle 30.png';
import bshopApi from '../../../../api/bshopApi';
import LoadingBlock from './LoadingBlock';
import uniqid from 'uniqid';
import { addBasketBshop } from '../../../../redux/action';

const OneItem = () => {
  const dispatch = useDispatch();
  const [item, setItem] = useState([]);
  const [sizesItem, setSizesItem] = useState(null);
  const [onClickSize, setOnClickSize] = useState('');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    getInfo(urlPath.id, urlPath.shopid);
  }, []);

  useEffect(() => {
    getSizes(item.shopid, item.name);
    console.log(item);
  }, [item]);

  const getInfo = async (id, shopId) => {
    const responseItem = await bshopApi.fetchItem(id, shopId);
    setItem(responseItem[0]);
  };

  const getSizes = async (shopId, name) => {
    const responseSizes = await bshopApi.fetchSizes(shopId, name);
    setSizesItem(responseSizes);
  };

  const urlPath = window.location.search
    .replace('?', '')
    .split('&')
    .reduce(function (p, e) {
      var a = e.split('=');
      p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
      return p;
    }, {});

  const picture = [];

  picture.push(item?.photo);
  const massSizes = (size, id) => {
    const obj = {
      size: size,
      id: id,
    };
    setOnClickSize(obj);
  };

  const addToBasketBshop = (item) => {
    dispatch(
      addBasketBshop({
        shopId: [item.shopid],
        shopName: [item.shopname],
        catId: [item.catId],
        id: [item.id],
        nameItem: [item.name],
        photo: [item.photo],
        price: [item.price],
        size: [onClickSize.size],
        skidka: [item.skidka],
        sku: [item.sku],
      }),
    );
    setOnClickSize([]);
  };
  return (
    <>
      {item.name ? (
        <div className={s.root}>
          <div className={s.carousel}>
            {/* <Carousel showArrows={false} showStatus={false} showThumbs={false}>
              {picture?.map((item, i) => {
                return (
                  <div key={uniqid()}>
                    <img src={`https://www.bshop.kz/photos/${item}`} alt="item" />
                  </div>
                );
              })}
            </Carousel> */}
            <div>
              {item.photo === '' ? (
                <img src={`https://bshop.kz/photos/1643275201.jpeg`} alt="item" />
              ) : (
                <img src={`https://www.bshop.kz/photos/${item.photo}`} alt="item" />
              )}
            </div>
          </div>
          <div className={s.name_item_price}>
            <div>
              <div className={s.name_item}>{item?.name}</div>
              <div className={s.price_item}>
                {item && `Цена: ${Number(item?.price).toLocaleString('ru')} тг.`}
              </div>
            </div>
            <div className={s.shop_name_wrapper}>
              <img src={shop} alt="shop" />
              <span className={s.shopname}>{item?.shopname}</span>
            </div>
          </div>
          <div className={s.sizes_item}>
            <div className={s.title_sizes}>Размер</div>
            <div className={s.sizes}>
              {sizesItem?.map((item, i) => {
                return (
                  <div
                    className={onClickSize && onClickSize.id === i ? s.one_size_click : s.one_size}
                    key={uniqid()}
                    onClick={(e) => massSizes(e.target.outerText, i)}>
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={s.description}>
            {/* <div className={s.description_title}>Характеристики</div> */}
            {/* <div className={s.description_text}></div> */}
          </div>
          <div className={s.buy} onClick={() => addToBasketBshop(item)}>
            <ButtonOrange>В корзину</ButtonOrange>
          </div>
        </div>
      ) : (
        <LoadingBlock />
      )}
    </>
  );
};

export default OneItem;
