import React, { Component } from 'react';

import { connect } from 'react-redux'

import userIcon from '../asseets/profile-user.png'
import phoneIcon from '../asseets/profile-phone.png'
// import geoIcon from '../asseets/profile-geo.png'

import './style.css'
// import Button from '../companents/button';

class Profile extends Component {

    state = {
        adress: '',
        street: '',
        apartment: '',
        floor: '',
        entrance: '',
        cottedge: false
    }

    handleChange = async (e) => {
        if (e.target.name === 'cottedge') await this.setState({ [e.target.name]: e.target.checked });
        else await this.setState({ [e.target.name]: e.target.value });
    }

    addNewAdress = () => {
        this.props.addNewAdress({ ...this.state })
    }

    render() {
        const [user] = this.props.user;

        const { name, phone, adress } = user ?? [];

        const form = [
            { value: name, image: userIcon }, { value: phone, image: phoneIcon }
        ]

        // const formAddAdress = [
        //     { title: 'Улица', value: 'adress', placeholder: 'Укажите вашу улицу', type: 'text', name: 'adress' },
        //     { title: 'Дом', value: 'street', placeholder: 'Дом', type: 'text', name: 'street' },
        //     { title: 'Подъезд', value: 'entrance', placeholder: 'Номер подъезда', type: 'text', name: 'entrance' },
        //     { title: 'Номер квартиры', value: 'apartment', placeholder: 'Номер квартиры', type: 'text', name: 'apartment' },
        //     { title: 'Этаж', value: 'floor', placeholder: 'Этаж', type: 'text', name: 'floor' },
        // ]

        // const newAddAdress = this.state.cottedge ? formAddAdress.slice(0, 2) : formAddAdress;

        return (
            <div className='cabinet'>
                <div className='cabinet-wrapper'>
                    <div className='cabinet-text'>
                        <h3>Профиль</h3>
                    </div>

                    <div className='cabinet-items'>
                        {form.map((el, i) => {
                            return (
                                <div key={i} className='profile-item'>
                                    <img src={el.image} alt='' />
                                    <p>{el.value}</p>
                                </div>
                            )
                        })}
                    </div>

                    <div className='cabinet-text'>
                        <h4>Адреса</h4>

                        <div className='cabinet-adress-user'>
                            {adress.map(el => {
                                return (
                                    <div>
                                        <p>{el.adress} - {el.street} </p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>




                    {/* <div className='cabinet-text'>
                        <h4>Адреса</h4>
                        <div className='profile-add-adress'>
                            <span>+</span>
                        </div>
                    </div> */}
                    {/* <div className='cabinet-form-item-switch'>
                        <span>Частный дом</span>
                        <input type='checkbox' name='cottedge' value={this.state.cottedge} autoComplete='off' onChange={this.handleChange} />
                    </div>

                    <form onSubmit={e => e.preventDefault()} className='profile-add-adress-form'>
                        {newAddAdress.map((el, i) => {
                            return (
                                <div key={i} className='cabinet-form-item'>
                                    <span>{el.title}</span>
                                    <input type={el.type} name={el.value} value={this.state[el.value]} autoComplete='off' onChange={this.handleChange} placeholder={el.placeholder} />
                                </div>
                            )
                        })}
                        <Button value='Добавить адрес' class='btn_add_adress' click={this.addNewAdress} />
                    </form> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (data) => data

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);